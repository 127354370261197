import { Trash } from "iconsax-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateToast } from "../../../../../../../../tools/toast/ToastService";
import { ToastType } from "../../../../../../../../viewModels/enums/ToastType";
import Loading from "../../../../../../../../tools/loading/Loading";
import { IServiceResult } from "../../../../../../../../viewModels/api/Api";
import Style from "./DeleteAdmin.module.scss";
import { DeleteAdministratorService } from "./DeleteAdmin.Service";
import { DeletePopUp } from "../../../../addUser/addResidentForm/AddResidentPopup";
import useBodyScrollLock from "../../../../../../../../hook/BodyScrollLock";

interface Props {
  buildingId: number;
  userId: string;
  deleteHandler: () => void;
}
const DeleteAdmin: React.FC<Props> = ({
  buildingId,
  userId,
  deleteHandler,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progressing, setProgressing] = useState<boolean>(false);
  const { t: translate } = useTranslation();
  const deleteAdminHandler = async () => {
    if (buildingId) {
      setLoading(true);
      setProgressing(true);
      const deleteResult: IServiceResult<boolean> =
        await DeleteAdministratorService({
          buildingId: buildingId,
          userId: userId,
        });
      if (deleteResult.data) {
        setIsOpen(false);
        setLoading(false);
        setProgressing(false);
        deleteHandler();
      } else {
        CreateToast(ToastType.Error, translate("ERRORS.DELETE_ADMIN_FAILD"));
        setLoading(false);
        setProgressing(false);
      }
    }
  };

  const showPopUpHandler = () => {
    setIsOpen(!isOpen);
  };
  // Use custom hook to lock body scroll
  useBodyScrollLock(isOpen);
  return (
    <>
      <div className={Style.delete_admin} onClick={showPopUpHandler}>
        <Trash className={Style.delete_admin_icon} />
      </div>
      {isOpen && (
        <DeletePopUp
          title={translate("ADMIN.DELETE_ADMIN_TITR")}
          description={translate("ADMIN.DELETE_ADMIN_DESC")}
          setPopUpState={showPopUpHandler}
          deleteAction={deleteAdminHandler}
          loading={progressing}
        />
      )}
      {loading && <Loading />}
    </>
  );
};

export default DeleteAdmin;
