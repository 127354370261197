import { Paper } from "@mui/material";
import { PickersLayout } from "@mui/x-date-pickers";
import styled from "styled-components";

export const StyledStaticDatePicker = styled(PickersLayout)({
  ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.20) !important",
  },
  ".MuiPickersCalendarHeader-root": {
    ".MuiPickersCalendarHeader-labelContainer": {
      button: {
        display: "none",
      },
    },
    ".MuiPickersArrowSwitcher-root": {
      svg: {
        stroke: "var(--icon-white)",
        strokeWidth: 1,
      },
    },
  },
  ".MuiPickersDay-root": {
    fontFamily:"var(--regular)",
    
    "&:hover": {
      backgroundColor: "var(--bg-primary-low-opacity)", // Background color on hover
    },
  },
  ".MuiPickersDay-today": {
    border: "none !important", // Border color for today's date
  },
  // Style for today's date
});

export const StyledPaper = styled(Paper)({
    boxShadow: '0px 1.268px 1.268px 0px rgba(0, 0, 0, 0.20)', // Custom box shadow
  });

