import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Style from "./Captcha.module.scss";
import { VerifyCaptcha } from "../../services/api/GeneralApi";

interface Props {
  setMethod: (bot: boolean) => void;
}
const Captcha: React.FC<Props> = ({ setMethod }) => {
  const handleCaptchaChange = async (token: string | null) => {
 
    
    if (token != null) {
      var _tokenState = await VerifyCaptcha(token);      
      setMethod(_tokenState);
    } else {
      setMethod(false);
    }
  };
  return (
    <div className={Style.captcha}>
      <ReCAPTCHA
        sitekey="6LefqmgUAAAAAAjEgTq_MtalZgV-p3BJfKdbztHl"
        onChange={handleCaptchaChange}
        size="normal"
        onError={() => setMethod(false)}
        onExpired={() => setMethod(false)}
      />
    </div>
  );
};

export default Captcha;
