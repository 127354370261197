import React from 'react';
import * as Icons from '@mui/icons-material';
import { SvgIconProps } from '@mui/material/SvgIcon';

type IconNames = keyof typeof Icons;

interface IconWrapperProps extends SvgIconProps {
  name: string;
}

const IconWrapper: React.FC<IconWrapperProps> = ({ name, ...props }) => {
  const IconComponent = Icons[name as IconNames];

  if (!IconComponent) {
    return null;
  }

  return <IconComponent {...props} />;
};

export default IconWrapper;
