import { ApiResponse, IServiceResult } from "../../../../../viewModels/api/Api";
import { ComplaintsViewModel } from "../../../../../viewModels/dataTypes/complaints/ComplaintsViewModel";
import {
  GetComplaintsList,
  UpdateComplaintState,
} from "../../../../../services/api/Complaint";
import {
  Complaint,
  UpdateComplaintStateViewModel,
} from "../../../../../viewModels/api/Models";
import {
  FloorinsDateTimeFormat,
  IsNullString,
} from "../../../../../services/commonServices/CommonServices";
import { ContainsSearchTerm } from "../../../../../tools/table/filter/filterSearch/FIlterSearch.Service";
import { FilterConditionsEnum } from "../../../../../viewModels/enums/FilterConditionsEnum";
import dayjs from "dayjs";
// Ensure to import dayjs plugins
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FormatDateDefault } from "../../../../../Setting";
import { CalenderEnum } from "../../../../../viewModels/enums/CalenderEnum";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

export const GetComplaintsItems = async (
  buildingId: string
): Promise<IServiceResult<ComplaintsViewModel[]>> => {
  const resultModel: IServiceResult<ComplaintsViewModel[]> = {
    message: "",
    data: null,
  };

  const getComplaintsResults: ApiResponse<Complaint[]> =
    await GetComplaintsList(buildingId);

  const transformedData: ComplaintsViewModel[] =
    getComplaintsResults.data?.map((subject: Complaint, index: number) => ({
      ...subject,
      lastUpdateDateTime: FloorinsDateTimeFormat(
        subject.lastUpdateDateTime?.toString() ?? ""
      ),
      createdDateTime: FloorinsDateTimeFormat(
        subject.createdDateTime?.toString() ?? ""
      ),
      number: index + 1,
    })) || [];

  resultModel.data = transformedData;

  return resultModel;
};
export const UpdateStateComplaints = async (
  stateInfo: UpdateComplaintStateViewModel
) => {
  const resultModel: IServiceResult<boolean> = {
    message: "",
    data: false,
  };

  const getComplsintStateResult: ApiResponse<UpdateComplaintStateViewModel> =
    await UpdateComplaintState(stateInfo);
  resultModel.data = !!getComplsintStateResult.data;
  return resultModel;
};

const SearchComplaints = (data: ComplaintsViewModel[], searchTerm: string) => {
  if (IsNullString(searchTerm)) {
    return data;
  }

  return data.filter((item: ComplaintsViewModel) =>
    ContainsSearchTerm(item, searchTerm)
  );
};

export const FilterComplaints = (
  data: ComplaintsViewModel[],
  subjects: string,
  subjectsLogic: string,
  searchText: string,
  states: string,
  stateFilterLogic: string,
  dateRange: { start: string; end: string } | null,
  selectedMode: string
) => {
  const searchedData = SearchComplaints(data, searchText);

  const filteredData = searchedData.filter((item: ComplaintsViewModel) => {
    const subjectsList: string[] = subjects
      ? subjects.split(",").map((u) => u.trim())
      : [];
    const stateList: number[] = states
      ? states.split(",").map((s) => parseInt(s.trim(), 10))
      : [];

    // Filter by subjects and states
    const subjectsMatch =
      subjectsLogic === FilterConditionsEnum.OR
        ? subjectsList.length === 0 ||
          subjectsList.some(
            (subject: string) => item.subject?.split(",").includes(subject)
          )
        : subjectsList.length === 0 ||
          subjectsList.every(
            (subject: string) => item.subject?.split(",").includes(subject)
          );

    const statesMatch =
      stateFilterLogic === FilterConditionsEnum.OR
        ? stateList.length === 0 ||
          stateList.some((state: number) => item.status === state)
        : stateList.length === 0 ||
          stateList.every((state: number) => item.status === state);

    // Filter by date range based on selectedMode
    const dateMatch =
      !dateRange ||
      (() => {
        const itemDate = dayjs(item.createdDateTime);
        const startDate = dayjs(
          dateRange.start,
          selectedMode === CalenderEnum.MONTH ? FormatDateDefault : FormatDateDefault,
          true
        );
        const endDate = dayjs(
          dateRange.end,
          selectedMode === CalenderEnum.MONTH
            ? FormatDateDefault
            : FormatDateDefault,
          true
        );

        if (!itemDate.isValid() || !startDate.isValid() || !endDate.isValid()) {
          return false; 
        }

        switch (selectedMode) {
          case CalenderEnum.DAY:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.DAY) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.DAY)
            );
          case CalenderEnum.WEEK:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.DAY) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.DAY)
            );
          case CalenderEnum.MONTH:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.MONTH) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.MONTH)
            );
          case CalenderEnum.YEAR:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.YEAR) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.YEAR)
            );
          default:
            return true;
        }
      })();

    return subjectsMatch && statesMatch && dateMatch;
  });

  return filteredData;
};


