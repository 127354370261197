import React, { useEffect, useState } from "react";
import Style from "./ImageBoxSlider.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  GetFileFormat,
  GetFileName,
  GetShortFileName,
} from "../../../services/commonServices/filesCommonServices/FilesCommonServices";
import DownloadButton from "../../button/DownloadButton";
import { Attachments } from "../../../viewModels/api/Models";

interface Props {
  currentImage: string;
  imagesList: Attachments[];
  onClose: () => void;
}

const ImageBoxSlider: React.FC<Props> = ({
  currentImage,
  imagesList,
  onClose,
}) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  useEffect(() => {
    const index = imagesList?.findIndex((url: Attachments) => url.filePath === currentImage);

    if (index !== -1) {
      if (swiper) {
        swiper.slideTo(index); // Ensure swiper is initialized before using slideTo
      }
    }
  }, [currentImage, swiper, imagesList]);

  const handlePrevClick = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  return (
    <div className={Style.image_box}>
      <div>
        <div className={Style.image}>
          <>
            <Swiper
              effect="coverflow"
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={1}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              navigation={true}
              modules={[Navigation]}
              className={Style.slider}
              onSwiper={setSwiper} // Setting swiper instance
            >
              {imagesList.map((url: Attachments, index: number) => (
                <SwiperSlide key={index}>
                  {/* Add a key prop here */}
                  <div className={Style.slider_container}>
                    <div className={Style.slide_info}>
                      <div className={Style.image_info}>
                        <span>{GetShortFileName(GetFileName(url.filePath??""), 20)}</span>
                      </div>
                      <div className={Style.image_box_calltoaction}>
                        <DownloadButton
                          fileName={GetFileName(url.filePath??"")}
                          fileUrl={url.filePath??""}
                        />
                        <CloseIcon onClick={onClose} />
                      </div>
                    </div>
                    <div className={Style.slider_item}>
                      {GetFileFormat(url.filePath??"") === "pdf" ? (
                        <iframe src={url.filePath??""} title="PDF Preview" />
                      ) : (
                        <img src={url.filePath??""} alt={`slide-${index}`} />
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            {/* Custom navigation buttons */}
            <div
              className={`${Style.slider_icon} ${Style.prev} swiper-button-prev`}
              onClick={handlePrevClick}
            >
              <ArrowBackIosOutlinedIcon />
            </div>
            <div
              className={`${Style.slider_icon} ${Style.next} swiper-button-next`}
              onClick={handleNextClick}
            >
              <ArrowForwardIosOutlinedIcon />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default ImageBoxSlider;
