import React, { ReactNode, useRef } from "react";
import ServicesItemBox from "../../../serviceCategory/servicesCategoryList/serviceCategoryItem/ServiceItemStyle";
import Style from "./BuildingServiceItem.module.scss";
import { Link } from "react-router-dom";
import IconWrapper from "../../../../../../../tools/icons/CustomIcon";
import usePreventContextMenu from "../../../../../../../hook/PreventContextMenu";

interface Props {
  color: string;
  icon: string;
  title: string;
  className?: string;
  callToActionComponent?: ReactNode;
  link?: string;
  isActive?: boolean; // New prop for active state
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void; // Allow the event parameter
}

const BuildingServiceItem: React.FC<Props> = ({
  color,
  icon,
  title,
  className = "",
  link = "",
  callToActionComponent = null,
  isActive = false, // Default to not active
  onClick = () => {}, // Default to no-op
}) => {
  // Create a ref for the Link element
  const linkRef = useRef<HTMLAnchorElement>(null);

  // Use the custom hook on the Link ref
  usePreventContextMenu(linkRef);
  return (
    <ServicesItemBox
      color={color}
      dimensions={6.5}
      isActive={isActive}
      onClick={onClick}
    >
      <div className={`${Style.service_card} ${className}`}>
        <Link to={link} ref={linkRef}>
          <IconWrapper className={Style.icon} name={icon} />
          <div className={`max-w-100 ${Style.card_title}`}>
            <p className="text-elipsis" title={title}>
              {title}
            </p>
          </div>
        </Link>
        {callToActionComponent && (
          <div className={Style.calltoaction_component}>
            {callToActionComponent}
          </div>
        )}
      </div>
    </ServicesItemBox>
  );
};

export default BuildingServiceItem;
