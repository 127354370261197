import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Style from "./Table.module.scss";
import { IconButton, TablePagination, TableSortLabel } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "./TableStyle";
import { SortingMethodEnum } from "../../viewModels/enums/SortingMethodEnum";
import {
  DataTableItemType,
  HeadCellsType,
} from "../../viewModels/dataTypes/DataTableType";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import {
  CustomeArrowDownIcon,
  CustomeArrowUpIocn,
} from "../icons/CustomeArrowIcon";
import NotFoundRow from "./notFoundRow/NotFoundRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ClassNameViewModel } from "../../viewModels/dataTypes/CommonViewModels";
import useDynamicMaxHeight from "../../hook/DynamicMaxHeightTable";

interface Props<T extends DataTableItemType> extends ClassNameViewModel {
  tableData: T[];
  tableHeaderItems: HeadCellsType<T>[];
  path: string;
  notFoundMessage: string;
}

const CustomizedTables = <T extends DataTableItemType>({
  tableData,
  tableHeaderItems,
  path,
  notFoundMessage,
  className = "",
}: Props<T>) => {
  const [data, setDate] = useState<T[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState<keyof T | null>(null);
  const [order, setOrder] = useState<SortingMethodEnum>(SortingMethodEnum.DESC);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [maxHeight, setMaxHeight] = useState<string>("auto");
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDate(tableData);
  }, [tableData]);

  const handleSort = (column: keyof T) => {
    if (orderBy === column && order === SortingMethodEnum.DESC) {
      setOrder(SortingMethodEnum.ASC);
    } else {
      setOrderBy(column);
      setOrder(SortingMethodEnum.DESC);
    }
  };

  const renderSortIcon = (column: keyof T) => {
    if (orderBy !== column) {
      return <CustomeArrowDownIcon />;
    }
    return order === SortingMethodEnum.DESC ? (
      <CustomeArrowDownIcon />
    ) : (
      <CustomeArrowUpIocn />
    );
  };

  const handleMouseEnter = (index: number) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const sortedData = [...tableData].sort((a, b) => {
      if (!orderBy) return 0;
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (typeof aValue === "string" && typeof bValue === "string") {
        const aFirstWord = aValue.trim().split(" ")[0];
        const bFirstWord = bValue.trim().split(" ")[0];
        if (order === SortingMethodEnum.ASC) {
          return aFirstWord.localeCompare(bFirstWord);
        } else {
          return bFirstWord.localeCompare(aFirstWord);
        }
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return order === SortingMethodEnum.ASC
          ? aValue - bValue
          : bValue - aValue;
      }
      return 0;
    });
    setDate(sortedData);
  }, [tableData, orderBy, order]);

  useEffect(() => {
    const sortedData = [...tableData].sort((a, b) => {
      if (!orderBy) return 0;
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (typeof aValue === "string" && typeof bValue === "string") {
        const aFirstWord = aValue.trim().split(" ")[0];
        const bFirstWord = bValue.trim().split(" ")[0];
        if (order === SortingMethodEnum.ASC) {
          return aFirstWord.localeCompare(bFirstWord);
        } else {
          return bFirstWord.localeCompare(aFirstWord);
        }
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return order === SortingMethodEnum.ASC
          ? aValue - bValue
          : bValue - aValue;
      }
      return 0;
    });
    setDate(sortedData);
  }, [tableData, orderBy, order]);

  useDynamicMaxHeight({
    tableContainerRef,
    paginationRef,
    spacing: 70, // setting px of extra spaces such as padding and margin depend on ui for each pages
    setMaxHeight,
  });
  const { t: translate } = useTranslation();

  return (
    <Paper className={`${Style.table_paper}`}>
      <TableContainer
        className={`${Style.table_container} ${className}`}
        component={Paper}
        ref={tableContainerRef}
        style={{ height: maxHeight }}
      >
        <Table
          className={Style.table}
          stickyHeader
          aria-label="custom pagination table"
          sx={{ minWidth: 700 }}
        >
          <TableHead className={Style.table_header}>
            <TableRow>
              {tableHeaderItems.map(
                (headCell: HeadCellsType<T>, index: number) => (
                  <TableCell key={index} align={headCell.align}>
                    {headCell.sortable ? (
                      <TableSortLabel
                        direction={
                          orderBy === headCell.id
                            ? order
                            : SortingMethodEnum.ASC
                        }
                        onClick={() => handleSort(headCell.id)}
                        IconComponent={() => renderSortIcon(headCell.id)}
                      >
                        {translate(headCell.label)}
                      </TableSortLabel>
                    ) : (
                      <> {translate(headCell.label)}</>
                    )}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody className={Style.table_body}>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: T, rowIndex: number) => (
                  <StyledTableRow
                    key={rowIndex}
                    onMouseOver={() => handleMouseEnter(rowIndex)}
                    onMouseOut={handleMouseLeave}
                  >
                    {tableHeaderItems.map(
                      (headerItem: HeadCellsType<T>, index: number) => (
                        <StyledTableCell
                          cellWidth={headerItem.cellSize}
                          key={index}
                          align={headerItem.align}
                        >
                          {headerItem.isLink ? (
                            <Link to={headerItem.isLink ? path + row.id : ""}>
                              {headerItem.specialComponent ? (
                                <headerItem.specialComponent
                                  {...headerItem.specialComponentProps?.reduce(
                                    (acc, prop: keyof T) => ({
                                      ...acc,
                                      [prop]: row[prop],
                                    }),
                                    {}
                                  )}
                                  isHovered={hoveredRowIndex === rowIndex}
                                  indexOfRow={rowIndex}
                                />
                              ) : (
                                String(row[headerItem.id])
                              )}
                            </Link>
                          ) : (
                            <div>
                              {headerItem.specialComponent ? (
                                <headerItem.specialComponent
                                  {...headerItem.specialComponentProps?.reduce(
                                    (
                                      acc: Partial<Record<keyof T, T[keyof T]>>,
                                      prop: keyof T
                                    ) => ({
                                      ...acc,
                                      [prop]: row[prop],
                                    }),
                                    {}
                                  )}
                                  isHovered={hoveredRowIndex === rowIndex}
                                  indexOfRow={rowIndex}
                                />
                              ) : (
                                String(row[headerItem.id])
                              )}
                            </div>
                          )}
                        </StyledTableCell>
                      )
                    )}
                  </StyledTableRow>
                ))
            ) : (
              <NotFoundRow message={notFoundMessage} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        ref={paginationRef}
        SelectProps={{
          IconComponent: KeyboardArrowDownIcon,
        }}
        rowsPerPageOptions={[
          5,
          15,
          25,
          { label: translate("SHARED.ALL"), value: data.length },
        ]}
        component={"div"}
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={Style.pagination}
        ActionsComponent={({ onPageChange, page }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton></IconButton>
            <IconButton
              onClick={() => onPageChange(null, 0)}
              disabled={page === 0}
            >
              <FirstPage />
            </IconButton>
            <IconButton
              onClick={() => onPageChange(null, page - 1)}
              disabled={page === 0}
            >
              <NavigateBefore />
            </IconButton>
            <IconButton
              onClick={() => onPageChange(null, page + 1)}
              disabled={page === Math.ceil(data.length / rowsPerPage) - 1}
            >
              <NavigateNext />
            </IconButton>
            <IconButton
              onClick={() =>
                onPageChange(null, Math.ceil(data.length / rowsPerPage) - 1)
              }
              disabled={page === Math.ceil(data.length / rowsPerPage) - 1}
            >
              <LastPage />
            </IconButton>
          </div>
        )}
      />
    </Paper>
  );
};

export default CustomizedTables;
