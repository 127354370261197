import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import Style from "./TextArea.module.scss";
import { FieldState } from "../../viewModels/enums/PublicEnum";
import { MessageHandler } from "../messagesHandler/MessagesHandler";
import { MessageType } from "../../viewModels/enums/MessageType";
import { Typography } from "@mui/material";
interface Props {
  icon?: ReactElement;
  label: string;
  onChange?: (param?: any) => void;
  fieldState?: FieldState;
  value: string;
  errorMessage?: string | null;
  messageType?: MessageType;
  placeholder: string;
  onBlur?: () => void;
  maxLength?: number;
  readonly?: boolean;
  counter?: boolean;
}
const TextArea: React.FC<Props> = ({
  icon,
  label,
  onChange,
  fieldState = FieldState.Default,
  value,
  errorMessage = null,
  messageType = MessageType.Error,
  placeholder,
  onBlur,
  maxLength = 200,
  readonly = false,
  counter,
}) => {
  //states
  const [_fieldState, setFieldState] = useState<FieldState>(FieldState.Default);
  const [changeLabelColor, setChangeLabelColor] = useState(false);
  //effects
  useEffect(() => {
    setFieldState(fieldState);
  }, [fieldState]);

  useEffect(() => {
    setChangeLabelColor(value !== null && value.length > 0);
  }, [value]);

  const changeTextArea = (value: string) => {
    if (onChange) {
      onChange(value);
      if (value !== null && value.length > 0) {
        setChangeLabelColor(true);
      } else {
        setChangeLabelColor(false);
      }
    }
  };
  const fieldOnBlure = () => {
    setFieldState(
      _fieldState === FieldState.Error ? FieldState.Error : FieldState.Default
    );
    if (onBlur) {
      onBlur();
    }
  };
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
    }
  }, [value]);
  return (
    <div className={Style.flooe_textarea}>
      {errorMessage?.length === 0 || errorMessage == null ? (
        <label className={`${changeLabelColor ? Style.fill_field : ""}`}>
          {icon}
          <span
            style={{
              color: _fieldState === FieldState.Error ? "var(--text-red)" : "",
            }}
            className={Style.label}
          >
            {label}
          </span>
        </label>
      ) : (
        <MessageHandler type={messageType} message={errorMessage ?? ""} />
      )}
      <div className={`${readonly ? Style.readonly_field : ""}`}>
        <textarea
          ref={textareaRef}
          maxLength={maxLength}
          value={value}
          placeholder={placeholder}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            changeTextArea(e.target.value)
          }
          onBlur={fieldOnBlure}
          readOnly={readonly}
        />
        {counter && (
          <Typography className={Style.counter} variant="body2">
            {`${(value ?? "").length}/${maxLength}`}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default TextArea;
