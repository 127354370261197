import React, { useEffect, useState } from "react";
import RequestsList from "./requestsList/RequestsList";
import {
  IBuildingServiceRequestsReducer,
  IReducer,
} from "../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../redux/Store";
import { GetBuildingServiceRequests } from "../../../../../redux/slices/BuildingServiceRequestsSlice";
import { useParams } from "react-router-dom";
import Loading from "../../../../../tools/loading/Loading";

const ServiceRequests = () => {
  const { buildingId, categoryId } = useParams<{
    buildingId: string;
    categoryId: string;
  }>();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [preload, setPreload] = useState<boolean>(true);
  const buildingServiceRequestsInfo: IBuildingServiceRequestsReducer =
    useSelector((state: IReducer) => state.buildingServiceRequests);

  useEffect(() => {
    const getServiceRequests = async () => {
      setPreload(true);
      await dispatch(
        GetBuildingServiceRequests({
          buildingId: Number(buildingId),
          categoryId: Number(categoryId),
        })
      );
      setPreload(false);
    };
    getServiceRequests();
  }, [categoryId, buildingId]);

  return (
    <>
      {preload ? (
        <Loading />
      ) : (
        <RequestsList
          serviceRequests={buildingServiceRequestsInfo.serviceRequests || []}
        />
      )}
    </>
  );
};

export default ServiceRequests;
