import React from "react";
import Style from "./MobileHeader.module.scss";
import { HambergerMenu, Notification } from "iconsax-react";
import { useDispatch } from "react-redux";
import { AppThunkDispatch } from "../../../../redux/Store";
import { menuStateHandler } from "../../../../redux/slices/MenuSlice";

interface Props {
  scrolled: boolean;
}
const MobileHeader: React.FC<Props> = ({ scrolled }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const menuHandler = () => {
    dispatch(menuStateHandler(true));
  };

  return (
    <header className={`${Style.header} ${scrolled ? Style.menu_shadow : ""}`}>
      <div className={Style.menu} onClick={menuHandler}>
        <HambergerMenu className={Style.hamberger_menu_icon} />
      </div>
      <div className={Style.notification}>
        <Notification className={Style.notification_icon} />
      </div>
    </header>
  );
};

export default MobileHeader;
