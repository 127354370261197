import styled from "styled-components";

interface Props {
  backgroundColor: string;
  borderColor: string;
}

// Styled component for the dropdown menu
export const StyledMenu = styled("div")<Props>`
  border-color: ${(props: Props) => props.borderColor} !important;
  > svg {
    color: ${(props: Props) => props.borderColor};
    margin-right: 0.25rem;
    stroke-width: 1;
    stroke: var(--icon-white);
  }
  &:hover {
    background-color: ${(props: Props) => props.backgroundColor};
    svg {
      color: var(--icon-white);
      stroke: ${(props: Props) => props.backgroundColor};
      stroke-width: 1;
    }
  }
`;
