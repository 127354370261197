import { CheckPhoenValidation, EmailRegex } from "../../../../services/Regex";
import {
  CheckEmailToLogIn,
  CheckPhoneNumberToLogIn,
} from "../../../../services/api/User";
import { Localstorage_UserValidation } from "../../../../text/LocalStorageValues";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../viewModels/api/Api";
import { ReceiverType } from "../../../../viewModels/api/Models";
import { UserContactValidationModel } from "../../../../viewModels/dataTypes/Cookie";
import { CountryType } from "../../../../viewModels/dataTypes/country/Country";
import { CheckEmailOrPhoneNumberResponse } from "../../../../viewModels/dataTypes/registration/SingUp";
import { ResponseCode } from "../../../../viewModels/enums/ResponseCode";
import { DeliveryMethodDetectionService } from "../supperAdminLogin/SuperAdminLogin.Service";

export const UserLoginService = async (
  receiver: string,
  country?: CountryType
) => {
  let receiverType = DeliveryMethodDetectionService(country);
  switch (receiverType) {
    case ReceiverType.PhoneNumber:
      return await UserLoginServiceViaPhoneNumber(receiver, country);
    case ReceiverType.Email:
      return await UserLoginServiceViaEmail(receiver);
  }
};

export const UserLoginServiceViaPhoneNumber = async (
  phoneNumber: string,
  country: CountryType | undefined
) => {
  // define a parameter to handle Api response
  var result: IServiceResult<CheckEmailOrPhoneNumberResponse> & {
    route: string;
  } = {
    message: "",
    route: "",
  };
  // check phone number validation
  let isValidated = CheckPhoenValidation(phoneNumber);
  if (isValidated) {
    // call check user phone number Api
    const setPhoneNumber: ApiResponse<CheckEmailOrPhoneNumberResponse> =
      await CheckPhoneNumberToLogIn(country?.code + phoneNumber);
      
    if (setPhoneNumber.responseStatus == ResponseCode.Success) {
      //when the response status of request is 200
      if (setPhoneNumber.data?.status == ResponseCode.Accepted) {
        // when there aren't any problem to sign-up with the phone number
        var newUserValidation: UserContactValidationModel = {
          userContact: phoneNumber,
          userCountry: country ?? null,
          otpDeliveryMethod: ReceiverType.PhoneNumber,
        };
        localStorage.setItem(
          Localstorage_UserValidation,
          JSON.stringify(newUserValidation)
        );
        result.route = "/login/checkSmsOtp";
        result.data = setPhoneNumber.data;
      } else if (setPhoneNumber.data?.status == ResponseCode.NotFound) {
        // when the phone number is resgistred
        result.message = "ERRORS.LOGIN_ENTER_DEFERENT_PHONENUMBER";
        result.data = setPhoneNumber.data;
      } else {
        result.message = "ERRORS.TRY_OTP_LATER";
      }
    } else {
      result.message = "ERRORS.OPERATION_FAILD";
    }
  } else {
    result.message = "ERRORS.INVALID_PHONENUMBER";
  }
  return result;
};

export const UserLoginServiceViaEmail = async (email: string) => {
  // define a parameter to handle Api response
  var result: IServiceResult<CheckEmailOrPhoneNumberResponse> & {
    route: string;
  } = {
    message: "",
    route: "",
  };
  // check email validation
  let isValdate = EmailRegex(email);
  if (isValdate) {
    // call check user email address Api
    const setEmail: ApiResponse<CheckEmailOrPhoneNumberResponse> =
      await CheckEmailToLogIn(email);
    if (setEmail.responseStatus == ResponseCode.Success) {
      //when the response status of request is 200
      if (setEmail.data?.status == ResponseCode.Accepted) {
        // when there aren't any problem to sign-up with the email address
        var newUserValidation: UserContactValidationModel = {
          userContact: email,
          userCountry: null,
          otpDeliveryMethod: ReceiverType.Email,
        };
        localStorage.setItem(
          Localstorage_UserValidation,
          JSON.stringify(newUserValidation)
        );
        result.route = "/login/checkEmailOtp";
        result.data = setEmail.data;
      } else if (setEmail.data?.status == ResponseCode.NotFound) {
        // when the email address is resgistred
        result.message = "ERRORS.LOGIN_ENTER_DEFETENT_EMAIL";
        result.data = setEmail.data;
      } else {
        result.message = "ERRORS.TRY_OTP_LATER";
      }
    } else {
      result.message = "ERRORS.OPERATION_FAILD";
    }
  } else {
    result.message = "ERRORS.INVALID_EMAIL";
  }
  return result;
};
