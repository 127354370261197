import { TimeInterval } from "../../../../../../../../viewModels/api/Models";
import { IDropDown } from "../../../../../../../../viewModels/dataTypes/tools/DropDownItems";

export const ListOfRepeatDuration: IDropDown[] = [
  {
    value: TimeInterval.Weekly.toString(),
    text: "SHARED.WEEK",
  },
  {
    value: TimeInterval.Monthly.toString(),
    text: "SHARED.MONTH",
  },
];
