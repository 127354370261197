import { useTranslation } from "react-i18next";
import MessagePopUp from "../../../../../../tools/popup/MessagePopUp";
import { Trash } from "iconsax-react";
import Style from "./AddResidentForm.module.scss";
import Button from "../../../../../../tools/button/Button";
import { SizeType } from "../../../../../../viewModels/dataTypes/tools/ListComponentType";
import {
  ButtonState,
  ButtonTheme,
} from "../../../../../../viewModels/enums/ButtonEnum";
import { useEffect, useState } from "react";

interface Props {
  title: string;
  description: string;
  setPopUpState: (state: boolean) => void;
  deleteAction: () => void;
  loading?: boolean;
}
export const DeletePopUp: React.FC<Props> = ({
  title,
  description,
  setPopUpState,
  deleteAction,
  loading = false,
}) => {
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.disable
  );
  const { t: translate } = useTranslation();
  useEffect(() => {
    const changeStatePolice: boolean = loading;
    setButtonState(
      changeStatePolice ? ButtonState.disable : ButtonState.default
    );
  }, [loading]);

  return (
    <>
      <MessagePopUp
        icon={<Trash className={Style.delete_icon} />}
        title={translate(title)}
        description={translate(description)}
        className={Style.delete_user_popup}
        buttons={
          <>
            <Button
              className={Style.delete_button}
              text={translate("BUTTON.CANCEL")}
              theme={ButtonTheme.deleteReverse}
              size={SizeType.small}
              clickMethod={() => setPopUpState(false)}
            />
            <Button
              className={Style.delete_button}
              text={translate("BUTTON.DELETE")}
              theme={ButtonTheme.delete}
              size={SizeType.small}
              clickMethod={deleteAction}
              state={buttonState}
            />
          </>
        }
      />
    </>
  );
};
