import { BodyOfGetRequest } from "../../Setting";
import { BaseUrl, GetAnnouncements } from "../../text/Api";
import { ApiResponse } from "../../viewModels/api/Api";
import { Announcement } from "../../viewModels/api/Models";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CallApi } from "../CallApi";
import { DefaultApiHeader } from "./ApiHeaders";


export const GetAnouncementsList  = async (buildingId: string) => {
  const Url: string = `${BaseUrl}${GetAnnouncements}/${buildingId}`;
   
  let Result: ApiResponse<Announcement[]> = await CallApi(
    Url,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Get
  );

  return Result;
};