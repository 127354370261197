import {
  ActionReducerMapBuilder,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { ApiResponse } from "../../viewModels/api/Api";
import { IServicesIconReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";
import { Icon, IconType } from "../../viewModels/api/Models";
import { GetIconsBasedOnType } from "../../services/api/Icon";

const initialState = {
  icons: [],
  loading: true,
  fill: false,
  err: "",
} as IServicesIconReducer;

const ServicesIconsSlice = createSlice({
  name: "servicesIcons",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<IServicesIconReducer>) => {
    builder.addCase(GetServicesIcons.pending, (State : IServicesIconReducer) => {
      State.loading = true;
    });
    builder.addCase(
      GetServicesIcons.fulfilled,
      (State: IServicesIconReducer, action: PayloadAction<Icon[]>) => {
        State.icons = action.payload;
        State.loading = false;
        State.fill = true;
      }
    );
    builder.addCase(
      GetServicesIcons.rejected,
      (State: IServicesIconReducer) => {
        State.loading = false;
        State.err = "Loading Faild";
      }
    );
  },
});

export const GetServicesIcons = createAsyncThunk(
  "GetServicesIcons",
  async () => {
    try {
      const servicesIcon: ApiResponse<Icon[] | null> =
        await GetIconsBasedOnType(IconType.All);
      if (servicesIcon.data) {
        return servicesIcon.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);

export default ServicesIconsSlice.reducer;
