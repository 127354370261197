import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../tools/button/Button";
import Loading from "../../../../tools/loading/Loading";
import { useTranslation } from "react-i18next";
import BeforeDashboardForm from "../../BeforeDashboardForm";
import SmartField from "../../../../tools/fields/smartField/SmartField";
import Captcha from "../../../../tools/capcha/Captcha";
import { ButtonState } from "../../../../viewModels/enums/ButtonEnum";
import { FieldState } from "../../../../viewModels/enums/PublicEnum";
import { ResponseCode } from "../../../../viewModels/enums/ResponseCode";
import { UserLoginService } from "./UserLogin.Service";
import Style from "./UserLogin.module.scss";
import { CountryType } from "../../../../viewModels/dataTypes/country/Country";
import { UserContactValidationModel } from "../../../../viewModels/dataTypes/Cookie";
import { Localstorage_UserToken } from "../../../../text/LocalStorageValues";

const UserLogin: React.FC = () => {
  const [userContactInfo, setUserContactInfo] = useState<string>("");
  const [country, setCountry] = useState<CountryType>();
  const [captchaState, setCaptchaState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [messageContent, setMessageContent] = useState<string>("");
  const [enableLogin, setEnableLogin] = useState(false);
  const [fieldState, setFieldState] = useState<FieldState>(FieldState.Default);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem(Localstorage_UserToken);

    window.history.pushState(null, "", window.location.href);

    const preventBackNavigation = () => {
      window.history.pushState(null, "", window.location.href);
    };
    window.addEventListener("popstate", preventBackNavigation);

    return () => {
      window.removeEventListener("popstate", preventBackNavigation);
    };
  }, [navigate]);

  useEffect(() => {
    const storedContactInfo = localStorage.getItem("userContactInfo");
    if (storedContactInfo) {
      setUserContactInfo(storedContactInfo);
    }
    const storedCountry = localStorage.getItem("userCountry");
    if (storedCountry) {
      setCountry(JSON.parse(storedCountry));
    }
    const userValidationJson = localStorage.getItem("UserValidation");
    if (userValidationJson) {
      const userValidationData: UserContactValidationModel =
        JSON.parse(userValidationJson);
      setUserContactInfo(userValidationData.userContact);
      if (userValidationData.userCountry) {
        setCountry(userValidationData.userCountry);
      } else {
        setCountry(undefined);
      }
    }
  }, []);

  const setUserMobileOrEmail = (phoneNumber: string, country?: CountryType) => {
    setMessageContent("");
    setEnableLogin(false);
    setFieldState(FieldState.Default);
    setCountry(country);
    setUserContactInfo(phoneNumber);

    const userValidationData: UserContactValidationModel = {
      userContact: phoneNumber,
      userCountry: country || null,
      otpDeliveryMethod: 2,
    };

    localStorage.setItem("UserValidation", JSON.stringify(userValidationData));
  };

  const backHandler = () => {
    localStorage.removeItem("UserValidation");
  };

  const submitPhoneNumberOrEmail = async () => {
    setMessageContent("");
    setFieldState(FieldState.Default);
    setEnableLogin(false);
    setLoading(true);
    const superAdminLoginResult = await UserLoginService(
      userContactInfo,
      country
    );
    if (superAdminLoginResult.data?.status === ResponseCode.Accepted) {
      navigate(superAdminLoginResult.route);
    } else {
      if (superAdminLoginResult.data?.status === ResponseCode.NotFound) {
        setEnableLogin(true);
      }
      setFieldState(FieldState.Error);
      setMessageContent(superAdminLoginResult.message);
      setLoading(false);
    }
  };
  const { t: translate } = useTranslation();

  return (
    <>
      <BeforeDashboardForm
        title={translate("AUTHENTICATION.LOGIN")}
        description={translate("AUTHENTICATION.SIGNUP_DESCRIPTION")}
        backHandler={backHandler}
        backButton={false}
        body={
          <>
            <SmartField
              initialValue={userContactInfo}
              onChange={setUserMobileOrEmail}
              errorMessage={
                !enableLogin ? (
                  translate(messageContent)
                ) : (
                  <>
                    {translate(messageContent)}
                    <Link className={Style.login_link} to="/">
                      {translate("SHARED.GET_HELP")}
                    </Link>
                  </>
                )
              }
              fieldState={fieldState}
            />
            <div className="mt-2">
              <Captcha setMethod={setCaptchaState} />
            </div>
            <div className="mt-3">
              {captchaState === true && userContactInfo.length > 0 ? (
                <>
                  <Button
                    text={translate("BUTTON.NEXT")}
                    clickMethod={submitPhoneNumberOrEmail}
                  />
                </>
              ) : (
                <>
                  <Button
                    state={ButtonState.disable}
                    text={translate("BUTTON.NEXT")}
                  />
                </>
              )}
            </div>
            {loading && <Loading />}
          </>
        }
      />
    </>
  );
};

export default UserLogin;
