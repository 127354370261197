import React from "react";
import Style from "./AddUser.module.scss";
import Button from "../../../../../../tools/button/Button";
import ButtonMenu from "../../../../../../tools/dropDown/buttonMenu/ButtonMenu";
import { SizeType } from "../../../../../../viewModels/dataTypes/tools/ListComponentType";
import { useTranslation } from "react-i18next";
import { IButtonMenuItems } from "../../../../../../viewModels/dataTypes/tools/DropDownItems";
import { ButtonTheme } from "../../../../../../viewModels/enums/ButtonEnum";
import { Add } from "iconsax-react";

interface Props {
  buildingId: string;
}
const AddUser: React.FC<Props> = ({ buildingId }) => {
  const { t: translate } = useTranslation();

  const usersType: IButtonMenuItems[] = [
    {
      value: "Tanent",
      link: `/dashboard/building/users/${buildingId}/addTenant`,
    },
    {
      value: "Owner",
      link: `/dashboard/building/users/${buildingId}/addOwner`,
    },
  ];
  
  return (
    <div className={Style.user_buttons}>
      <Button
        size={SizeType.small}
        theme={ButtonTheme.primaryReverse}
        text={translate("USERS.ADMIN")}
        icon={<Add size="16" />}
        link={`/dashboard/building/users/${buildingId}/addAdmin`}
      />
      <ButtonMenu
        size={SizeType.small}
        text={translate("USERS.USER")}
        items={usersType}
        icon={<Add size="16" />}
      />
    </div>
  );
};

export default AddUser;
