import React, { useEffect, useState } from "react";
import Button from "../../../tools/button/Button";
import Loading from "../../../tools/loading/Loading";
import TextField from "../../../tools/fields/textField/TextField";
import { useNavigate } from "react-router-dom";
import { UserContactValidationModel } from "../../../viewModels/dataTypes/Cookie";
import { useTranslation } from "react-i18next";
import {
  CheckUserItemValidationService,
  ForgetPasswordService,
} from "./service/ForgetPassword.Service";
import { IServiceResult } from "../../../viewModels/api/Api";
import { Localstorage_UserValidation } from "../../../text/LocalStorageValues";
import BeforeDashboardForm from "../BeforeDashboardForm";

import PhoneNumberValidation from "../../../tools/fields/phoneNumberField/PhoneNumberField";
import {
  ContactVerificationState,
  FieldState,
} from "../../../viewModels/enums/PublicEnum";
import { ReSendOtpService } from "../checkOtp/ResendOtp.Services";
import { CheckEmailOrPhoneNumberResponse } from "../../../viewModels/dataTypes/registration/SingUp";
import CheckCode from "../../../tools/fields/checkCode/CheckCode";
import Timer from "../../../tools/timer/Timer";
import { ResponseCode } from "../../../viewModels/enums/ResponseCode";
import { ICheckOtpResponse } from "../../../viewModels/dataTypes/registration/Otp";
import { CheckOtpService } from "../checkOtp/CheckOtp.Service";
import { MaskString } from "../../../services/commonServices/CommonServices";
import { ButtonState } from "../../../viewModels/enums/ButtonEnum";
import { CountryType } from "../../../viewModels/dataTypes/country/Country";
import { ReceiverType } from "../../../viewModels/api/Models";
interface Props {
  otpLength: number;
}
const ForgetPassword: React.FC<Props> = ({ otpLength }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>("");

  const [otpFieldState, setOtpFieldState] = useState(FieldState.Default);
  const [fieldState, setFieldState] = useState(FieldState.Default);
  const [helper, setHelper] = useState("");
  const [preload, setPreload] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [otpDisabled, setOtpDisabled] = useState<boolean>(false);
  const [otpBlocked, setOtpBlocked] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState(false);
  const [userContact, setUserContact] = useState("");
  const [country, setCountry] = useState<CountryType>();
  const [buttonState, setButtonState] = useState(ButtonState.disable);
  const [deliveryMethod, setDeliveryMethod] = useState<ReceiverType>(
    ReceiverType.PhoneNumber
  );
  const [contactVerificationState, setContactVerificationState] =
    useState<ContactVerificationState>(ContactVerificationState.Empty);
  const [otp, setOtp] = useState<string[]>(Array(otpLength).fill(""));
  const [finalCode, setFinalCode] = useState<string>("");
  const [theUserValidation, setTheUserValidation] =
    useState<UserContactValidationModel>();

  useEffect(() => {
    const getUserInfo = async () => {
      if (!localStorage.getItem(Localstorage_UserValidation)) {
        navigate("/");
      } else {
        var ueserDataJson = localStorage.getItem(Localstorage_UserValidation);
        if (ueserDataJson != null) {
          var info: UserContactValidationModel | null =
            await CheckUserItemValidationService(ueserDataJson);
          if (info != null) {
            setTheUserValidation(info);
            setDeliveryMethod(info.otpDeliveryMethod);
            if (info.otpDeliveryMethod === ReceiverType.PhoneNumber) {
              setHelper(
                MaskString(info.userCountry?.code + info.userContact ?? "")
              );
            } else {
              setHelper(MaskString(info.userContact ?? ""));
            }
            setPreload(false);
          } else {
            navigate("/");
          }
        } else {
          navigate("/");
        }
      }
    };
    getUserInfo();
  }, [navigate]);

  useEffect(() => {
    if (otpBlocked) {
      setOtpDisabled(true);
    }
  }, [otpBlocked]);

  useEffect(() => {
    if (!otpDisabled) {
      setOtpBlocked(false);
    }
  }, [otpDisabled]);

  useEffect(() => {
    localStorage.setItem("otpDisabled", JSON.stringify(otpDisabled));
    localStorage.setItem("isOtpBlocked", JSON.stringify(otpBlocked));
    localStorage.setItem("otpFieldState", JSON.stringify(otpFieldState));
    localStorage.removeItem("timerValue");
  }, [otpDisabled, otpBlocked, otpFieldState]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("otpMessageContent");
      localStorage.removeItem("otpMessageType");
      localStorage.removeItem("otpDisabled");
      localStorage.removeItem("isOtpBlocked");
      localStorage.removeItem("otpFieldState");
      localStorage.removeItem("timerValue");
    };
  }, []);
  const onChangePhoneNumber = (phoneNumber: string, country?: CountryType) => {
    setFieldState(FieldState.Default);
    setErrorMessage("");
    setUserContact(phoneNumber);
    setCountry(country);
    if (phoneNumber.length > 0) {
      setButtonState(ButtonState.default);
    } else {
      setButtonState(ButtonState.disable);
    }
  };

  const onChangeEmail = (email: string) => {
    setFieldState(FieldState.Default);
    setErrorMessage("");
    setUserContact(email);
    if (email.length > 0) {
      setButtonState(ButtonState.default);
    } else {
      setButtonState(ButtonState.disable);
    }
  };

  const emailConfirmation = (email: string) => {
    return email === theUserValidation?.userContact;
  };

  const setOtpCode = (nextNumber: string, index: number) => {
    setOtpErrorMessage("");
    setOtpFieldState(FieldState.Default);
    setResetForm(false);
    otp[index] = nextNumber;
    setOtp(otp);

    if (otp.every((item: string) => item.length > 0)) {
      var code: string = "";
      for (var i: number = 0; i < otp.length; i++) {
        code = code + otp[i];
      }
      setFinalCode(code);
      submitOtp(code);
    }
  };

  const submitOtp = async (otpCode: string) => {
    if (userContact.length > 0) {
      setResetForm(false);
      setLoading(true);
      setButtonState(ButtonState.default);
      let userContactValue: string =
        country != null ? country.code + userContact : userContact;
      let result: IServiceResult<ICheckOtpResponse> = await CheckOtpService(
        {
          code: otpCode,
          receiver: userContactValue,
          receiverType: deliveryMethod,
        },
        setOtpDisabled
      );

      if (result.data != null) {
        localStorage.setItem(
          Localstorage_UserValidation,
          JSON.stringify(theUserValidation)
        );
        navigate("/login/changePassword");
      } else {
        setOtpErrorMessage(result.message);
        setOtpFieldState(FieldState.Error);
        setLoading(false);
        setResetForm(true);
        setOtp(Array(otpLength).fill(""));
        setButtonState(ButtonState.disable);
      }
    } else {
      setResetForm(true);
      setOtp(Array(otpLength).fill(""));
      setButtonState(ButtonState.disable);
      setOtpErrorMessage("ERRORS.INVALID_OTP");
      setLoading(false);
    }
  };

  const resend = async () => {
    setLoading(true);
    setOtpBlocked(false);
    setOtpDisabled(false);
    var result: IServiceResult<CheckEmailOrPhoneNumberResponse> =
      await ReSendOtpService(
        country != null ? country.code + userContact : userContact,
        deliveryMethod
      );
    setResetForm(true);
    setOtp(Array(otpLength).fill(""));
    if (result.data?.status === ResponseCode.Accepted) {
      setOtpFieldState(FieldState.Default);
      setOtpErrorMessage("");
      setLoading(false);
    } else {
      setOtpErrorMessage("ERRORS.OPERATION_FAILD");
      setLoading(false);
    }
  };

  const forgetPassword = async () => {
    if (contactVerificationState === ContactVerificationState.Checking) {
      submitOtp(finalCode);
    } else {
      setLoading(true);
      let userContactValue: string =
        country != null ? country.code + userContact : userContact;
      let userContactExpection =
        deliveryMethod === ReceiverType.PhoneNumber && theUserValidation != null
          ? theUserValidation.userCountry?.code + theUserValidation.userContact
          : theUserValidation?.userContact;
      if (userContactValue === userContactExpection) {
        var result = await ForgetPasswordService(
          deliveryMethod,
          userContactValue
        );
        if (result.data != null) {
          setContactVerificationState(ContactVerificationState.Checking);
          setLoading(false);
          setErrorMessage("");
          setFieldState(FieldState.Default);
          setButtonState(ButtonState.disable);
        } else {
          setErrorMessage(result.message);
          setLoading(false);
          setFieldState(FieldState.Error);
        }
      } else {
        setFieldState(FieldState.Error);
        setErrorMessage(
          deliveryMethod === ReceiverType.PhoneNumber
            ? "ERRORS.INVALID_PHONENUMBER"
            : "ERRORS.INVALID_EMAIL"
        );
        setLoading(false);
      }
    }
  };

  const { t: translate } = useTranslation();
  return (
    <>
      {preload === true ? (
        <Loading />
      ) : (
        <BeforeDashboardForm
          title={translate("AUTHENTICATION.PASSWORD_RECOVERY")}
          description={
            theUserValidation?.otpDeliveryMethod === ReceiverType.PhoneNumber
              ? translate("AUTHENTICATION.PHONE_HELPER", { value: `${helper}` })
              : translate("AUTHENTICATION.EMAIL_HELPER", { value: `${helper}` })
          }
          body={
            <>
              {deliveryMethod === ReceiverType.PhoneNumber ? (
                <PhoneNumberValidation
                  value={userContact}
                  errorMessage={translate(errorMessage)}
                  onChange={onChangePhoneNumber}
                  fieldState={fieldState}
                  readOnly={
                    contactVerificationState ===
                    ContactVerificationState.Checking
                      ? true
                      : false
                  }
                />
              ) : (
                <TextField
                  value={userContact}
                  errorMessage={translate(errorMessage)}
                  placeHolder={`${translate("PLACEHOLDER.EMAIL")}`}
                  label={translate("LABELS.EMAIL")}
                  onChange={onChangeEmail}
                  condotion={emailConfirmation}
                  fieldState={fieldState}
                  readOnly={
                    contactVerificationState ===
                    ContactVerificationState.Checking
                      ? true
                      : false
                  }
                />
              )}
              <>
                {contactVerificationState ===
                ContactVerificationState.Checking ? (
                  <>
                    <div className={otpErrorMessage.length === 0 ? "mt-2" : ""}>
                      <CheckCode
                        errorMessage={otpErrorMessage}
                        resetMode={resetForm}
                        setMethod={setOtpCode}
                        fieldState={otpFieldState}
                        errorMessageAlignCenter
                        otpDisabled={otpDisabled}
                      />
                    </div>
                    <div>
                      <Timer action={resend} />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
              <div
                className={
                  contactVerificationState === ContactVerificationState.Checking
                    ? "mt-3"
                    : "mt-4"
                }
              >
                <Button
                  state={buttonState}
                  text={translate("BUTTON.NEXT")}
                  clickMethod={forgetPassword}
                />
              </div>
              {loading && <Loading />}
            </>
          }
        />
      )}
    </>
  );
};

export default ForgetPassword;
