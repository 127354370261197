import React, { useEffect, useState } from "react";
import { DashboardBox } from "../../../../../../tools/box/DashboardBox";
import DashboardTitle from "../../../../../../tools/dashboardTitle/DashboardTitle";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import Style from "./ServicesCategoryList.module.scss";

import { useTranslation } from "react-i18next";
import {
  IBuildingInfoReducer,
  IBuildingServiceCategoriesReducer,
  IReducer,
} from "../../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../../redux/Store";
import { GetBuildingInfoDataAction } from "../../../../../../redux/slices/BuildingInfoSlice";
import { useParams } from "react-router-dom";
import { AddServiceCategoryService } from "./ServicesCategoryList.Service";
import ServiceCategoryItem from "./serviceCategoryItem/ServiceCategoryItem";
import { ServiceCategory } from "../../../../../../viewModels/api/Models";
import Loading from "../../../../../../tools/loading/Loading";
import ServiceCategoryModifieItems from "./serviceCategoryModifieItems/ServiceCategoryModifieItems";
import ServiceCategoryPopUp from "../servicesCategoryPopUp/ServiceCategoryPopUp";
import { ToastType } from "../../../../../../viewModels/enums/ToastType";
import { CreateToast } from "../../../../../../tools/toast/ToastService";
import { GetBuildingServiceCategories } from "../../../../../../redux/slices/BuildingServiceCategories";
import useBodyScrollLock from "../../../../../../hook/BodyScrollLock";

const ServicesCategoryList = () => {
  const { buildingId } = useParams();

  const { t: translate } = useTranslation();
  //states
  const userBuildingInfo: IBuildingInfoReducer = useSelector(
    (state: IReducer) => state.buildingInfo
  );
  const buildingServiceCategoriesInfo: IBuildingServiceCategoriesReducer =
    useSelector((state: IReducer) => state.buildingServiceCategories);

  const [openAddCategory, setOpenAddCategory] = useState<boolean>(false);
  const [buildingName, setBuildingName] = useState<string>("");
  const [categoryName, setCategoryName] = useState<string>("");
  const [iconId, setIconId] = useState<number>(0);
  const [serviceCategories, setServiceCategories] = useState<
    ServiceCategory[] | null
  >(null);
  const [preload, setPreload] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [progressing, setProgressing] = useState<boolean>(false);
  const [activeItemId, setActiveItemId] = useState<number | null>(null);
  //dispatch
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    const getBuildingInfo = async () => {
      if (userBuildingInfo.buildingInfo != null) {
        setBuildingName(userBuildingInfo.buildingInfo.buildingName);
      } else {
        dispatch(GetBuildingInfoDataAction(buildingId ?? ""));
      }
    };
    getBuildingInfo();
  }, [userBuildingInfo]);

  useEffect(() => {
    const getCategoriesInfo = async () => {
      if (buildingServiceCategoriesInfo.fill) {
        setServiceCategories(buildingServiceCategoriesInfo.categories);
        setPreload(false);
      } else {
        dispatch(GetBuildingServiceCategories(Number(buildingId)));
      }
    };
    getCategoriesInfo();
  }, [buildingServiceCategoriesInfo]);

  // Handle clicking outside to reset all isActive states
  useEffect(() => {
    const handleClickOutside: (event: MouseEvent) => void = () => {
      setActiveItemId(null); 
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const openAddCategoryBoxHandler = () => {
    setOpenAddCategory(!openAddCategory);
    changeInfoHandler("", 0);
    setActiveItemId(null);
  };

  const addServiceCategoryHandler = async () => {
    setLoading(true);
    setProgressing(true);
    const addCategoryResult = await AddServiceCategoryService(
      Number(buildingId),
      categoryName,
      iconId
    );
    if (addCategoryResult.data) {
      openAddCategoryBoxHandler();
      dispatch(GetBuildingServiceCategories(Number(buildingId)));
      CreateToast(
        ToastType.Success,
        translate("ALERT.ADD_SUCCESSFULLY", {
          value: translate("SHARED.CATEGORY"),
        })
      );
      setLoading(false);
      setProgressing(false);
    } else {
      CreateToast(
        ToastType.Error,
        translate("ERRORS.ADDED_FAILD", {
          value: translate("SHARED.CATEGORY"),
        })
      );
      setLoading(false);
      setProgressing(false);
    }
  };
  // Use custom hook to lock body scroll
  useBodyScrollLock(openAddCategory);
  const changeInfoHandler = (categoryName: string, categoryIcon: number) => {
    setIconId(categoryIcon);
    setCategoryName(categoryName);
  };
  const handleItemClick = (itemId: number, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent the click from bubbling up
    setActiveItemId(itemId);
  };
  const existingCategoryNames =
    serviceCategories
      ?.map((category: ServiceCategory) => category.title?.toLowerCase())
      .filter(
        (title: string | null | undefined): title is string =>
          title !== null && title !== undefined
      ) || [];
  return (
    <DashboardBox>
      <DashboardTitle
        icon={<BuildOutlinedIcon className={Style.service_icon} />}
        label={`${buildingName} ${translate("TITLES.SERVICE_CATEGORY")}`}
        className={Style.service_title}
      />
      {preload ? (
        <Loading />
      ) : (
        <div className={Style.service_items}>
          <div className={Style.service_items_container}>
            <div onClick={openAddCategoryBoxHandler}>
              <ServiceCategoryItem
                className={Style.add_card}
                color={"var(--icon-primary)"}
                icon={"Add"}
                title={translate("BUTTON.ADD")}
                isActive={false} // Check if this item is active
              />
            </div>
            {serviceCategories?.map((item: ServiceCategory, index: number) => (
              <ServiceCategoryItem
                color={item.iconColor ?? ""}
                icon={item.iconTitle ?? ""}
                key={index}
                title={item.title ?? ""}
                link={`/dashboard/building/services/${buildingId}/${item.id}`}
                callToActionComponent={
                  item.buildingId ? (
                    <ServiceCategoryModifieItems serviceCategory={item} />
                  ) : (
                    <></>
                  )
                }
                isActive={item.id === activeItemId}
                onClick={(event: React.MouseEvent<Element, MouseEvent>) => handleItemClick(item.id!, event)}
              />
            ))}
          </div>
        </div>
      )}
      {openAddCategory && (
        <ServiceCategoryPopUp
          categoryServiceName={categoryName}
          onChangeHandler={changeInfoHandler}
          title={translate("SERVICE.NEW_CATEGORY")}
          onCancelHandler={openAddCategoryBoxHandler}
          onClickHandler={addServiceCategoryHandler}
          callToActionButtonText={translate("BUTTON.ADD")}
          existingCategoryNames={existingCategoryNames}
          loading={progressing}
        />
      )}
      {loading && <Loading />}
    </DashboardBox>
  );
};
export default ServicesCategoryList;
