import React, { useEffect, useState } from "react";
import Button from "../../../tools/button/Button";
import PasswordField from "../../../tools/fields/passwordField/PasswordField";
import { UserContactValidationModel } from "../../../viewModels/dataTypes/Cookie";
import { useNavigate } from "react-router-dom";
import { CheckUserValidation } from "../../../services/commonServices/CommonServices";
import { PasswordRegex } from "../../../services/Regex";
import { FieldState } from "../../../viewModels/enums/PublicEnum";
import { Localstorage_UserValidation } from "../../../text/LocalStorageValues";
import { useTranslation } from "react-i18next";
import { IServiceResult } from "../../../viewModels/api/Api";
import {
  ChangePasswordService,
  CheckValidationToChangePassword,
} from "./service/ChangePassword.Service";
import BeforeDashboardForm from "../BeforeDashboardForm";
import { ButtonState } from "../../../viewModels/enums/ButtonEnum";
import Loading from "../../../tools/loading/Loading";
import {
  CheckConfirmPasswordService,
  CheckPasswordService,
} from "../registration/services/CheckInfo";

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordFieldState, setPasswordFieldState] = useState<FieldState>(
    FieldState.Default
  );
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>("");
  //confirm password
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordFieldState, setConfirmPasswordFieldState] =
    useState<FieldState>(FieldState.Default);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState<string>("");

  const [buttonState, setButtonState] = useState(ButtonState.disable);
  const [TheUserValidation, setTheUserValidation] =
    useState<UserContactValidationModel>();

  useEffect(() => {
    if (!localStorage.getItem(Localstorage_UserValidation)) {
      navigate("/");
    } else {
      var userValidation = CheckUserValidation();
      if (
        userValidation.data != null &&
        CheckValidationToChangePassword(userValidation.data)
      ) {
        setTheUserValidation(userValidation.data);
      } else {
        navigate("/");
      }
    }
  }, []);

  const checkPassword = async () => {
    setLoading(true);

    let checkPassword = CheckPasswordService(
      password,
      setPasswordErrorMessage,
      setPasswordFieldState
    );
    let checkConfirmPassword = CheckConfirmPasswordService(
      password,
      confirmPassword,
      setConfirmPasswordErrorMessage,
      setConfirmPasswordFieldState
    );
    if (checkPassword && checkConfirmPassword) {
      const changePasswordResult: IServiceResult<boolean> =
        await ChangePasswordService(password, TheUserValidation?.id ?? "");
      if (changePasswordResult.data == true) {
        navigate("/login/resetPasswordSuccessfully");
      } else {
        setPasswordErrorMessage(changePasswordResult.message);
        setPasswordFieldState(FieldState.Error);

        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const confirmPasswordRegex = (value: string) => {
    if (password === value) {
      return true;
    } else {
      return false;
    }
  };

  const onChangePassword = (password: string) => {
    setPasswordFieldState(FieldState.Default);
    setPasswordErrorMessage("");
    setPassword(password);
    if (password.length > 0 && confirmPassword.length > 0) {
      setButtonState(ButtonState.default);
    } else {
      setButtonState(ButtonState.disable);
    }
    if (password == confirmPassword) {
      setConfirmPasswordFieldState(FieldState.Default);
      setConfirmPasswordErrorMessage("");
    }
  };

  const onChangeConfirmPassword = (confirmPassword: string) => {
    setConfirmPasswordFieldState(FieldState.Default);
    setConfirmPasswordErrorMessage("");
    setConfirmPassword(confirmPassword);
    if (password.length > 0 && confirmPassword.length > 0) {
      setButtonState(ButtonState.default);
    } else {
      setButtonState(ButtonState.disable);
    }
  };

  const { t: translate } = useTranslation();

  return (
    <>
      <BeforeDashboardForm
        title={translate("AUTHENTICATION.CHANGE_PASSWORD")}
        description={translate("AUTHENTICATION.ENTER_NEW_PASSWORD")}
        body={
          <div>
            <PasswordField
              errorMessage={passwordErrorMessage}
              label={translate("AUTHENTICATION.NEW_PASSWORD")}
              onChange={onChangePassword}
              condotion={PasswordRegex}
              checkPass={true}
              fieldState={passwordFieldState}
              placeholder={translate("PLACEHOLDER.PASSWORD")}
            />
            <PasswordField
              errorMessage={confirmPasswordErrorMessage}
              onChange={onChangeConfirmPassword}
              condotion={confirmPasswordRegex}
              label={translate("AUTHENTICATION.CONFIRM_PASSWORD")}
              placeholder={translate("PLACEHOLDER.CONFIRM_PASSWORD")}
              checkPass={true}
              fieldState={confirmPasswordFieldState}
            />
            <div className="mt-3">
              <Button
                state={buttonState}
                text={translate("BUTTON.NEXT")}
                clickMethod={checkPassword}
              />
            </div>
          </div>
        }
      />
      {loading && <Loading />}
    </>
  );
};

export default ChangePassword;
