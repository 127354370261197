import React, { useEffect, useState } from "react";
import Style from "./CheckOtp.module.scss";
import Button from "../../../tools/button/Button";
import CheckCode from "../../../tools/fields/checkCode/CheckCode";
import { useNavigate } from "react-router-dom";
import { UserContactValidationModel } from "../../../viewModels/dataTypes/Cookie";
import { MessageType } from "../../../viewModels/enums/MessageType";
import Loading from "../../../tools/loading/Loading";
import { FloorinsScrollTop } from "../../../services/commonServices/CommonServices";
import Timer from "../../../tools/timer/Timer";
import { Localstorage_UserValidation } from "../../../text/LocalStorageValues";
import { useTranslation } from "react-i18next";
import { CheckOtpService } from "./CheckOtp.Service";
import { IServiceResult } from "../../../viewModels/api/Api";
import { ICheckOtpResponse } from "../../../viewModels/dataTypes/registration/Otp";
import { ReSendOtpService } from "./ResendOtp.Services";
import BeforeDashboardForm from "../BeforeDashboardForm";
import { FieldState } from "../../../viewModels/enums/PublicEnum";
import { ButtonState } from "../../../viewModels/enums/ButtonEnum";
import { ReceiverType } from "../../../viewModels/api/Models";
import { GeneralOtpCount } from "../../../Setting";

interface Props {
  title: string;
  description: string;
  deliveryType: ReceiverType;
  otpLength: number;
}
const CheckOtp: React.FC<Props> = ({
  title,
  description,
  deliveryType,
  otpLength,
}) => {
  // error
  const [buttonState, setButtonState] = useState(ButtonState.disable);
  const [loading, setLoading] = useState<boolean>(false);
  const [messageContent, setMessageContent] = useState<string>("");
  const [messageType, setMessageType] = useState<MessageType | null>(null);
  const [code, setCode] = useState<string[]>(Array(otpLength).fill(""));
  const [finalCode, setFinalCode] = useState<string>("");
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [otpFieldState, setOtpFieldState] = useState<FieldState>(
    FieldState.Default
  );
  const [receiver, setReceiver] = useState<string>("");
  const [otpDisabled, setOtpDisabled] = useState<boolean>(false);
  const [otpBlocked, setOtpBlocked] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const savedMessageContent: string | null =
      localStorage.getItem("otpMessageContent");
    const savedMessageType: string | null =
      localStorage.getItem("otpMessageType");
    const savedIsOtpBlocked: string | null =
      localStorage.getItem("isOtpBlocked");
    const savedOtpDisabled: string | null = localStorage.getItem("otpDisabled");
    const savedOtpFieldState: string | null =
      localStorage.getItem("otpFieldState");

    if (savedMessageContent) setMessageContent(savedMessageContent);
    if (savedMessageType) setMessageType(JSON.parse(savedMessageType));

    if (savedIsOtpBlocked) {
      const isBlocked: boolean = JSON.parse(savedIsOtpBlocked);
      setOtpBlocked(isBlocked);
      setOtpDisabled(isBlocked);
    }

    if (savedOtpDisabled) setOtpDisabled(JSON.parse(savedOtpDisabled));
    if (savedOtpFieldState) setOtpFieldState(JSON.parse(savedOtpFieldState));
  }, []);

  useEffect(() => {
    if (otpBlocked) {
      setOtpDisabled(true);
    }
  }, [otpBlocked]);

  useEffect(() => {
    if (!otpDisabled) {
      setOtpBlocked(false);
    }
  }, [otpDisabled]);

  useEffect(() => {
    localStorage.setItem("otpMessageContent", messageContent);
    localStorage.setItem("otpMessageType", JSON.stringify(messageType));
    localStorage.setItem("otpDisabled", JSON.stringify(otpDisabled));
    localStorage.setItem("isOtpBlocked", JSON.stringify(otpBlocked));
    localStorage.setItem("otpFieldState", JSON.stringify(otpFieldState));
  }, [messageContent, messageType, otpDisabled, otpBlocked, otpFieldState]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("otpMessageContent");
      localStorage.removeItem("otpMessageType");
      localStorage.removeItem("otpDisabled");
      localStorage.removeItem("isOtpBlocked");
      localStorage.removeItem("otpFieldState");
      localStorage.removeItem("timerValue");
    };
  }, []);

  useEffect(() => {
    if (!localStorage.getItem(Localstorage_UserValidation)) {
      navigate("/");
    } else {
      var ueserDataJson = localStorage.getItem(Localstorage_UserValidation);
      if (ueserDataJson != null) {
        var userValidationData: UserContactValidationModel =
          JSON.parse(ueserDataJson);

        setReceiver(
          userValidationData.userCountry != null
            ? userValidationData.userCountry.code +
                userValidationData.userContact
            : userValidationData.userContact
        );
      } else {
        navigate("/");
      }
    }
  }, [navigate]);

  const submitOtp = async (otpCode: string) => {
    if (receiver.length > 0) {
      setResetForm(false);
      setLoading(true);
      setButtonState(ButtonState.default);
      let result: IServiceResult<ICheckOtpResponse> = await CheckOtpService(
        {
          code: otpCode,
          receiver: receiver,
          receiverType: deliveryType,
        },
        setOtpDisabled
      );

      if (result.data != null) {
        if (result.data?.isNewUser) {
          navigate("/signup/registrationInfo");
        } else {
          if (result.data.isVerified) {
            navigate("/login/checkPassword");
          } else {
            navigate("/completeInfo");
          }
        }
      } else {
        setMessageType(MessageType.Error);
        setMessageContent(result.message);
        setLoading(false);
        setResetForm(true);
        setCode(Array(otpLength).fill(""));
        setOtpFieldState(FieldState.Error);
        setButtonState(ButtonState.disable);
      }
    } else {
      setButtonState(ButtonState.disable);
      setMessageType(MessageType.Error);
      setMessageContent("ERRORS.INVALID_OTP");
      setLoading(false);
      setOtpFieldState(FieldState.Error);
    }
  };

  const setOtpCode = (nextNumber: string, index: number) => {
    setMessageContent("");
    setMessageType(null);
    setOtpFieldState(FieldState.Default);
    code[index] = nextNumber;
    setCode(code);
    if (code.every((item: string) => item.length > 0)) {
      var Code: string = "";
      for (var i: number = 0; i < code.length; i++) {
        Code = Code + code[i];
      }
      setFinalCode(Code);
      submitOtp(Code);
    }
  };

  const resendCode = async () => {
    setResetForm(false);
    setOtpFieldState(FieldState.Error);
    setOtpDisabled(false);
    setOtpBlocked(false);
    if (receiver.length > 0) {
      setLoading(true);
      setOtpFieldState(FieldState.Default);
      var resendCodeResult: IServiceResult<any> = await ReSendOtpService(
        receiver,
        deliveryType
      );
      if (resendCodeResult.data != null) {
        setResetForm(true);
        setCode(Array(otpLength).fill(""));
        FloorinsScrollTop();
        setMessageType(null);
        setMessageContent(resendCodeResult.message);
        setLoading(false);
      } else {
        FloorinsScrollTop();
        setMessageType(MessageType.Error);
        setMessageContent(resendCodeResult.message);
        setLoading(false);
      }
    } else {
      FloorinsScrollTop();
      setMessageType(MessageType.Error);
      setMessageContent("ERRORS.OPERATION_FAILD");
      setLoading(false);
    }
  };

  const { t: translate } = useTranslation();
  return (
    <>
      <BeforeDashboardForm
        title={translate(title)}
        description={translate(description)}
        body={
          <div>
            <div className="mt-2 mb-5">
              {messageType == null && (
                <div className={Style.otp_receiver}>
                  <span>
                    {translate("AUTHENTICATION.OTP_RECEIVER", {
                      value: receiver,
                    })}
                  </span>
                </div>
              )}
              <CheckCode
                setMethod={setOtpCode}
                resetMode={resetForm}
                fieldState={otpFieldState}
                errorMessage={messageContent}
                label=""
                errorMessageAlignCenter
                otpLength={GeneralOtpCount}
                otpDisabled={otpDisabled}
              />
              <Timer action={resendCode} />
            </div>
            <div className="mt-3">
              <Button
                state={buttonState}
                text={translate("BUTTON.NEXT")}
                link="./"
                clickMethod={() => submitOtp(finalCode)}
              />
            </div>

            {loading === true && <Loading />}
          </div>
        }
      />
    </>
  );
};

export default CheckOtp;
