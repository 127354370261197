import React, { ReactNode } from "react";
import Style from "./CounterAvatar.module.scss";
import { ClassNameViewModel } from "../../viewModels/dataTypes/CommonViewModels";

interface Props extends ClassNameViewModel {
  counterValue: string;
  counterText: string;
  callToActionComponent?: ReactNode;
}
const CounterAvatar: React.FC<Props> = ({
  counterText,
  counterValue,
  className = "",
  callToActionComponent,
}) => {
  return (
    <div className={`${Style.counter_flexbox} ${className}`}>
      <div className={Style.counter}>
        <div className={Style.counter_value}>
          {counterValue.length > 2
            ? counterValue.toString().substring(0, 1)
            : counterValue}
        </div>
        <div className={Style.counter_text}> {counterText}</div>
      </div>
      <div className={Style.additional_component}>{callToActionComponent}</div>
    </div>
  );
};

export default CounterAvatar;
