import React from "react";
import Style from "./LargeAvatar.module.scss";
import { User } from "iconsax-react";

const LargeAvatar: React.FC = () => {
  return (
    <div className={Style.avatar}>
      <User variant="Bold" />
    </div>
  );
};

export default LargeAvatar;
