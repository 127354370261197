import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Style from "./ServiceCategoryModifieItems.module.scss";
import { IconButton, Menu, MenuItem } from "@mui/material";
import ServiceCategoryPopUp from "../../servicesCategoryPopUp/ServiceCategoryPopUp";
import { ServiceCategory } from "../../../../../../../viewModels/api/Models";
import { useTranslation } from "react-i18next";
import { CreateToast } from "../../../../../../../tools/toast/ToastService";
import { ToastType } from "../../../../../../../viewModels/enums/ToastType";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../../../redux/Store";
import { GetBuildingServiceCategories } from "../../../../../../../redux/slices/BuildingServiceCategories";
import { useParams } from "react-router-dom";
import {
  DeleteServiceCategoryService,
  UpdateServiceCategoryService,
} from "./ServiceCategoryModifie.Service";
import DeleteServiceCategory from "./deleteServiceCategory/DeleteServiceCategory";
import Loading from "../../../../../../../tools/loading/Loading";
import { IReducer } from "../../../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import useBodyScrollLock from "../../../../../../../hook/BodyScrollLock";
import useCloseOnResize from "../../../../../../../hook/CloseOnResize";

interface Props {
  serviceCategory: ServiceCategory;
}

const ServiceCategoryModifieItems: React.FC<Props> = ({ serviceCategory }) => {
  const [dropDownElement, setDropDownElement] = useState<null | HTMLElement>(
    null
  );
  const { buildingId } = useParams();
  const [openEditPopUp, setOpenEditPopUp] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [progressing, setProgressing] = useState<boolean>(false);
  const [openDeletePopUp, setOpenDeletePopUp] = useState<boolean>(false);
  const open = Boolean(dropDownElement);
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t: translate } = useTranslation();
  const [categoryName, setCategoryName] = useState<string>(
    serviceCategory?.title ?? ""
  );
  const [iconId, setIconId] = useState<number>(serviceCategory.iconId ?? 0);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setDropDownElement(event.currentTarget);
  };
  const handleClose = () => {
    setDropDownElement(null);
  };

  const openServiceCategoryHandler = () => {
    setDropDownElement(null);
    changeInfoHandler(
      serviceCategory.title ?? "",
      Number(serviceCategory.iconId)
    );
    setOpenEditPopUp(!openEditPopUp);
  };
  const openDeletePopUpHandler = () => {
    handleClose();
    changeInfoHandler(
      serviceCategory.title ?? "",
      Number(serviceCategory.iconId)
    );
    setOpenDeletePopUp(!openDeletePopUp);
  };
  const updateServiceCategoryHandler = async () => {
    setLoading(true);
    setProgressing(true);
    const updateCategoryResult = await UpdateServiceCategoryService(
      Number(buildingId),
      categoryName,
      iconId,
      serviceCategory.id ?? 0
    );
    if (updateCategoryResult.data) {
      openServiceCategoryHandler();
      dispatch(GetBuildingServiceCategories(Number(buildingId)));
      CreateToast(ToastType.Success, translate("ALERT.EDIT_SUCCESS"));
      setLoading(false);
      setProgressing(false);
    } else {
      CreateToast(ToastType.Error, translate("ERRORS.EDITION_FAILD"));
      setLoading(false);
      setProgressing(false);
    }
  };
  const changeInfoHandler = (categoryName: string, categoryIcon: number) => {
    setIconId(categoryIcon);
    setCategoryName(categoryName);
  };

  const deleteServiceCategoryHandler = async () => {
    setLoading(true);
    setProgressing(true);
    const deleteCategoryResult = await DeleteServiceCategoryService(
      serviceCategory.id ?? 0
    );

    if (deleteCategoryResult.data) {
      openDeletePopUpHandler();
      dispatch(GetBuildingServiceCategories(Number(buildingId)));
      CreateToast(
        ToastType.Success,
        translate("ALERT.DELETED_SUCCESSFULLY", {
          value: translate("SHARED.CATEGORY"),
        })
      );
      setLoading(false);
      setProgressing(false);
    } else {
      CreateToast(
        ToastType.Error,
        translate("ERRORS.DELETED_FAILD", {
          value: translate("SHARED.CATEGORY"),
        })
      );
      setLoading(false);
      setProgressing(false);
    }
  };
  const buildingServiceCategoriesInfo = useSelector(
    (state: IReducer) => state.buildingServiceCategories
  );
  const existingCategoryNames = buildingServiceCategoriesInfo.categories?.map(
    (category: ServiceCategory) => (category.title ?? "").toLowerCase()
  );
  // Use custom hook to lock body scroll
  useBodyScrollLock(openEditPopUp || openDeletePopUp);
  // Using the custom hook
  useCloseOnResize(handleClose);
  return (
    <>
      <div className={Style.dropdown}>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          className={Style.dropdown_items}
          anchorEl={dropDownElement}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            key={`${serviceCategory.title} Delete`}
            onClick={openDeletePopUpHandler}
          >
            <span>{translate("SERVICE.DELETE_CATEGORY")}</span>
          </MenuItem>
          <MenuItem
            key={`${serviceCategory.title} Update`}
            onClick={openServiceCategoryHandler}
          >
            <span>{translate("SERVICE.EDIT_CATEGORY")}</span>
          </MenuItem>
        </Menu>
      </div>
      {openEditPopUp && (
        <ServiceCategoryPopUp
          categoryServiceName={categoryName}
          categoryServiceIconId={iconId}
          onChangeHandler={changeInfoHandler}
          title={translate("SERVICE.EDIT_CATEGORY")}
          onCancelHandler={openServiceCategoryHandler}
          onClickHandler={updateServiceCategoryHandler}
          callToActionButtonText={translate("BUTTON.SAVE")}
          existingCategoryNames={existingCategoryNames}
          openEditPopUp={openEditPopUp}
          loading={progressing}
        />
      )}
      {openDeletePopUp && (
        <div>
          <DeleteServiceCategory
            categoryName={categoryName}
            cancelDeleteHandler={openDeletePopUpHandler}
            deleteHandler={deleteServiceCategoryHandler}
            loading={progressing}
          />
        </div>
      )}
      {loading && <Loading />}
    </>
  );
};

export default ServiceCategoryModifieItems;
