import React, { ChangeEvent, useEffect, useState } from "react";
import Style from "./CheckCode.module.scss";
import { FieldState } from "../../../viewModels/enums/PublicEnum";
import { MessageHandler } from "../../messagesHandler/MessagesHandler";
import { MessageType } from "../../../viewModels/enums/MessageType";
import { useTranslation } from "react-i18next";
import { GeneralOtpCount } from "../../../Setting";
import { generateOtpCodeMask } from "./CheckCode.Service";
import useNumericInputMode from "../../../hook/NumericInputMode";

interface Props {
  setMethod: (nextNumber: string, index: number) => void;
  resetMode?: boolean;
  fieldState?: FieldState;
  errorMessage: string;
  label?: string;
  errorMessageAlignCenter?: boolean;
  otpLength?: number; // Add a prop to specify OTP length
  otpDisabled?: boolean;
}

const CheckCode: React.FC<Props> = ({
  setMethod,
  resetMode = false,
  fieldState = FieldState.Default,
  errorMessage,
  label,
  errorMessageAlignCenter = false,
  otpLength = GeneralOtpCount, // Default OTP length
  otpDisabled = false,
}) => {
  const [hasRendered, setHasRendered] = useState<boolean>(false);
  const inputRefs = useNumericInputMode(); // Use the custom hook
  const otpCodeMask: string[] = generateOtpCodeMask(otpLength); // Generate dynamic OTP code mask
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (resetMode) {
      handleReset();
    }
  }, [resetMode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      requestAnimationFrame(() => {
        inputRefs.current[0]?.focus();
      });
    }, 100); // Increase timeout for Safari on iOS
    // Trigger a second render to ensure focus
    setHasRendered(true);
    return () => clearTimeout(timer);
  }, [hasRendered]);

  const handleNextInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const nextSibling: HTMLInputElement | null = inputRefs.current[index + 1];
    if (e.target.value.length > 0) {
      e.target.value = e.target.value.charAt(e.target.value.length - 1);
      setMethod(e.target.value, index);
      if (nextSibling) {
        nextSibling.focus();
      }
    }
  };
  const handleReset = () => {
    inputRefs.current.forEach((ref: HTMLInputElement | null) => {
      if (ref) {
        ref.value = "";
      }
    });
    const timer = setTimeout(() => {
      requestAnimationFrame(() => {
        inputRefs.current[0]?.focus();
      });
    }, 100); // Increase timeout for Safari on iOS

    return () => clearTimeout(timer);
  };
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      if (inputRefs.current[index]?.value) {
        inputRefs.current[index]!.value = "";
        setMethod("", index);
      } else if (index > 0) {
        const prevSibling: HTMLInputElement | null =
          inputRefs.current[index - 1];
        if (prevSibling) {
          prevSibling.focus();
        }
      }
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const inputs = otpCodeMask.map((_, index: number) => (
    <input
      key={index}
      id={`box${index + 1}`}
      type="text"
      maxLength={1}
      onFocus={handleFocus}
      autoComplete="off"
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleNextInput(e, index)}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
        handleKeyDown(e, index)
      }
      ref={(element: HTMLInputElement | null) =>
        (inputRefs.current[index] = element)
      }
      disabled={otpDisabled}
    />
  ));

  return (
    <>
      {errorMessage.length !== 0 ? (
        <MessageHandler
          type={MessageType.Error}
          message={translate(errorMessage)}
          alignCenter={errorMessageAlignCenter}
        />
      ) : label?.length !== 0 ? (
        <label className={Style.label}>
          <span>{label}</span>
        </label>
      ) : (
        <></>
      )}
      <div
        className={`${Style.code_card} ${
          fieldState === FieldState.Error && Style.error
        }`}
      >
        {inputs}
      </div>
    </>
  );
};

export default CheckCode;
