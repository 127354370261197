import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MessagePopUp from "../../../../../../../../tools/popup/MessagePopUp";
import { InfoCircle } from "iconsax-react";
import Button from "../../../../../../../../tools/button/Button";
import { ButtonTheme } from "../../../../../../../../viewModels/enums/ButtonEnum";
import { SizeType } from "../../../../../../../../viewModels/dataTypes/tools/ListComponentType";
import Style from "./AddBuildingServiceContainer.module.scss";

interface Props {
  cancelButton: () => void;
}
export const CancelAddService: React.FC<Props> = ({ cancelButton }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const lastPage = () => {
    navigate(-1);
  };

  return (
    <MessagePopUp
      icon={<InfoCircle />}
      title={translate("SHARED.LEAVE_PAGE")}
      description={translate("ALERT.LEAVE_PAGE")}
      buttons={
        <>
          <Button
            className={Style.cancel_button}
            text={translate("BUTTON.CANCEL")}
            size={SizeType.small}
            clickMethod={cancelButton}
            theme={ButtonTheme.primaryReverse}
          />
          <Button
            className={Style.cancel_button}
            text={translate("BUTTON.DISCARD")}
            size={SizeType.small}
            clickMethod={lastPage}
            theme={ButtonTheme.primary}
          />
        </>
      }
    />
  );
};
