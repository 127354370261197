import React, { ReactElement } from "react";
import Style from "./ServiceItem.module.scss";

import { Link } from "react-router-dom";

interface Props {
  icon: ReactElement;
  title: string;
  desc: string;
  link?: string;
}
const Item: React.FC<Props> = ({ icon, title, desc, link = "" }) => {
  return (
    <div className={`${Style.card}`}>
      <Link to={link}>
        {icon}
        <h2>{title}</h2>
        <p>{desc}</p>
      </Link>
    </div>
  );
};

export default Item;
