import React, { useState } from "react";
import Style from "./StateComponent.module.scss";
import CustomExpandMoreIcon from "../../icons/CustomeExpandMoreIcon";
import {
  DotIcon,
  StateLabel,
  ExpandMoreIcon,
  CustomMenuItem,
  CustomSelect,
  CustomFormControl,
} from "./StateComponentStyled";
import useDropdownPosition from "../../../hook/DropdownPosition";
import useCloseOnResize from "../../../hook/CloseOnResize";

interface StateComponentProps {
  status: number;
  stateItems: string[];
  stateColors: string[];
  hovered: boolean | undefined;
  onSelect: (index: number) => void;
}

const StateComponent: React.FC<StateComponentProps> = ({
  status,
  stateItems,
  stateColors,
  hovered,
  onSelect,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { selectRef, menuPosition, calculatePosition } = useDropdownPosition();
  const divRef = React.useRef<HTMLDivElement>(null);

  const getBorderColor = (index: number) => stateColors[index] || "transparent";

  const handleClick = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  const handleMenuItemClick = (index: number) => {
    onSelect(index);
    handleClose();
  };

  useCloseOnResize(handleClose);

  return (
    <>
      <div className={Style.state_container} ref={divRef}>
        <CustomFormControl ref={selectRef}>
          <CustomSelect
            onOpen={calculatePosition}
            open={open}
            onClose={handleClose}
            onClick={handleClick}
            hovered={hovered}
            borderColor={getBorderColor(status)}
            className={Style.state_button}
            value={stateItems[status]}
            IconComponent={() => null}
            MenuProps={{
              PaperProps: {
                sx: {
                  boxShadow: "0px 5.8px 11.6px 0px #0000001F",
                },
              },
              MenuListProps: {
                sx: {
                  width: "7.5rem",
                  padding: "0px",
                },
              },
              classes: { paper: Style.dropdown_menu },
              anchorOrigin: {
                vertical: menuPosition === "top" ? "top" : "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: menuPosition === "top" ? "bottom" : "top",
                horizontal: "left",
              },
            }}
            renderValue={() => (
              <div className={Style.button_container}>
                <div className={Style.dot_icon_container}>
                  <DotIcon
                    className={Style.dot_icon}
                    hovered={hovered}
                    borderColor={getBorderColor(status)}
                  />
                  <span className={Style.state_label}>
                    {stateItems[status]}
                  </span>
                </div>
                <ExpandMoreIcon hovered={hovered as boolean}>
                  <CustomExpandMoreIcon open={open} />
                </ExpandMoreIcon>
              </div>
            )}
          >
            {stateItems.map((option: string, index: number) => (
              <CustomMenuItem
                key={index}
                value={option}
                onClick={() => handleMenuItemClick(index)}
              >
                <StateLabel className={Style.state_item}>{option}</StateLabel>
              </CustomMenuItem>
            ))}
          </CustomSelect>
        </CustomFormControl>
      </div>
    </>
  );
};

export default StateComponent;
