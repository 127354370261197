import React from "react";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";
import useWindowSize from "../../../hook/WindowSize";
import { UltraSmallDeviceTrigger } from "../../../Setting";

const DefaultPickerDay: React.FC<PickersDayProps<dayjs.Dayjs>> = (props) => {
  const windowSize = useWindowSize();
  const isMobile: boolean = windowSize.width < UltraSmallDeviceTrigger;
  return (
    <PickersDay
      {...props}
      sx={{
        "&.Mui-selected": {
          backgroundColor: "var(--bg-blue-low) !important",
          color: "var(--text-black)",
        },
        "&:hover": {
          backgroundColor: isMobile
            ? "var(--bg-blue-low) !important" // Hover effect for mobile devices
            : "var(--bg-gray-low) !important", // Hover effect for non-mobile devices
        },
      }}
    />
  );
};

export default DefaultPickerDay;
