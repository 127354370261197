import {
  GetCitiesByProvince,
  GetProvincesByCountry,
} from "../../../../services/api/Building";
import { ApiResponse } from "../../../../viewModels/api/Api";
import { ICity } from "../../../../viewModels/dataTypes/country/Country";
import { ResponseCode } from "../../../../viewModels/enums/ResponseCode";

export const GetProvince = async (counteryId: number) => {
  const getProvinceResult: ApiResponse<ICity[]> = await GetProvincesByCountry(
    counteryId
  );

  if (getProvinceResult.responseStatus == ResponseCode.Success) {
    return getProvinceResult.data;
  } else {
    return null;
  }
};
export const GetCities = async (province: string) => {
  if (province.length > 0) {
    const getProvinceResult: ApiResponse<ICity[]> = await GetCitiesByProvince(
      province
    );
    if (getProvinceResult.responseStatus == ResponseCode.Success) {
      return getProvinceResult.data;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
