import { GetBuildingUnits } from "../../../../../../../services/api/Unit";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../../viewModels/api/Api";
import { IUnitInfo } from "../../../../../../../viewModels/dataTypes/building/IUnitInfo";
import { ResponseCode } from "../../../../../../../viewModels/enums/ResponseCode";

export const GetBuildingUnitsService = async (buildingId: string) => {
  var resultModel: IServiceResult<IUnitInfo[]> = {
    message: "",
  };
  var result: ApiResponse<IUnitInfo[]> = await GetBuildingUnits(buildingId);
  if (result.responseStatus == ResponseCode.Success) {
    resultModel.data = result.data;
  }

  return resultModel;
};
