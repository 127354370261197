import React from "react";
import Button from "../../../../../../tools/button/Button";
import { useTranslation } from "react-i18next";
import { SizeType } from "../../../../../../viewModels/dataTypes/tools/ListComponentType";
import { Add } from "iconsax-react";
import Style from "./AddBuildingButton.module.scss";
const AddBuildingButton = () => {
  const { t: translate } = useTranslation();
  return (
    <div className={Style.add_button}>
      <Button
        link={"/"}
        text={translate("DASHBOARD.BUILDING")}
        size={SizeType.small}
        icon={<Add className={Style.add_button_icon} />}
      />
    </div>
  );
};

export default AddBuildingButton;
