import React, { useEffect, useState } from "react";
import { DashboardBox } from "../../../../../../../../tools/box/DashboardBox";
import DashboardTitle from "../../../../../../../../tools/dashboardTitle/DashboardTitle";
import IconWrapper from "../../../../../../../../tools/icons/CustomIcon";
import { useParams } from "react-router-dom";
import {
  IBuildingServiceCategoriesReducer,
  IReducer,
} from "../../../../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../../../../redux/Store";
import { useTranslation } from "react-i18next";
import { ServiceCategory } from "../../../../../../../../viewModels/api/Models";
import { GetBuildingServiceCategories } from "../../../../../../../../redux/slices/BuildingServiceCategories";
import Style from "./AddBuildingServiceContainer.module.scss";
import AddServiceForm from "./addServiceForm/AddServiceForm";
import Loading from "../../../../../../../../tools/loading/Loading";

const AddBuildingServiceContainer = () => {
  const { categoryId, buildingId } = useParams();

  const buildingServiceCategoriesInfo: IBuildingServiceCategoriesReducer =
    useSelector((state: IReducer) => state.buildingServiceCategories);
  const [categoryIcon, setCategoryIcon] = useState<string>("");
  const [categoryName, setCategoryName] = useState<string>("");
  const [category, setCategory] = useState<ServiceCategory | null>(null);
  const dispatch = useDispatch<AppThunkDispatch>();

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (buildingServiceCategoriesInfo.fill) {
      if (
        buildingServiceCategoriesInfo.categories &&
        (buildingServiceCategoriesInfo?.categories[
          buildingServiceCategoriesInfo?.categories.length - 1
        ].buildingId === Number(buildingId) ||
          buildingServiceCategoriesInfo?.categories[
            buildingServiceCategoriesInfo?.categories.length - 1
          ].buildingId === 0)
      ) {
        const currentServiceCategory =
          buildingServiceCategoriesInfo.categories?.find(
            (category: ServiceCategory) => category.id === Number(categoryId)
          );
        setCategoryIcon(currentServiceCategory?.iconTitle ?? "");
        setCategoryName(currentServiceCategory?.title ?? "");
        setCategory(currentServiceCategory ?? null);
      } else {
        dispatch(GetBuildingServiceCategories(Number(buildingId)));
      }
    } else {
      dispatch(GetBuildingServiceCategories(Number(buildingId)));
    }
  }, [buildingServiceCategoriesInfo.categories, buildingId]);

  return (
    <DashboardBox>
      {buildingServiceCategoriesInfo.fill && category ? (
        <>
          <DashboardTitle
            icon={
              <IconWrapper
                name={categoryIcon}
                className={Style.category_icon}
              />
            }
            label={`${categoryName} ${translate("SERVICE.SERVICES")}`}
            className={Style.service_title}
          />

          <AddServiceForm serviceCategory={category} />
        </>
      ) : (
        <Loading />
      )}
    </DashboardBox>
  );
};

export default AddBuildingServiceContainer;
