import styled from "styled-components";

export const GridBox = styled.div`
  min-height: 12vh;
  overflow: hidden;
  padding: 2rem;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  position: relative;
  height: auto;
  @media screen and (max-width: 1200px), screen and (max-height: 900px) {
    border-radius: 12px;
  }
  @media (max-width: 900px) {
    padding: 1rem;
  }
  @media (max-width: 900px) {
    border-radius: 8px;
  }
  @media (max-width: 600px) {
    padding: 0rem;
  }
  @media screen and (max-width: 900px), screen and (max-height: 768) {
    overflow-y: auto;
  }
`;
