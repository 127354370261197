import React from "react";
import Style from "./DayComponent.module.scss";
import { WeekDaysType } from "../../../viewModels/dataTypes/tools/WeekDaysType";

const DayComponent: React.FC<WeekDaysType> = ({ isActive = false, label }) => {
  return (
    <div className={`${Style.item} ${isActive ? Style.active_item : ""}`}>
      {label.toString().substring(0, 1)}
    </div>
  );
};

export default DayComponent;
