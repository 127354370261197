import React, { useEffect, useState } from "react";
import Style from "./AddResidentForm.module.scss";
import { Grid } from "@mui/material";
import Accordion from "../../../../../../tools/accordion/Accordion";
import { Profile, Profile2User } from "iconsax-react";
import ResidentInfoForm from "./residentInfoForm/ResidentInfoForm";
import { CountryType } from "../../../../../../viewModels/dataTypes/country/Country";
import { useTranslation } from "react-i18next";
import UnitInfo from "./unitInfo/UnitInfo";
import TenantContract from "./tenantContract/TenantContract";
import {
  GetUnitResidentViewModel,
  IUnitInfo,
} from "../../../../../../viewModels/dataTypes/building/IUnitInfo";
import {
  CheckEmailValidation,
  CheckPhoneNumberValidation,
  CreateUnitResidentsService,
  DeleteResidentService,
  FindResidentInfo,
  GetUnitResidentsService,
} from "./AddResident.Service";
import { ResidentType } from "../../../../../../viewModels/enums/ResidentType";
import { IResident } from "../../../../../../viewModels/dataTypes/user/IResident";
import FloorinsDatePicker from "../../../../../../tools/datePicker/DatePicker";
import {
  ButtonState,
  ButtonTheme,
} from "../../../../../../viewModels/enums/ButtonEnum";
import Button from "../../../../../../tools/button/Button";
import { IServiceResult } from "../../../../../../viewModels/api/Api";
import CheckBox from "../../../../../../tools/checkBox/CheckBox";
import FirstLogin from "../../firstLogin/FirstLogin";
import { CancelAddAdmin } from "../addAdminForm/AddAdminPopUps";
import { useNavigate, useParams } from "react-router-dom";
import RemoveResident from "./removeResident/RemoveResident";
import Loading from "../../../../../../tools/loading/Loading";
import { CreateToast } from "../../../../../../tools/toast/ToastService";
import { ToastType } from "../../../../../../viewModels/enums/ToastType";
import { FieldState } from "../../../../../../viewModels/enums/PublicEnum";
import { DeletePopUp } from "./AddResidentPopup";
import {
  AddResidentViewModel,
  CreateUnitResidentsViewModel,
} from "../../../../../../viewModels/api/Models";
import { GetBuildingUsersAction } from "../../../../../../redux/slices/BuildingUsersSlice";
import { useDispatch } from "react-redux";
import { AppThunkDispatch } from "../../../../../../redux/Store";
import useBodyScrollLock from "../../../../../../hook/BodyScrollLock";
import { setHasUnsavedChanges } from "../../../../../../redux/slices/UnsavedChangesSlice";

interface Props {
  residentType: ResidentType;
}
const AddResidentForm: React.FC<Props> = ({ residentType }) => {
  const { t: translate } = useTranslation();

  const { buildingId } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch<AppThunkDispatch>();
  //primary user info
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [country, setCountry] = useState<CountryType | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<IUnitInfo | null>(null);
  const [residentId, setResidentId] = useState<number | null>(null);
  // const [existPrimaryResident, setExistPrimaryResident] = useState(false);
  const [existUserInfo, setExistUserInfo] = useState<boolean>(false);
  const [residentFirstLogin, setResidentFirstLogin] = useState<boolean>(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] =
    useState<string>("");
  const [phoneNumberFieldState, setPhoneNumberFieldState] = useState(
    FieldState.Default
  );
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>("");
  const [emailFieldState, setEmailFieldState] = useState<FieldState>(
    FieldState.Default
  );
  const [deleteResidentPopUp, setDeleteResidentPopUp] =
    useState<boolean>(false);
  //co user info
  const [coResidentId, setCoResidentId] = useState<number | null>(null);
  const [coUserEmail, setCoUserEmail] = useState<string>("");
  const [coUserPhoneNumber, setCoUserPhoneNumber] = useState<string>("");
  const [coUserFirstName, setCoUserFirstName] = useState<string>("");
  const [coUserlastName, setCoUserLastName] = useState<string>("");
  const [coUsercountry, setCoUserCountry] = useState<CountryType | null>(null);
  // const [existCoResident, setExistCoResident] = useState(false);
  const [existCoUserInfo, setExistCoUserInfo] = useState(false);
  const [coUserPhoneNumberErrorMessage, setCoUserPhoneNumberErrorMessage] =
    useState<string>("");
  const [coUserPhoneNumberFieldState, setcoUserPhoneNumberFieldState] =
    useState<FieldState>(FieldState.Default);
  const [coUserEmailErrorMessage, setCoUserEmailErrorMessage] =
    useState<string>("");
  const [coUserEmailFieldState, setcoUserEmailFieldState] =
    useState<FieldState>(FieldState.Default);
  const [coResidentFirstLogin, setCoResidentFirstLogin] =
    useState<boolean>(false);

  const [contractStartDate, setcontractStartDate] = useState<Date | null>(null);
  const [deleteCoResidentPopUp, setDeleteCoResidentPopUp] =
    useState<boolean>(false);
  const [contractEndDate, setcontractEndDate] = useState<Date | null>(null);
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.disable
  );
  const [isResident, setIsResident] = useState<boolean>(
    residentType === ResidentType.Tenant
  );
  const [mandatoriesChange, setMandatoriesChange] = useState<boolean>(false);
  const [cancelAddResidentState, setcancelAddResidentState] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [progressing, setProgressing] = useState<boolean>(false);

  //Effects

  useEffect(() => {
    const areMandatoriesFilled = Boolean(
      (phoneNumber && email && selectedUnit) ||
        (coUserPhoneNumber && coUserEmail && selectedUnit)
    );
    const isAnyFieldFilled = Boolean(
      phoneNumber ||
        email ||
        selectedUnit ||
        firstName ||
        lastName ||
        coUserFirstName ||
        coUserlastName ||
        coUserEmail ||
        coUserPhoneNumber ||
        contractStartDate ||
        contractEndDate
    );
    setButtonState(
      areMandatoriesFilled ? ButtonState.default : ButtonState.disable
    );
    setMandatoriesChange(isAnyFieldFilled);
  }, [
    phoneNumber,
    email,
    selectedUnit,
    firstName,
    lastName,
    coUserFirstName,
    coUserlastName,
    coUserEmail,
    coUserPhoneNumber,
    contractStartDate,
    contractEndDate,
  ]);

  const changeUnitNumber = async (unitInfo: IUnitInfo | null) => {
    resetPrimaryResidentForm();
    resetCoResidentForm();
    setSelectedUnit(unitInfo);
    if (unitInfo) {
      const _unitInfo: GetUnitResidentViewModel = {
        unitId: unitInfo.id,
        userRole: residentType,
      };
      setLoading(true);
      setProgressing(true);
      const residentsInfo = await GetUnitResidentsService(_unitInfo);
      if (residentsInfo.data) {
        const primaryResident = FindResidentInfo(residentsInfo.data, true);
        getPriamryResidentInfo(primaryResident);
        const coResident = FindResidentInfo(residentsInfo.data, false);
        getCoResidentInfo(coResident);
        setLoading(false);
        setProgressing(false);
      }
    }
  };

  const getPriamryResidentInfo = async (residentInfo: IResident | null) => {
    if (residentInfo == null) return;
    setResidentId(residentInfo.id);
    setIsResident(residentInfo.isResident);
    setResidentFirstLogin(residentInfo.firstLogin);
    setExistUserInfo(true);
    setFirstName(residentInfo.firstName);
    setLastName(residentInfo.lastName);
    setPhoneNumber(
      residentInfo.phoneNumber.replace(residentInfo.countryCode, "")
    );
    var userCountry: CountryType = {
      code: residentInfo.countryCode,
      name: residentInfo.countryName,
      symbol: residentInfo.countrySymbol,
    };
    setCountry(userCountry);
    setEmail(residentInfo.email);
    setcontractStartDate(residentInfo.contractStartDate);
    setcontractEndDate(residentInfo.contractEndDate);
  };

  const getCoResidentInfo = async (residentInfo: IResident | null) => {
    if (residentInfo == null) return;
    setCoResidentId(residentInfo.id);
    setIsResident(residentInfo.isResident);
    setExistCoUserInfo(true);
    setCoResidentFirstLogin(residentInfo.firstLogin);
    setCoUserFirstName(residentInfo.firstName);
    setCoUserLastName(residentInfo.lastName);
    setCoUserPhoneNumber(
      residentInfo.phoneNumber.replace(residentInfo.countryCode, "")
    );
    var userCountry: CountryType = {
      code: residentInfo.countryCode,
      name: residentInfo.countryName,
      symbol: residentInfo.countrySymbol,
    };
    setCoUserCountry(userCountry);
    setCoUserEmail(residentInfo.email);
    setIsResident(residentInfo.isResident);
    setcontractStartDate(residentInfo.contractStartDate);
    setcontractEndDate(residentInfo.contractEndDate);
  };

  const resetPrimaryResidentForm = () => {
    setResidentId(null);
    setIsResident(false);
    setResidentFirstLogin(false);
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setCountry(null);
    setEmail("");
    setcontractStartDate(null);
    setcontractEndDate(null);
    setMandatoriesChange(false);
    setExistUserInfo(false);
    setEmailErrorMessage("");
    setPhoneNumberErrorMessage("");
    setPhoneNumberFieldState(FieldState.Default);
    setEmailFieldState(FieldState.Default);
  };

  const resetCoResidentForm = () => {
    setCoResidentId(null);
    setIsResident(false);
    setCoResidentFirstLogin(false);
    setCoUserFirstName("");
    setCoUserLastName("");
    setCoUserPhoneNumber("");
    setCoUserCountry(null);
    setCoUserEmail("");
    setcontractStartDate(null);
    setcontractEndDate(null);
    setExistCoUserInfo(false);
    setMandatoriesChange(false);
    setCoUserEmailErrorMessage("");
    setCoUserPhoneNumberErrorMessage("");
    setcoUserPhoneNumberFieldState(FieldState.Default);
    setcoUserEmailFieldState(FieldState.Default);
  };

  const submitNewResident = async () => {
    setLoading(true);
    setProgressing(true);
    let phoneNumberValidation: boolean = true;
    let emailValidation: boolean = true;
    if (phoneNumber || email) {
      phoneNumberValidation = CheckPhoneNumberValidation(
        setPhoneNumberErrorMessage,
        setPhoneNumberFieldState,
        phoneNumber
      );
      emailValidation = CheckEmailValidation(
        setEmailErrorMessage,
        setEmailFieldState,
        email
      );
    }
    let coUserPhoneNumberValidation: boolean = true;
    let coUserEmailValidation: boolean = true;
    if (coUserPhoneNumber || coUserEmail) {
      coUserPhoneNumberValidation = CheckPhoneNumberValidation(
        setCoUserPhoneNumberErrorMessage,
        setcoUserPhoneNumberFieldState,
        coUserPhoneNumber
      );
      coUserEmailValidation = CheckEmailValidation(
        setCoUserEmailErrorMessage,
        setcoUserEmailFieldState,
        coUserEmail
      );
    }
    if (
      !phoneNumberValidation ||
      !coUserPhoneNumberValidation ||
      !emailValidation ||
      !coUserEmailValidation
    ) {
      setLoading(false);
      setProgressing(false);
      return;
    }
    let primaryResidentInfo: AddResidentViewModel | undefined;
    let coResidentInfo: AddResidentViewModel | undefined;

    if (phoneNumber && email) {
      primaryResidentInfo = {
        id: Number(residentId),
        countryId: country?.id || 0,
        email: email,
        phoneNumber: country?.code + phoneNumber,
        firstName: firstName,
        lastName: lastName,
        isPrimary: true,
        isResident: isResident,
        unitId: selectedUnit?.id || 0,
        userRole: residentType,
        contractStartDate: contractStartDate,
        contractEndDate: contractEndDate,
      };
    }
    if (coUserPhoneNumber && coUserEmail) {
      coResidentInfo = {
        id: Number(coResidentId),
        countryId: coUsercountry?.id || 0,
        email: coUserEmail,
        phoneNumber: coUsercountry?.code + coUserPhoneNumber,
        firstName: coUserFirstName,
        lastName: coUserlastName,
        isPrimary: false,
        isResident: isResident,
        unitId: selectedUnit?.id || 0,
        userRole: residentType,
        contractStartDate: contractStartDate,
        contractEndDate: contractEndDate,
      };
    }

    const createUnitResidentsRequestInfo: CreateUnitResidentsViewModel = {
      primaryResident: primaryResidentInfo,
      coResident: coResidentInfo,
    };

    const result: IServiceResult<boolean> = await CreateUnitResidentsService(
      createUnitResidentsRequestInfo
    );

    if (result.data) {
      dispatch(GetBuildingUsersAction(buildingId ?? ""));
      CreateToast(
        ToastType.Success,
        translate("ALERT.ADD_RESIDENT_SUCCESSFULLY", { value: residentType })
      );
      changeUnitNumber(null);
      setLoading(false);
      setProgressing(false);
    } else {
      CreateToast(
        ToastType.Error,
        translate("ERRORS.ADD_RESIDENT_FAILED", { value: residentType })
      );
      setLoading(false);
      setProgressing(false);
    }
  };

  useEffect(() => {
    if (mandatoriesChange) {
      dispatch(setHasUnsavedChanges(true));
    } else {
      dispatch(setHasUnsavedChanges(false));
    }
  }, [mandatoriesChange]);

  const cancelAddResidentForm = () => {
    if (mandatoriesChange) {
      setcancelAddResidentState(true);
    } else {
      navigate(-1);
    }
  };

  const closeCancelAddAdminPopUp = () => {
    setcancelAddResidentState(false);
  };

  const showDeleteResident = async () => {
    if (residentId) {
      setDeleteResidentPopUp(true);
    }
  };

  const showDeleteCoResident = async () => {
    if (coResidentId) {
      setDeleteCoResidentPopUp(true);
    }
  };

  const deleteResidentHandler = async (
    residentId: number | null,
    isPrimary: boolean
  ) => {
    if (residentId) {
      var _selectedUnit = selectedUnit;
      changeUnitNumber(null);
      setLoading(true);
      setProgressing(true);
      const deleteResult: IServiceResult<boolean> = await DeleteResidentService(
        residentId
      );
      if (deleteResult.data) {
        CreateToast(
          ToastType.Success,
          translate("ALERT.DELETE_RESIDENT_SUCCESSFULLY", {
            value: isPrimary
              ? residentType
              : translate("RESIDENT.CO_RESIDENT", { value: residentType }),
          })
        );
        changeUnitNumber(_selectedUnit);
        setLoading(false);
        setProgressing(false);
        setDeleteResidentPopUp(false);
        setDeleteCoResidentPopUp(false);
      } else {
        CreateToast(
          ToastType.Error,
          translate("ERRORS.DELETED_FAILD", {
            value: isPrimary
              ? residentType
              : translate("RESIDENT.CO_RESIDENT", { value: residentType }),
          })
        );
        setLoading(false);
        setProgressing(false);
      }
    }
  };
  // Use custom hook to lock body scroll
  useBodyScrollLock(deleteCoResidentPopUp || deleteResidentPopUp);
  return (
    <>
      <div className={Style.new_resident}>
        <div className={Style.new_resident_form}>
          <UnitInfo
            onChangeUnit={changeUnitNumber}
            selectedUnit={selectedUnit}
          />
          <Accordion
            callToActionNode={
              <RemoveResident
                className={residentId ? Style.active_delete_button : ""}
                onClock={showDeleteResident}
              />
            }
            title={translate("RESIDENT.RESIDENT_INFO", { value: residentType })}
            icon={<Profile />}
            children={<FirstLogin isFirstLogin={residentFirstLogin} />}
            content={
              <ResidentInfoForm
                phoneNumberErrorMessage={phoneNumberErrorMessage}
                phoneNumberFieldState={phoneNumberFieldState}
                setPhoneNumberFieldState={setPhoneNumberFieldState}
                emailFieldState={emailFieldState}
                setEmailFieldState={setEmailFieldState}
                setEmailErrorMessage={setEmailErrorMessage}
                setPhoneNumberErrorMessage={setPhoneNumberErrorMessage}
                emailErrorMessage={emailErrorMessage}
                userType={residentType}
                email={email}
                setEmail={setEmail}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                country={country}
                setCountry={setCountry}
                fieldReedOnly={existUserInfo}
                setFieldReedOnly={setExistUserInfo}
                setFirstLogin={setResidentFirstLogin}
              />
            }
          />
          <Accordion
            title={translate("RESIDENT.CO_RESIDENT_INFO", {
              value: residentType,
            })}
            icon={<Profile2User />}
            accordionIsOpen={false}
            callToActionNode={
              <RemoveResident
                className={coResidentId ? Style.active_delete_button : ""}
                onClock={showDeleteCoResident}
              />
            }
            children={<FirstLogin isFirstLogin={coResidentFirstLogin} />}
            content={
              <ResidentInfoForm
                setEmailErrorMessage={setCoUserEmailErrorMessage}
                setPhoneNumberErrorMessage={setCoUserPhoneNumberErrorMessage}
                emailFieldState={coUserEmailFieldState}
                setEmailFieldState={setcoUserEmailFieldState}
                phoneNumberFieldState={coUserPhoneNumberFieldState}
                setPhoneNumberFieldState={setcoUserPhoneNumberFieldState}
                phoneNumberErrorMessage={coUserPhoneNumberErrorMessage}
                emailErrorMessage={coUserEmailErrorMessage}
                userType={translate("RESIDENT.CO_RESIDENT", {
                  value: residentType,
                })}
                email={coUserEmail}
                setEmail={setCoUserEmail}
                phoneNumber={coUserPhoneNumber}
                setPhoneNumber={setCoUserPhoneNumber}
                firstName={coUserFirstName}
                setFirstName={setCoUserFirstName}
                lastName={coUserlastName}
                setLastName={setCoUserLastName}
                country={coUsercountry}
                setCountry={setCoUserCountry}
                fieldReedOnly={existCoUserInfo}
                setFieldReedOnly={setExistCoUserInfo}
                setFirstLogin={setCoResidentFirstLogin}
              />
            }
          />
          {residentType === ResidentType.Owner ? (
            <CheckBox
              className={Style.resident_check_box}
              isChecked={isResident}
              onChecked={setIsResident}
              checkBoxText={translate("RESIDENT.OWNER_IS_RESIDENT")}
            />
          ) : (
            <TenantContract>
              <Grid item md={6} sm={12} xs={12}>
                <FloorinsDatePicker
                  value={contractStartDate}
                  onChange={setcontractStartDate}
                  label={translate("LABELS.START_DATE")}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FloorinsDatePicker
                  value={contractEndDate}
                  onChange={setcontractEndDate}
                  label={translate("LABELS.END_DATE")}
                />
              </Grid>
            </TenantContract>
          )}
        </div>
        <div className={Style.new_resident_buttons}>
          <Button
            theme={ButtonTheme.primaryReverse}
            text={translate("BUTTON.BACK")}
            clickMethod={cancelAddResidentForm}
          />
          <Button
            text={translate("BUTTON.ADD")}
            clickMethod={submitNewResident}
            state={buttonState}
            theme={ButtonTheme.primary}
          />
        </div>
      </div>

      {loading && <Loading />}
      {cancelAddResidentState && (
        <CancelAddAdmin cancelButton={closeCancelAddAdminPopUp} />
      )}
      {deleteResidentPopUp && (
        <DeletePopUp
          title={translate("RESIDENT.DELETE_TYPE_OF_RESIDENT_TITR", {
            value: residentType,
          })}
          description={translate("ALERT.DELETE_ITEM_DESC", {
            value: residentType,
          })}
          setPopUpState={setDeleteResidentPopUp}
          deleteAction={() => deleteResidentHandler(residentId, true)}
          loading={progressing}
        />
      )}
      {deleteCoResidentPopUp && (
        <DeletePopUp
          title={translate("RESIDENT.DELETE_TYPE_OF_RESIDENT_TITR", {
            value: translate("RESIDENT.CO_RESIDENT", { value: residentType }),
          })}
          description={translate("ALERT.DELETE_ITEM_DESC", {
            value: translate("RESIDENT.CO_RESIDENT", { value: residentType }),
          })}
          setPopUpState={setDeleteCoResidentPopUp}
          deleteAction={() => deleteResidentHandler(coResidentId, false)}
          loading={progressing}
        />
      )}
    </>
  );
};

export default AddResidentForm;
