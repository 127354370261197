import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Map from "../../../../../tools/map/Map";
import TextField from "../../../../../tools/fields/textField/TextField";
import { DashboardBox } from "../../../../../tools/box/DashboardBox";
import { useTranslation } from "react-i18next";
import FileUpload from "../../../../../tools/fileUpload/FileUpload";
import AutoComplete from "../../../../../tools/dropDown/autoComplete/AutoComplete";
import { ICity } from "../../../../../viewModels/dataTypes/country/Country";
import {
  BuildingUsagesDropDownItems,
  GenerateBuildingUsagesList,
  UpdateBuildingInfoServices,
} from "./BuildingGeneralInfo.Service";
import CheckBox from "../../../../../tools/dropDown/checkBoxDropDown/CheckBox";
import Style from "./BuildingInfo.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { IServiceResult } from "../../../../../viewModels/api/Api";
import {
  GetCities,
  GetProvince,
} from "../../../../beforeDashboard/pricing/buildingInfo/BuildingInfo.Service";
import Button from "../../../../../tools/button/Button";
import { FileUploadService } from "../../../../../tools/fileUpload/File.Service";
import {
  ButtonState,
  ButtonTheme,
} from "../../../../../viewModels/enums/ButtonEnum";
import useWindowSize from "../../../../../hook/WindowSize";
import BuildingInfoStepper from "./buildingStepper/BuildingInfoStepper";
import DashboardTitle from "../../../../../tools/dashboardTitle/DashboardTitle";
import { Building } from "iconsax-react";
import { FilesUploadType } from "../../../../../viewModels/dataTypes/tools/FilesUploadType";
import {
  ConvertIDropDownToString,
  GetUserId,
} from "../../../../../services/commonServices/CommonServices";
import { GetUserBuildingDataAction } from "../../../../../redux/slices/UserBuildingSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../redux/Store";
import {
  IBuildingInfoReducer,
  IReducer,
} from "../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { GetBuildingInfoDataAction } from "../../../../../redux/slices/BuildingInfoSlice";
import { IFile } from "../../../../../viewModels/dataTypes/File";
import { IDropDown } from "../../../../../viewModels/dataTypes/tools/DropDownItems";
import { MediumDeviceTrigger } from "../../../../../Setting";
import Loading from "../../../../../tools/loading/Loading";
import { BuildingInfoViewModel } from "../../../../../viewModels/api/Models";
import { setHasUnsavedChanges } from "../../../../../redux/slices/UnsavedChangesSlice";
import { CancelAddAdmin } from "../../users/addUser/addAdminForm/AddAdminPopUps";

const BuildingGeneralInfo = () => {
  //states
  const { buildingId } = useParams();
  const navigate = useNavigate();
  const [buildingUsage, setBuildingUsage] = useState<IDropDown[] | null>(null);
  const [buildingName, setBuildingName] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [cities, setCities] = useState<ICity[]>([]);
  const [selectedCity, setSelectedCity] = useState<ICity | null>(null);
  const [provinces, setProvinces] = useState<ICity[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<ICity | null>(null);
  const [address, setAddress] = useState<string>("");
  const [fileUpload, setFileUpload] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileUploadId, setFileUploadId] = useState<number | null>(null);
  const [buttonState, setButtonState] = useState(ButtonState.default);
  const [loading, setLoading] = useState<boolean>(false);
  const [mandatoriesChange, setMandatoriesChange] = useState<boolean>(false);
  const [cancelAddBuildingState, setcancelAddBuildingState] =
    useState<boolean>(false);

  const size = useWindowSize();

  const userBuildingInfo: IBuildingInfoReducer = useSelector(
    (state: IReducer) => state.buildingInfo
  );

  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    const isAnyFieldFilled = Boolean(
      buildingName ||
        buildingUsage ||
        fileName ||
        selectedProvince ||
        selectedCity ||
        zipCode
    );

    setMandatoriesChange(isAnyFieldFilled);
  }, [
    buildingName,
    buildingUsage,
    fileName,
    selectedProvince,
    selectedCity,
    zipCode,
  ]);

  useEffect(() => {
    setMandatoriesChange(false);
  }, [loading]);

  useEffect(() => {
    if (mandatoriesChange) {
      dispatch(setHasUnsavedChanges(true));
    } else {
      dispatch(setHasUnsavedChanges(false));
    }
  }, [mandatoriesChange]);

  const cancelAddBuildingForm = () => {
    if (mandatoriesChange) {
      setcancelAddBuildingState(true);
    } else {
      navigate(-1);
    }
  };

  const closeCancelAddAdminPopUp = () => {
    setcancelAddBuildingState(false);
  };

  useEffect(() => {
    const getBuildingInfo = async () => {
      setLoading(true);
      if (userBuildingInfo.buildingInfo) {
        const provinces = await GetProvince(
          userBuildingInfo.buildingInfo?.countryId ?? 0
        );

        setProvinces(provinces ?? []);
        getCitiesOfProvince(userBuildingInfo.buildingInfo.province);
        setBuildingUsage(
          GenerateBuildingUsagesList(
            userBuildingInfo.buildingInfo.buildingUsages
          )
        );
        setBuildingName(userBuildingInfo.buildingInfo.buildingName);
        setAddress(userBuildingInfo.buildingInfo.address);

        setZipCode(userBuildingInfo.buildingInfo.zipCode);
        setFileUploadId(userBuildingInfo.buildingInfo.fileUploadId);
        setFileName(userBuildingInfo.buildingInfo.fileName);
        const buildingCity: ICity = {
          id: userBuildingInfo.buildingInfo.cityId,
          name: userBuildingInfo.buildingInfo.cityName,
          province: userBuildingInfo.buildingInfo.province,
        };
        setSelectedProvince(buildingCity);
        setSelectedCity(buildingCity);
        setLoading(false);
      } else {
        dispatch(GetBuildingInfoDataAction(buildingId ?? ""));
        setLoading(false);
      }
    };
    getBuildingInfo();
  }, [userBuildingInfo]);

  const getCitiesOfProvince = async (province: string) => {
    const cities = await GetCities(province);
    setCities(cities ?? []);
  };

  const onChnageProvince = (province: ICity | null) => {
    setCities([]);
    setSelectedCity(null);
    setSelectedProvince(province);
    getCitiesOfProvince(province?.province ?? "");
    setButtonState(ButtonState.disable);
  };

  const onChangeCity = (city: ICity | null) => {
    setSelectedCity(city);
    if (city != null) {
      setButtonState(ButtonState.default);
    } else {
      setButtonState(ButtonState.disable);
    }
  };

  const checkUploadFile = async () => {
    if (fileUpload != null) {
      const fileUploadResult: IServiceResult<IFile> = await FileUploadService(
        fileUpload
      );
      setFileUploadId(fileUploadResult.data?.id ?? null);
      return fileUploadResult.data?.id;
    } else if (fileUploadId != null) {
      return fileUploadId;
    } else {
      return null;
    }
  };

  const deleteFile = () => {
    setFileUploadId(null);
    setFileName(null);
    setFileUpload(null);
  };

  const updateBuildingInfo = async () => {
    var fileId = await checkUploadFile();
    const updatedInfo: BuildingInfoViewModel = {
      id: Number(buildingId),
      address: address,
      buildingName: buildingName,
      buildingUsages: ConvertIDropDownToString(buildingUsage),
      cityId: selectedCity?.id ?? 1,
      fileUploadId: fileId ?? null,
      zipCode: zipCode,
    };

    const updateResult: IServiceResult<boolean> =
      await UpdateBuildingInfoServices(updatedInfo);

    if (updateResult.data != null) {
      var userId: string | undefined = GetUserId();
      dispatch(GetUserBuildingDataAction(userId ?? ""));
      navigate(`/dashboard/building/buildingMap/${buildingId}`);
    }
  };

  const changeBuildingName = (value: string) => {
    if (value.length === 0) {
      setButtonState(ButtonState.disable);
    } else {
      setButtonState(ButtonState.default);
    }
    setBuildingName(value);
  };

  const { t: translate } = useTranslation();

  return (
    <DashboardBox>
      <DashboardTitle
        className={Style.building_info_title}
        icon={<Building size="22" color="var(--icon-gray-high)" />}
        label={`${buildingName} ${translate("BUILDING.BUILDING_INFO")}`}
      />
      <BuildingInfoStepper activeItem={0} />
      <div className={Style.building_info}>
        <div className={Style.building_info_items}>
          <Grid
            className={Style.building_info_box}
            container
            columnSpacing={size.width > MediumDeviceTrigger ? 19 : 6}
          >
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                value={buildingName}
                onChange={changeBuildingName}
                label={translate("LABELS.BUILDING_NAME")}
                placeHolder={translate("PLACEHOLDER.BUILDING_NAME")}
                requiredField
              />
              <CheckBox
                placeHolder={translate("LABELS.BUILDING_USAGE")}
                values={buildingUsage}
                label={translate("LABELS.BUILDING_USAGE")}
                onChange={setBuildingUsage}
                data={BuildingUsagesDropDownItems}
              />
              <FileUpload
                fileType={FilesUploadType.image}
                onDelete={deleteFile}
                fileName={fileName}
                label={translate("LABELS.UPLOAD_LOGO")}
                onchange={setFileUpload}
                fileDimensions={640}
              />
              <Grid
                container
                spacing={size.width > MediumDeviceTrigger ? 4 : 1}
              >
                <Grid item xs={6}>
                  <AutoComplete
                    requiredField
                    items={provinces}
                    label={translate("LABELS.PROVINCE")}
                    placeHolder={translate("PLACEHOLDER.PROVINCE")}
                    getOptionLabel={(provinces: ICity) => provinces.province}
                    getRenderOption={(provinces: ICity) => provinces.province}
                    onChange={onChnageProvince}
                    value={selectedProvince}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AutoComplete
                    requiredField
                    items={cities}
                    label={translate("LABELS.CITY")}
                    placeHolder={translate("PLACEHOLDER.CITY")}
                    getOptionLabel={(cities: ICity) => cities.name}
                    getRenderOption={(cities: ICity) => cities.name}
                    onChange={onChangeCity}
                    value={selectedCity}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                label={translate("LABELS.ZIP_CODE")}
                placeHolder={translate("PLACEHOLDER.ZIP_CODE")}
                onChange={setZipCode}
                value={zipCode}
              />
              <Map pinedAddress={address} onChangeAddress={setAddress} />
            </Grid>
          </Grid>
        </div>
        {cancelAddBuildingState && (
          <CancelAddAdmin cancelButton={closeCancelAddAdminPopUp} />
        )}
        <div className={Style.buttons}>
          <Button
            theme={ButtonTheme.primaryReverse}
            text={translate("BUTTON.BACK")}
            clickMethod={cancelAddBuildingForm}
          />
          <Button
            text={translate("BUTTON.NEXT")}
            clickMethod={updateBuildingInfo}
            state={buttonState}
          />
        </div>
      </div>
      {loading && <Loading />}
    </DashboardBox>
  );
};

export default BuildingGeneralInfo;
