import { Menu, MenuItem } from "@mui/material";
import React, { ReactNode } from "react";
import Button from "../../button/Button";
import { SizeType } from "../../../viewModels/dataTypes/tools/ListComponentType";
import { NavLink } from "react-router-dom";
import { IButtonMenuItems } from "../../../viewModels/dataTypes/tools/DropDownItems";
import Style from "./ButtonMenu.module.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
  icon?: ReactNode;
  text: string;
  size: SizeType;
  items: IButtonMenuItems[];
}

const ButtonMenu: React.FC<Props> = ({ icon, text, size, items }) => {
  const [menuElement, setMenuElement] = React.useState<null | HTMLElement>(
    null
  );
  const open = Boolean(menuElement);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setMenuElement(event.currentTarget);
  };
  const handleClose = () => {
    setMenuElement(null);
  };
  return (
    <div>
      <Button
        size={size}
        icon={icon}
        text={
          <div className={Style.menu_button_icon}>
            <span>{text}</span>

            <KeyboardArrowDownIcon className={Style.menu_arrow_down} />
          </div>
        }
        clickMethod={handleClick}
      />

      <Menu
        className={Style.button_menu_list}
        anchorEl={menuElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items.map((item: IButtonMenuItems, index: number) => (
          <MenuItem key={index}>
            <NavLink to={item.link}>{item.value}</NavLink>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ButtonMenu;
