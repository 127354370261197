import { Box, CircularProgress } from "@mui/material";
import React from "react";
import Style from "./LoaderScreen.module.scss";

const LoaderScreen = () => {
  return (
    <Box className={Style.loader_screen}>
      <CircularProgress />
    </Box>
  );
};

export default LoaderScreen;
