import { ApiResponse } from "../../viewModels/api/Api";
import { CallApi } from "../CallApi";
import {
  BaseUrl,
  ChangePasswordRequest,
  CheckPasswordRequest,
  ForgetPasswordViaEmailRequest,
  ForgetPasswordViaPhoneRequest,
} from "../../text/Api";
import { ResponseCode } from "../../viewModels/enums/ResponseCode";
import { UserTokenModel } from "../../viewModels/dataTypes/registration/TokenViewModel";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CheckEmailOrPhoneNumberResponse } from "../../viewModels/dataTypes/registration/SingUp";
import { ChangePasswordRequestViewModel, TwoFactorAuthViewModel } from "../../viewModels/api/Models";
import { DefaultApiHeader } from "./ApiHeaders";



export const ForgetPasswordViaEmail = async (reveiver: string) => {
  const Url: string = BaseUrl + ForgetPasswordViaEmailRequest + reveiver;
  var raw = JSON.stringify({});
  var Result: ApiResponse<CheckEmailOrPhoneNumberResponse> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const ForgetPasswordViaPhone = async (reveiver: string) => {
  const Url: string = BaseUrl + ForgetPasswordViaPhoneRequest + reveiver;
  var raw = JSON.stringify({});
  var Result: ApiResponse<CheckEmailOrPhoneNumberResponse> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const ChangePasswordAction = async (userInfo : ChangePasswordRequestViewModel) => {
  try {
    const Url: string = BaseUrl + ChangePasswordRequest;
    var raw = JSON.stringify(userInfo);

    var Result: ApiResponse<boolean> = await CallApi(
      Url,
      raw,
      DefaultApiHeader,
      ApiMethod.Post
    );
    return Result;
  } catch (error) {
    var Result: ApiResponse<boolean> = {
      data: null,
      responseStatus: ResponseCode.Unauthorized,
    };
    return Result;
  }
};

export const CheckUserPassword = async (
  userInfo : TwoFactorAuthViewModel
) => {
  const Url: string = BaseUrl + CheckPasswordRequest;
  var raw = JSON.stringify(userInfo);

  var Result: ApiResponse<UserTokenModel> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Post
  );

  return Result;
};
