import React, { useEffect, useState } from "react";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../dashboardBody/DashboardBody";
import {
  IBuildingComplaintsReducer,
  IReducer,
} from "../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../redux/Store";
import { GetBuildingComplaintsAction } from "../../../../redux/slices/ComplaintsSlice";
import { useParams } from "react-router-dom";
import ComplaintsList from "./complaintsList/ComplaintsList";
import Loading from "../../../../tools/loading/Loading";

const Complaints = () => {
  const { buildingId } = useParams();

  const buildingComplaintsInfo: IBuildingComplaintsReducer = useSelector(
    (state: IReducer) => state.complaintsList
  );

  const dispatch = useDispatch<AppThunkDispatch>();
  const [preload, setPreload] = useState<boolean>(true);

  useEffect(() => {
    const getBuildingUsers = async () => {
      if (buildingComplaintsInfo.fill) {
        setPreload(false);
      } else {
        dispatch(GetBuildingComplaintsAction(buildingId ?? ""));
      }
    };
    getBuildingUsers();
  }, [buildingComplaintsInfo]);

  return (
    <DashboardContainer>
      <DashboardBody>
        {!preload ? (
          <ComplaintsList
            complaints={buildingComplaintsInfo.complaints || []}
          />
        ) : (
          <Loading />
        )}
      </DashboardBody>
    </DashboardContainer>
  );
};

export default Complaints;
