import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/Store";
import LoaderScreen from "./tools/loaderScreen/LoaderScreen";
// Initialize i18next with configuration
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init(
    {
      supportedLngs: ["en", "de"],
      lng: document.querySelector("html")?.lang || "en",
      fallbackLng: "en",
      detection: {
        order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
        caches: ["cookie"],
      },
      backend: {
        loadPath: "/locales/{{lng}}/translation.json",
      },
      react: { useSuspense: false },
    }
  );

const Main = () => {
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    const waitForI18nAndFonts = async () => {
      // Wait for i18n to initialize
      if (!i18n.isInitialized) {
        await new Promise<void>((resolve) => {
          i18n.on("initialized", () => resolve());
        });
      }

      // Load each font explicitly
      const fontPromises: Promise<FontFace[]>[] = [
        document.fonts.load('1rem "Poppins-Light"'),
        document.fonts.load('1rem "Poppins-Medium"'),
        document.fonts.load('1rem "Poppins-Regular"'),
        document.fonts.load('1rem "Poppins-SemiBold"'),
      ];

      await Promise.all(fontPromises);

      // After both i18n and fonts are ready, set isReady to true
      setIsReady(true);
    };

    waitForI18nAndFonts();
  }, []);

  if (!isReady) {
    return <LoaderScreen />; // Show loading screen until translations and fonts are ready
  }

  return (
    <div>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </div>
  );
};





const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<Main />);
