import styled from "styled-components";
import "../../../../../assets/style/Font.scss";
import { PricingCallToActionType } from "../../../../../viewModels/dataTypes/tools/PricingCallToActionType";
interface Props {
  planColor: string;
  select: boolean;
  callToAction: PricingCallToActionType;
}

export const PlanItemStyled = styled.div<Props>`
  padding: 0.75rem 1rem;
  background-color: var(--bg-secondary);
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: var(--carve);
  h3,
  span,
  svg {
    color: ${(a: Props) => a.planColor};
  }
  border: 1px solid
    ${(a: Props) => (a.select ? a.planColor : "var(--border-white)")};
  cursor: ${(a: Props) =>
    a.select && a.callToAction == PricingCallToActionType.Button
      ? "pointer"
      : "auto"};
  box-shadow: ${(a: Props) =>
    a.select && a.callToAction == PricingCallToActionType.Button
      ? "0 5px 12px 0 rgba(0, 0, 0, 0.16)"
      : "0 5px 16px 0 rgba(0, 0, 0, 0.1)"};

  a {
    background-color: ${(a: Props) =>
      a.select &&
      a.callToAction == PricingCallToActionType.Button &&
      `var(--bg-primary-high) !important`};
    color: ${(a: Props) =>
      a.select &&
      a.callToAction == PricingCallToActionType.Button &&
      `var(--text-secondary) !important`};
  }
`;
