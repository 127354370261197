export interface ComplaintDetailsState {
  complaintNumber: string;
  complaintDate: string;
  lastUpdate: string;
}

export enum ComplaintDetailsActionType {
  ComplaintNumber = "SET_COMPLAINT_NUMBER",
  ComplaintDate = "SET_COMPLAINT_DATE",
  LastUpdate = "SET_LAST_UPDATE",
}

export type ComplaintDetailsAction =
  | { type: ComplaintDetailsActionType.ComplaintNumber; payload: string }
  | { type: ComplaintDetailsActionType.ComplaintDate; payload: string }
  | { type: ComplaintDetailsActionType.LastUpdate; payload: string };

export const InitialComplaintDetailsState: ComplaintDetailsState = {
  complaintNumber: "",
  complaintDate: "",
  lastUpdate: "",
};

export const ComplaintDetailsReducer = (
  state: ComplaintDetailsState,
  action: ComplaintDetailsAction
): ComplaintDetailsState => {
  switch (action.type) {
    case ComplaintDetailsActionType.ComplaintNumber:
      return { ...state, complaintNumber: action.payload };
    case ComplaintDetailsActionType.ComplaintDate:
      return { ...state, complaintDate: action.payload };
    case ComplaintDetailsActionType.LastUpdate:
      return { ...state, lastUpdate: action.payload };
    default:
      return state;
  }
};
