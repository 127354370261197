import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Filter from "../../../../../tools/table/filter/Filter";
import { FilterConditionsEnum } from "../../../../../viewModels/enums/FilterConditionsEnum";
import FilterDropDown from "../../../../../tools/dropDown/filterDropDown/FilterDropDown";
import Loading from "../../../../../tools/loading/Loading";
import CustomizedTables from "../../../../../tools/table/Table";
import { useTranslation } from "react-i18next";
import { IDropDown } from "../../../../../viewModels/dataTypes/tools/DropDownItems";
import { useDispatch, useSelector } from "react-redux";
import { DataTableItemType } from "../../../../../viewModels/dataTypes/DataTableType";
import { AppThunkDispatch } from "../../../../../redux/Store";
import Style from "./ComplaintsList.module.scss";
import { ComplaintState } from "../../../../../viewModels/api/Models";
import {
  IBuildingInfoReducer,
  IReducer,
} from "../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { ComplaintsViewModel } from "../../../../../viewModels/dataTypes/complaints/ComplaintsViewModel";
import DashboardTitle from "../../../../../tools/dashboardTitle/DashboardTitle";
import { DashboardBox } from "../../../../../tools/box/DashboardBox";
import { GetBuildingInfoDataAction } from "../../../../../redux/slices/BuildingInfoSlice";
import { MenuBoard } from "iconsax-react";
import FilterDropDownDate from "../../../../../tools/dropDown/filterDropDownDate/FilterDropDownDate";
import FilterDropDownSelect from "../../../../../tools/dropDown/filterDropDownSelect/FilterDropDownSelect";
import { Dayjs } from "dayjs";
import useWindowSize from "../../../../../hook/WindowSize";
import {
  FormatDateDefault,
  MediumDeviceTrigger,
  UltraSmallDeviceTrigger,
} from "../../../../../Setting";
import { DateItems } from "../../../../../tools/dropDown/filterDropDownDate/DateItems";
import { CalenderEnum } from "../../../../../viewModels/enums/CalenderEnum";
import { DateRangeType } from "../../../../../viewModels/dataTypes/DateRangeType";
import { headCells } from "./ComplaintsListMockData";
import { FilterComplaints } from "./ComplaintsList.service";

interface Props {
  complaints: ComplaintsViewModel[];
}
const ServiceComplaints: React.FC<Props> = ({ complaints }) => {
  const windowsSize = useWindowSize();
  const { buildingId } = useParams<{
    buildingId: string;
  }>();
  const [complaintFilterList, setComplaintFilterList] = useState<
    (ComplaintsViewModel & DataTableItemType)[]
  >([]);
  const [selectedDateRange, setSelectedDateRange] =
    useState<DateRangeType | null>(null);
  const [preload, setPreload] = useState<boolean>(true);
  const [isActiveCloseIcon, setIsActiveCloseIcon] = useState<boolean>(false);
  const [checkedItemsStates, setCheckedItemsStates] = useState<string[]>([]);
  const [checkedItemsSubjects, setCheckedItemsSubjects] = useState<string[]>(
    []
  );
  const [buildingName, setBuildingName] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [complaintsList, setComplaintsList] = useState<string | null>(null);
  const [states, setStates] = useState<string | null>(null);
  const [stateDropDown, setStateDropDown] = useState<IDropDown[]>([]);
  const [subjectsDropDown, setSubjectsDropDown] = useState<IDropDown[]>([]);
  const [selectedMode, setSelectedMode] = useState<string>("");
  const isMediumToUltraSmallDevice: boolean =
    windowsSize.width <= MediumDeviceTrigger &&
    windowsSize.width > UltraSmallDeviceTrigger;

  const [stateFilterLogic, setStateFilterLogic] = useState<string>(
    FilterConditionsEnum.OR
  );
  const [complaintsFilterLogic, setComplaintsFilterLogic] = useState<string>(
    FilterConditionsEnum.OR
  );
  const userBuildingInfo: IBuildingInfoReducer = useSelector(
    (state: IReducer) => state.buildingInfo
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t } = useTranslation();
  const subjects = useSelector((state: IReducer) => state.complaintsList);

  useEffect(() => {
    const getBuildingInfo = async () => {
      if (userBuildingInfo.buildingInfo != null) {
        setBuildingName(userBuildingInfo.buildingInfo.buildingName);
      } else {
        await dispatch(GetBuildingInfoDataAction(buildingId ?? ""));
      }
    };
    getBuildingInfo();
  }, [userBuildingInfo, dispatch, buildingId]);

  const resetFilters = () => {
    setSearchText("");
    setComplaintsList(null);
    setStates(null);
    setCheckedItemsStates([]);
    setCheckedItemsSubjects([]);
    setSelectedMode("");
    if (
      (checkedItemsStates.length > 0 && checkedItemsSubjects.length > 0) ||
      searchText.length > 0
    ) {
      setComplaintsFilterLogic(FilterConditionsEnum.OR);
      setStateFilterLogic(FilterConditionsEnum.OR);
    }
  };

  const getRequestStateList = (): string[] => {
    return Object.keys(ComplaintState)
      .filter((key) => isNaN(Number(key)))
      .map((key: string) => key);
  };

  useEffect(() => {
    setComplaintFilterList(complaints);

    const stateDropDownList: IDropDown[] = getRequestStateList().map(
      (state: string, index: number) => ({
        value: index.toString() ?? "",
        text: state ?? "",
      })
    );
    setStateDropDown(stateDropDownList);
    setPreload(false);
  }, [complaints]);

  useEffect(() => {
    setIsActiveCloseIcon(
      checkedItemsStates.length > 0 ||
        checkedItemsSubjects.length > 0 ||
        searchText.length > 0 ||
        selectedMode !== ""
    );
  }, [
    checkedItemsStates.length,
    checkedItemsSubjects.length,
    searchText.length,
    selectedMode,
  ]);

  useEffect(() => {
    const dataFilter = FilterComplaints(
      complaints,
      complaintsList ?? "",
      complaintsFilterLogic ?? "",
      searchText,
      states ?? "",
      stateFilterLogic ?? "",
      selectedDateRange,
      selectedMode
    );

    setComplaintFilterList(dataFilter);
  }, [
    complaints,
    complaintsList,
    complaintsFilterLogic,
    searchText,
    states,
    stateFilterLogic,
    selectedDateRange,
    selectedMode,
  ]);
  // Handle date change
  const handleDateChange = (startDate: Dayjs, endDate: Dayjs) => {
    setSelectedDateRange({
      start: startDate.format(FormatDateDefault),
      end: endDate.format(FormatDateDefault),
    });
  };

  useEffect(() => {
    let _subjects: IDropDown[] = [];
    const uniqueSubjects = new Set<string>();
    subjects?.complaints?.forEach((item: ComplaintsViewModel) => {
      const subject = item.subject ?? "";
      if (!uniqueSubjects.has(subject)) {
        uniqueSubjects.add(subject);
        _subjects.push({
          text: subject,
          value: subject,
        });
      }
    });

    setSubjectsDropDown(_subjects);
  }, [subjects]);

  return (
    <DashboardBox>
      <DashboardTitle
        icon={<MenuBoard className={Style.user_icon} />}
        label={`${buildingName} ${t("COMPLAINTS.COMPLAINT")}`}
      />
      <Filter
        onSearch={setSearchText}
        onClose={resetFilters}
        valueSearch={searchText}
        isActiveCloseIcon={isActiveCloseIcon}
        filterItemsDate={
          <>
            {windowsSize.width >= UltraSmallDeviceTrigger && (
              <div className={Style.container_date_desktop_filter}>
                {isMediumToUltraSmallDevice ? (
                  selectedMode && (
                    <FilterDropDownSelect
                      items={DateItems}
                      label={t("COMPLAINTS.REPORTS_TIME")}
                      onModeChange={setSelectedMode}
                      value={selectedMode}
                    />
                  )
                ) : (
                  <FilterDropDownSelect
                    items={DateItems}
                    label={t("COMPLAINTS.REPORTS_TIME")}
                    onModeChange={setSelectedMode}
                    value={selectedMode}
                  />
                )}
                {selectedMode && (
                  <FilterDropDownDate
                    className={Style.complaints_list_dropdown}
                    mode={selectedMode}
                    onDateChange={(start: Dayjs, end: Dayjs) =>
                      handleDateChange(start, end)
                    }
                  />
                )}
              </div>
            )}
          </>
        }
        filterItems={
          <>
            {windowsSize.width < MediumDeviceTrigger && (
              <>
                <p
                  className={`${Style.complaint_mobile_report} ${
                    selectedMode !== "" ? Style.active : ""
                  }`}
                  onClick={() =>
                    setSelectedMode(selectedMode !== "" ? "" : CalenderEnum.DAY)
                  }
                >
                  {t("COMPLAINTS.REPORTS_TIME")}
                </p>
              </>
            )}
            <FilterDropDown
              className={Style.complaints_list_dropdown}
              onChange={setComplaintsList}
              onChangeConditionsLogic={setComplaintsFilterLogic}
              valueConditionsLogic={complaintsFilterLogic}
              items={subjectsDropDown}
              label={t("COMPLAINTS.SUBJECTS")}
              checkedItems={checkedItemsSubjects}
              setCheckedItems={setCheckedItemsSubjects}
              showRadioButton={true}
            />

            <FilterDropDown
              className={Style.complaints_list_dropdown}
              onChange={setStates}
              onChangeConditionsLogic={setStateFilterLogic}
              valueConditionsLogic={stateFilterLogic}
              items={stateDropDown}
              label={t("COMPLAINTS.STATE")}
              checkedItems={checkedItemsStates}
              setCheckedItems={setCheckedItemsStates}
              showRadioButton={false}
            />
          </>
        }
      />
      {selectedMode && windowsSize.width < UltraSmallDeviceTrigger && (
        <div className={`${Style.container_filter_Date}`}>
          <FilterDropDownSelect
            items={DateItems}
            label={t("COMPLAINTS.REPORTS_TIME")}
            onModeChange={setSelectedMode}
            value={selectedMode}
          />
          {selectedMode && (
            <FilterDropDownDate
              className={Style.complaints_list_dropdown}
              mode={selectedMode}
              onDateChange={(start: Dayjs, end: Dayjs) =>
                handleDateChange(start, end)
              }
            />
          )}
        </div>
      )}
      {!preload ? (
        <CustomizedTables
          className={Style.complaints_list}
          path={`/dashboard/building/complaints/${buildingId}/`}
          tableData={complaintFilterList}
          tableHeaderItems={headCells}
          notFoundMessage={t("ALERT.NOT_FOUND_COMPLAINTS")}
        />
      ) : (
        <Loading />
      )}
    </DashboardBox>
  );
};

export default ServiceComplaints;
