import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CheckPhoenValidation,
  EmailRegex,
} from "../../../../../../../services/Regex";
import {
  FieldStopTypingTime,
  MediumDeviceTrigger,
} from "../../../../../../../Setting";
import useWindowSize from "../../../../../../../hook/WindowSize";
import TextField from "../../../../../../../tools/fields/textField/TextField";
import PhoneNumberValidation from "../../../../../../../tools/fields/phoneNumberField/PhoneNumberField";
import { useTranslation } from "react-i18next";
import { FieldState } from "../../../../../../../viewModels/enums/PublicEnum";
import { CountryType } from "../../../../../../../viewModels/dataTypes/country/Country";
import Loading from "../../../../../../../tools/loading/Loading";
import { GetUserInfoService } from "../../../../../../beforeDashboard/login/confirmationUserInfo/ConfirmationUserInfo.Service";
import { IServiceResult } from "../../../../../../../viewModels/api/Api";
import { IUser } from "../../../../../../../viewModels/dataTypes/user/User";
import Style from "./ResidentInfoForm.module.scss";

interface Props {
  email: string;
  setEmail: (value: string) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  firstName: string;
  setFirstName: (value: string) => void;
  lastName: string;
  setLastName: (value: string) => void;
  country: CountryType | null;
  setCountry: (value: CountryType | null) => void;
  setFirstLogin: (value: boolean) => void;
  fieldReedOnly?: boolean;
  setFieldReedOnly: (value: boolean) => void;
  phoneNumberErrorMessage: string;
  setPhoneNumberErrorMessage: (message: string) => void;
  phoneNumberFieldState: FieldState;
  setPhoneNumberFieldState: (fieldState: FieldState) => void;
  emailErrorMessage: string;
  setEmailErrorMessage: (message: string) => void;
  emailFieldState: FieldState;
  setEmailFieldState: (fieldState: FieldState) => void;
  userType: string;
}
const ResidentInfoForm: React.FC<Props> = ({
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  country,
  setCountry,
  setFirstLogin,
  fieldReedOnly = false,
  setFieldReedOnly,
  userType,
  phoneNumberErrorMessage = "",
  setPhoneNumberErrorMessage,
  phoneNumberFieldState = FieldState.Default,
  setPhoneNumberFieldState,
  emailErrorMessage = "",
  setEmailErrorMessage,
  emailFieldState = FieldState.Default,
  setEmailFieldState,
}) => {
  const windowSize = useWindowSize();
  const { t: translate } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(checkPhoneNumber, FieldStopTypingTime); // Adjust the timeout value as needed

    return () => clearTimeout(timeout);
  }, [phoneNumber, country]);

  useEffect(() => {
    const timeout = setTimeout(checkEmailAddress, FieldStopTypingTime); // Adjust the timeout value as needed
    return () => clearTimeout(timeout);
  }, [email]);

  //Functions

  const checkEmailAddress = async () => {
    if (!fieldReedOnly && EmailRegex(email)) {
      setLoading(true);
      let userInfo: IServiceResult<IUser> = await GetUserInfoService(email);
      if (userInfo.data) {
        fillExistUserInfo(userInfo.data);
        setFieldReedOnly(true);
      }
      setLoading(false);
    }
  };

  const checkPhoneNumber = async () => {
    if (!fieldReedOnly && CheckPhoenValidation(phoneNumber)) {
      setLoading(true);
      let userInfo: IServiceResult<IUser> = await GetUserInfoService(
        country?.code + phoneNumber
      );
      if (userInfo.data) {
        fillExistUserInfo(userInfo.data);
        setFieldReedOnly(true);
      }
      setLoading(false);
    }
  };

  const onChangePhoneNumber = (
    value: string,
    countryValue?: CountryType | null
  ) => {
    setPhoneNumber(value);
    setPhoneNumberErrorMessage("");
    setPhoneNumberFieldState(FieldState.Default);
    if (countryValue != null) {
      setCountry(countryValue);
    }
  };

  const onChangeEmail = (value: string) => {
    setEmail(value);
    setEmailErrorMessage("");
    setEmailFieldState(FieldState.Default);
  };

  const fillExistUserInfo = (userInfo: IUser) => {
    setFirstName(userInfo.firstName);
    setLastName(userInfo.lastName);
    setPhoneNumber(userInfo.phoneNumber.replace(userInfo.countryCode, ""));
    var userCountry: CountryType = {
      code: userInfo.countryCode,
      name: userInfo.countryName,
      symbol: userInfo.countryAbbreviation,
      id: userInfo.countryId,
    };
    setCountry(userCountry);
    setFirstLogin(userInfo.isConfirmed);
    setEmail(userInfo.email);
    setPhoneNumberErrorMessage("");
    setPhoneNumberFieldState(FieldState.Default);
    setEmailErrorMessage("");
    setEmailFieldState(FieldState.Default);
  };

  return (
    <Grid
      className={Style.resident_info_container}
      container
      columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
    >
      <Grid item md={6} sm={12} xs={12}>
        <TextField
          value={email}
          errorMessage={translate(emailErrorMessage)}
          fieldState={emailFieldState}
          onChange={onChangeEmail}
          label={translate("LABELS.EMAIL")}
          placeHolder={translate("PLACEHOLDER.USERTYPE_EMAIL", {
            userType: userType.toLocaleLowerCase(),
          })}
          condotion={EmailRegex}
          requiredField
          readOnly={fieldReedOnly}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <PhoneNumberValidation
          errorMessage={translate(phoneNumberErrorMessage)}
          value={phoneNumber}
          onChange={onChangePhoneNumber}
          label={translate("LABELS.PHONENUMBER")}
          placeHolder={translate("PLACEHOLDER.USERTYPE_PHONE_NUMBER", {
            userType: userType.toLocaleLowerCase(),
          })}
          fieldState={phoneNumberFieldState}
          defaultCountry={country?.symbol}
          readOnly={fieldReedOnly}
          requiredField
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <TextField
          errorMessage={""}
          value={firstName}
          onChange={setFirstName}
          label={translate("LABELS.FIRSTNAME")}
          placeHolder={translate("PLACEHOLDER.USERTYPE_FIRSTNAME", {
            userType: userType.toLocaleLowerCase(),
          })}
          readOnly={fieldReedOnly}
        />
      </Grid>

      <Grid item md={6} sm={12} xs={12}>
        <TextField
          value={lastName}
          onChange={setLastName}
          label={translate("LABELS.LASTNAME")}
          placeHolder={translate("PLACEHOLDER.USERTYPE_LASTNAME", {
            userType: userType.toLocaleLowerCase(),
          })}
          readOnly={fieldReedOnly}
        />
      </Grid>
      {loading && <Loading />}
    </Grid>
  );
};

export default ResidentInfoForm;
