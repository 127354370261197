import React, { useEffect, useState } from "react";
import { SizeType } from "../../viewModels/dataTypes/tools/ListComponentType";
import Style from "./TimePickerDropDown.module.scss";
import DropDown from "../dropDown/DropDown";
import { IDropDown } from "../../viewModels/dataTypes/tools/DropDownItems";
import { GenerateDigitalTimes } from "./TimePickerDropDown.Service";
import dayjs from "dayjs";
import { DefaultMinTime } from "../../Setting";

interface Props {
  size?: SizeType;
  label: string;
  onChange: (date: string) => void;
  value: string | null;
  minTime?: string;
}

const TimePickerDropDown: React.FC<Props> = ({
  size = SizeType.large,
  label,
  onChange,
  value,
  minTime = DefaultMinTime,
}) => {
  const [selectedTime, setSelectedTime] = useState<IDropDown | null>(null);
  const [digitalTimes, setdigitalTimes] = useState<IDropDown[]>([]);

  //const allTimes = GenerateDigitalTimes();
  const changeHandler = (value: IDropDown | null) => {
    setSelectedTime(value ?? digitalTimes[0]);
    onChange(value?.value ?? digitalTimes[0].value);
  };
  const loadTimes = () => {
    const times: IDropDown[] = GenerateDigitalTimes();

    // Combine the time with a reference date to ensure proper parsing
    const mindateTime = new Date(`2024-01-01 ${minTime}`);
    const dayjsMinDateTime = dayjs(mindateTime);

    const filteredTimes = times.filter((time: IDropDown) => {
      const dateTime = new Date(`2024-01-01 ${time.value}`);
      const timeObj = dayjs(dateTime);

      return (
        timeObj.isAfter(dayjsMinDateTime) ||
        (minTime === DefaultMinTime && timeObj.isSame(dayjsMinDateTime))
      );
    });
    setdigitalTimes(filteredTimes);

    if (value) {
      let selectedValue = filteredTimes.find(
        (time: IDropDown) => time.value === value
      );
      changeHandler(selectedValue ?? filteredTimes[0]);
    }
  };
  useEffect(() => {
    loadTimes();
  }, [value, minTime]);

  return (
    <div className={Style.floor_field}>
      <div
        className={`${Style.field} ${
          size === SizeType.small ? Style.small_field : ""
        }`}
      >
        <DropDown
          value={selectedTime}
          data={digitalTimes}
          label={label}
          onChange={changeHandler}
        />
      </div>
    </div>
  );
};

export default TimePickerDropDown;
