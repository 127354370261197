import React, { useEffect, useState } from "react";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { AppThunkDispatch } from "../../../../redux/Store";
import DashboardBody from "../../dashboardBody/DashboardBody";
import { GetUserBuildingDataAction } from "../../../../redux/slices/UserBuildingSlice";
import {
  GetUserId,
  GetUserTokenInfo,
} from "../../../../services/commonServices/CommonServices";
import { GetUserDataAction } from "../../../../redux/slices/UserSlice";
import BuildingGeneralInfo from "./buildingInfo/BuildingGeneralInfo";
import { UserCredentialInfoViewModel } from "../../../../viewModels/api/Models";

const BuildingInfo = () => {
  const dispatch = useDispatch<AppThunkDispatch>();

  const userCredential = useSelector((state: IReducer) => state.user);
  const userBuilding = useSelector((state: IReducer) => state.building);

  const [, setUserCredentialInfo] = useState<UserCredentialInfoViewModel | null>(null);
  const [, setPreload] = useState(true);

  useEffect(() => {
    if (userCredential.userLogin) {
      setUserCredentialInfo(userCredential.user);
    } else {
      var userToken = GetUserTokenInfo();
      dispatch(GetUserDataAction(userToken?.userId ?? ""));
    }
  }, [userCredential]);

  useEffect(() => {
    if (userBuilding.fill === true) {
      setPreload(false);
    } else {
      var userId: string | undefined = GetUserId();
      dispatch(GetUserBuildingDataAction(userId ?? ""));
    }
  }, [userBuilding]);
  return (
    <DashboardContainer>
      <DashboardBody>
        <BuildingGeneralInfo />
      </DashboardBody>
    </DashboardContainer>
  );
};

export default BuildingInfo;
