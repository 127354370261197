import styled from "styled-components";

export const DashboardBox = styled.div`
  min-height: 12vh;
  overflow: visible;
  padding: 2rem;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  position: relative;
  height: 100%;
  max-height: auto;
  display: flex;
  flex-direction: column;
  > div {
    &:last-child {
      flex: 1;
      //display: grid;
    }
  }
  @media screen and (max-width: 1200px) and (max-height: 900px) {
    border-radius: 12px;
  }
  @media screen and (max-width: 1100px) and (max-height: 800px) {
    padding: 1.5rem 1.6rem;
  }
  @media (max-width: 900px) {
    padding: 1rem;
  }
  @media (max-width: 900px) {
    border-radius: 8px;
  }
  @media (max-width: 900px) {
    padding: 0rem;
  }
`;
