import dayjs from "dayjs";
import { GetAnouncementsList } from "../../../../../services/api/Anouncement";
import {
  FloorinsDateTimeFormat,
  IsNullString,
} from "../../../../../services/commonServices/CommonServices";
import { ApiResponse, IServiceResult } from "../../../../../viewModels/api/Api";
import { Announcement } from "../../../../../viewModels/api/Models";
import { AnnouncementViewModel } from "../../../../../viewModels/dataTypes/announcement/AnnouncementViewModel";
import { FilterConditionsEnum } from "../../../../../viewModels/enums/FilterConditionsEnum";
import { CalenderEnum } from "../../../../../viewModels/enums/CalenderEnum";
import { FormatDateDefault } from "../../../../../Setting";

export const GetAnnouncementItems = async (
  buildingId: string
): Promise<IServiceResult<AnnouncementViewModel[]>> => {
  const resultModel: IServiceResult<AnnouncementViewModel[]> = {
    message: "",
    data: null,
  };

  const getAnnouncementsResults: ApiResponse<Announcement[]> =
    await GetAnouncementsList(buildingId);

  const transformedData: AnnouncementViewModel[] =
    getAnnouncementsResults.data?.map((item: Announcement, index: number) => ({
      id: item.id ?? 0,
      createdDateTime: FloorinsDateTimeFormat(
        item.createdDateTime?.toString() ?? ""
      ),
      buildingId: item.buildingId ?? 0,
      title: item.title ?? "",
      description: item.description ?? "",
      label: item.label ?? "",
      isDelete: item.isDelete ?? false,
      announcementAttachment: item.announcementAttachment ?? [],
      number: index + 1,
    })) || [];

  resultModel.data = transformedData;

  return resultModel;
};

const SearchAnnouncements = (
  data: AnnouncementViewModel[],
  searchTerm: string
) => {
  if (IsNullString(searchTerm)) {
    return data;
  }

  return data.filter((item: AnnouncementViewModel) => {
    const { title, description, createdDateTime } = item;

    const searchInFields = [
      title?.toString().toLowerCase() ?? "",
      description?.toString().toLowerCase() ?? "",
      createdDateTime?.toString().toLowerCase() ?? "",
    ];

    return searchInFields.some((field) =>
      field.includes(searchTerm.toLowerCase())
    );
  });
};

export const FilterAnnouncements = (
  data: AnnouncementViewModel[],
  labels: string,
  labelsLogic: string,
  searchText: string,
  dateRange: { start: string; end: string } | null,
  selectedMode: string
) => {
  const searchedData = SearchAnnouncements(data, searchText);

  const filteredData = searchedData.filter((item: AnnouncementViewModel) => {
    const labelsList: string[] = labels
      ? labels.split(",").map((u) => u.trim())
      : [];

    const labelsMatch =
      labelsLogic === FilterConditionsEnum.OR
        ? labelsList.length === 0 ||
          labelsList.some(
            (label: string) => item.label?.split(",").includes(label)
          )
        : labelsList.length === 0 ||
          labelsList.every(
            (label: string) => item.label?.split(",").includes(label)
          );

    const dateMatch =
      !dateRange ||
      (() => {
        const itemDate = dayjs(item.createdDateTime);
        const startDate = dayjs(
          dateRange.start,
          selectedMode === CalenderEnum.MONTH
            ? FormatDateDefault
            : FormatDateDefault,
          true
        );
        const endDate = dayjs(
          dateRange.end,
          selectedMode === CalenderEnum.MONTH
            ? FormatDateDefault
            : FormatDateDefault,
          true
        );

        if (!itemDate.isValid() || !startDate.isValid() || !endDate.isValid()) {
          return false;
        }

        switch (selectedMode) {
          case CalenderEnum.DAY:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.DAY) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.DAY)
            );
          case CalenderEnum.WEEK:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.DAY) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.DAY)
            );
          case CalenderEnum.MONTH:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.MONTH) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.MONTH)
            );
          case CalenderEnum.YEAR:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.YEAR) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.YEAR)
            );
          default:
            return true;
        }
      })();

    return labelsMatch && dateMatch;
  });

  return filteredData;
};
