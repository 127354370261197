import React from "react";
import { Grid } from "@mui/material";
import { MediumDeviceTrigger } from "../../../../../../../Setting";
import useWindowSize from "../../../../../../../hook/WindowSize";
import DashboardFormTitle from "../../../../../../../tools/dashboardTitle/dashboardFormTitle/DashboardFormTitle";
import Style from "./TenantContract.module.scss";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import { useTranslation } from "react-i18next";

interface Props {
  children: React.ReactNode;
  className?: string;
}
const TenantContract: React.FC<Props> = ({ children, className }) => {
  const windowSize = useWindowSize();
  const { t: translate } = useTranslation();
  return (
    <div className={`${Style.contract} ${className}`}>
      <DashboardFormTitle
        title={translate("UNIT.CONTRACT_TERMS")}
        icon={<ReceiptLongOutlinedIcon className={Style.contracts_icon} />}
        className={Style.contract_title}
      />
      <Grid
        className={Style.contract_container}
        container
        columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
      >
        {children}
      </Grid>
    </div>
  );
};

export default TenantContract;
