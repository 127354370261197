import { ReSendEmailOtp, ReSendSMSOtp } from "../../../services/api/User";
import { ApiResponse, IServiceResult } from "../../../viewModels/api/Api";
import { ReceiverType } from "../../../viewModels/api/Models";
import { CheckEmailOrPhoneNumberResponse } from "../../../viewModels/dataTypes/registration/SingUp";
import { ResponseCode } from "../../../viewModels/enums/ResponseCode";

export const DeliveryMethodDetection = async (
  receiver: string,
  deliveryType: ReceiverType
) => {
  switch (deliveryType) {
    case ReceiverType.Email:
      return ReSendEmailOtp(receiver);
    case ReceiverType.PhoneNumber:
      return ReSendSMSOtp(receiver);
  }
};
export const ReSendOtpService = async (
  receiver: string,
  deliveryType: ReceiverType
) => {
  var checkOtpInfo: IServiceResult<CheckEmailOrPhoneNumberResponse> = {
    message: "",
    data: null,
  };
  var sendOtpApiResponse: ApiResponse<CheckEmailOrPhoneNumberResponse> =
    await DeliveryMethodDetection(receiver, deliveryType);
  if (sendOtpApiResponse.data != null) {
    if (sendOtpApiResponse.data.status === ResponseCode.Accepted) {
      checkOtpInfo.data = sendOtpApiResponse.data;
    } else {
      checkOtpInfo.message = "ERRORS.OPERATION_FAILD";
    }
  } else {
    checkOtpInfo.message = "ERRORS.OPERATION_FAILD";
  }
  return checkOtpInfo;
};
