import React, { useState, useEffect } from "react";
import { MakeAvatarWithName } from "../../../services/commonServices/CommonServices";
import Style from "./ProfileComponent.module.scss";
import { RandomColorEnum } from "../../../viewModels/enums/AvatarBackGround";

interface Props {
  value: string;
  avatar?: string;
  bgColor?: RandomColorEnum;
}

// Utility function to generate a hash from a string
function hashStringToColor(str: string): RandomColorEnum {
  const hash = Array.from(str).reduce(
    (acc, char) => acc + char.charCodeAt(0),
    0
  );
  const colorKeys = Object.keys(RandomColorEnum);
  return RandomColorEnum[
    colorKeys[hash % colorKeys.length] as keyof typeof RandomColorEnum
  ];
}

const ProfileComponent: React.FC<Props> = ({ value, avatar, bgColor }) => {
  const [backgroundColor, setBackgroundColor] = useState<RandomColorEnum>(
    bgColor || RandomColorEnum.Blue
  );

  useEffect(() => {
    if (!avatar) {
      setBackgroundColor(hashStringToColor(value));
    }
  }, [avatar, value]);

  // Function to generate a random background color
  function generateBgColor(backgroundColor: RandomColorEnum): string {
    switch (backgroundColor) {
      case RandomColorEnum.Red:
        return Style.redBg;
      case RandomColorEnum.LightBlue:
        return Style.lightBlueBg;
      case RandomColorEnum.Blue:
        return Style.darkBlueBg;
      case RandomColorEnum.Green:
        return Style.greenBg;
      default:
        return "";
    }
  }

  return (
    <div className={Style.profile_component}>
      <div className={Style.avatar}>
        {avatar == null ? (
          <div className={generateBgColor(backgroundColor)}>
            {MakeAvatarWithName(value)}
          </div>
        ) : (
          <div className={Style.logo}>
            <img src={avatar} alt={`${value} avatar`} />
          </div>
        )}
      </div>
      <div className={Style.profile_name}>
        <h3>{value}</h3>
      </div>
    </div>
  );
};

export default ProfileComponent;
