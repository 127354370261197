import React from "react";
import Style from "./PlaceAutoComplete.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  getInputProps: any;
  changeLabelColor: boolean;
}
const PlaceAutoComplete: React.FC<Props> = ({
  getInputProps,
  changeLabelColor,
}) => {
  const { t: translate } = useTranslation();
  return (
    <div className={Style.floor_field}>
      <label className={`${changeLabelColor ? Style.fill_field : ""}`}>
        <span className={Style.label}>{translate("LABELS.ADDRESS")}</span>
      </label>

      <div className={`${Style.field}`}>
        <input
          className={Style.suggestion_auto_complete}
          {...getInputProps({ placeholder: translate("PLACEHOLDER.ADDRESS") })}
        />
      </div>
    </div>
  );
};

export default PlaceAutoComplete;
