import * as Icons from "@mui/icons-material";
import { ServiceCategory } from "../../../../../../viewModels/api/Models";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../viewModels/api/Api";
import {
  AddServiceCategory,
  GetServiceCategories,
} from "../../../../../../services/api/BuildingServices";

export interface Sample {
  title: string;
  icon: keyof typeof Icons;
  color: string;
}

export const GetServicesCategoryItems = async (buildingId: number) => {
  const resultModel: IServiceResult<ServiceCategory[] | null> = {
    message: "",
    data: null,
  };
  const getServiceCategoriesResult: ApiResponse<ServiceCategory[]> =
    await GetServiceCategories(buildingId);
  resultModel.data = getServiceCategoriesResult.data;
  return resultModel;
};

export const AddServiceCategoryService = async (
  buildingId: number,
  title: string,
  iconId: number
) => {
  const resultModel: IServiceResult<ServiceCategory | null> = {
    message: "",
    data: null,
  };

  const newServiceCategoryInfo: ServiceCategory = {
    buildingId: buildingId,
    isDelete: false,
    title: title,
    iconId: iconId,
  };
  const getServiceCategoriesResult: ApiResponse<ServiceCategory> =
    await AddServiceCategory(newServiceCategoryInfo);

  resultModel.data = getServiceCategoriesResult.data;
  return resultModel;
};
