import {
  ForgetPasswordViaEmail,
  ForgetPasswordViaPhone,
} from "../../../../services/api/UserAuth";
import { ResponseCode } from "../../../../viewModels/enums/ResponseCode";
import {
  IServiceResult,
  ApiResponse,
} from "../../../../viewModels/api/Api";
import { UserContactValidationModel } from "../../../../viewModels/dataTypes/Cookie";
import { IUser } from "../../../../viewModels/dataTypes/user/User";
import { CheckEmailOrPhoneNumberResponse } from "../../../../viewModels/dataTypes/registration/SingUp";
import { GetUserInfoByUserContact } from "../../../../services/api/User";
import { ReceiverType } from "../../../../viewModels/api/Models";

//Before Change Password
export const CheckUserItemValidationService = async (ueserDataJson: string) => {
  var userValidationData: UserContactValidationModel =
    JSON.parse(ueserDataJson);

  var userInfo: ApiResponse<IUser> = await GetUserInfoByUserContact(
    userValidationData.userCountry != null
      ? userValidationData.userCountry.code + userValidationData.userContact
      : userValidationData.userContact
  );
  if (userInfo.data != null) {
    if (userValidationData.otpDeliveryMethod == ReceiverType.Email) {
      userValidationData.otpDeliveryMethod = ReceiverType.PhoneNumber;
      userValidationData.userContact = userInfo.data.phoneNumber.replace(
        userInfo.data.countryCode,
        ""
      );
      userValidationData.userCountry = {
        code: userInfo.data?.countryCode,
        symbol: userInfo.data?.countryAbbreviation,
        name: userInfo.data?.countryName,
        id: userInfo.data.countryId,
      };
      userValidationData.id = userInfo.data.id;
    } else {
      userValidationData.otpDeliveryMethod = ReceiverType.Email;
      userValidationData.userContact = userInfo.data.email;
      userValidationData.id = userInfo.data.id;
    }
    return userValidationData;
  } else {
    return null;
  }
};

export const ForgetPasswordService = async (
  deliveryMethod: ReceiverType,
  receiver: string
) => {
  switch (deliveryMethod) {
    case ReceiverType.Email:
      return ForgotPasswordViaEmailService(receiver);
    case ReceiverType.PhoneNumber:
      return ForgotPasswordViaPhoneService(receiver);
  }
};

export const ForgotPasswordViaEmailService = async (email: string) => {
  var resultModel: IServiceResult<CheckEmailOrPhoneNumberResponse> = {
    message: "",
  };
  var forgotPassResult: ApiResponse<CheckEmailOrPhoneNumberResponse> =
    await ForgetPasswordViaEmail(email);
  if (forgotPassResult.responseStatus === ResponseCode.Success) {
    if (forgotPassResult.data?.status === ResponseCode.Accepted) {
      resultModel.data = forgotPassResult.data;
    } else {
      resultModel.message = "ERRORS.TRY_OTP_LATER";
    }
  } else {
    resultModel.message = "ERRORS.OPERATION_FAILD";
  }
  return resultModel;
};

export const ForgotPasswordViaPhoneService = async (phoneNumber: string) => {
  var resultModel: IServiceResult<CheckEmailOrPhoneNumberResponse> = {
    message: "",
  };
  var forgotPassResult: ApiResponse<CheckEmailOrPhoneNumberResponse> =
    await ForgetPasswordViaPhone(phoneNumber);
  if (forgotPassResult.responseStatus === ResponseCode.Success) {
    if (forgotPassResult.data?.status === ResponseCode.Accepted) {
      resultModel.data = forgotPassResult.data;
    } else {
      resultModel.message = "ERRORS.TRY_OTP_LATER";
    }
  } else {
    resultModel.message = "ERRORS.OPERATION_FAILD";
  }
  return resultModel;
};
