import React from "react";
import Style from "./CheckBox.module.scss";
import { Checkbox } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

interface Props {
  onChecked: (isChecked: boolean) => void;
  isChecked: boolean;
  className?: string;
  checkBoxText: string;
}
const CheckBox: React.FC<Props> = ({
  onChecked,
  isChecked,
  className,
  checkBoxText,
}) => {
  return (
    <div className={`${Style.check_box} ${className}`}>
      <Checkbox
        checkedIcon={<CheckBoxIcon className={Style.checkbox_icon} />}
        icon={
          <CheckBoxOutlineBlankIcon className={Style.checkbox_blank_icon} />
        }
        onChange={(e) => onChecked(e.target.checked)} // Change the state of the checkbox
        checked={isChecked} // Check if the item is selected
      />
      {checkBoxText}
    </div>
  );
};

export default CheckBox;
