import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Style from "./AnnouncementItems.module.scss";
import IconWrapper from "../../../../../../tools/icons/CustomIcon";
import { useTranslation } from "react-i18next";
import AnouncementLabels from "../anouncementLabel/AnouncementLabels";
import { LabelEnum } from "../../../../../../viewModels/enums/LabelEnum";
import { getLabelClass } from "../../GetLabelClass";

interface Props {
  icon?: string;
  link?: string;
  title?: string;
  text?: string;
  colorTitle?: string;
  colorLabel?: string;
  addType?: boolean;
  number?: string;
  createdDateTime?: string;
}

const AnnouncementItems: React.FC<Props> = ({
  icon = "",
  number,
  link = "",
  title,
  text,
  colorLabel,
  addType,
  createdDateTime,
}) => {
  const Wrapper: React.ElementType = Link;
  const { t } = useTranslation();
  const { wrapperClass } = getLabelClass(colorLabel);
  return (
    <Wrapper
      className={`${Style.announcement_item} ${wrapperClass} ${
        addType ? Style.announcement_item_add : ""
      }`}
      {...{ to: link }}
    >
      {addType && <IconWrapper className={Style.add_icon} name={icon} />}
      <h3
        title={title}
        className={`${Style.title} ${addType ? Style.title_add : ""}  ${
          LabelEnum.Advertisement === colorLabel ? Style.title_green : ""
        }`}
      >
        {title}
      </h3>
      {!addType && <div className={Style.description}>{text}</div>}
      {!addType && (
        <div className={Style.footer_container}>
          <p>{createdDateTime}</p>
          <p>
            {t("ANNOUNCEMENTS.NUMBER")}
            {number}
          </p>
        </div>
      )}
      {!addType && <AnouncementLabels label={colorLabel} />}
    </Wrapper>
  );
};

export default AnnouncementItems;
