import React, { useEffect, useState } from "react";
import Style from "./CheckBox.module.scss";
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { IDropDown } from "../../../viewModels/dataTypes/tools/DropDownItems";
import { SizeType } from "../../../viewModels/dataTypes/tools/ListComponentType";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useDropdownPosition from "../../../hook/DropdownPosition";

interface Props {
  data: IDropDown[];
  values: IDropDown[] | null;
  onChange: (value: IDropDown[] | null) => void;
  label: string;
  size?: SizeType;
  placeHolder: string;
  readOnly?: boolean;
  requiredField?: boolean;
}

const CheckBox = ({
  data,
  onChange,
  values = null,
  label,
  size = SizeType.large,
  placeHolder,
  readOnly = false,
  requiredField = false,
}: Props) => {
  const [checkBoxItems, setCheckBoxItems] = useState<IDropDown[]>([]);
  const { selectRef, menuPosition, calculatePosition } = useDropdownPosition();

  useEffect(() => {
    setCheckBoxItems(values ? values : []);
  }, [values]);
  const handleChange: (event: SelectChangeEvent<string[]>) => void = (
    event: SelectChangeEvent<string[]>
  ) => {
    const {
      target: { value },
    } = event;

    const selectedItems = data.filter((item: IDropDown) =>
      value.includes(item.text.toString())
    );
    setCheckBoxItems(selectedItems);
    onChange(selectedItems.length === 0 ? null : selectedItems);
  };
  return (
    <div className={Style.dropdown_box}>
      <label
        className={`${Style.label} ${
          checkBoxItems.length > 0 ? Style.fill_field : ""
        }`}
      >
        <span>
          {label} {requiredField && "*"}
        </span>
      </label>
      <div
        ref={selectRef}
        className={`${Style.dropdown_items} ${
          size === SizeType.small ? Style.small_field : ""
        }`}
      >
        <FormControl className={Style.dropdown} sx={{ width: 300 }}>
          {checkBoxItems.length > 0 ? (
            <></>
          ) : (
            <div className={Style.placeholder}>{placeHolder}</div>
          )}

          <Select
            multiple
            IconComponent={ExpandMoreIcon}
            onOpen={calculatePosition}
            MenuProps={{
              classes: { paper: Style.dropdown_menu },
              anchorOrigin: {
                vertical: menuPosition === "top" ? "top" : "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: menuPosition === "top" ? "bottom" : "top",
                horizontal: "left",
              },
            }}
            value={checkBoxItems.map((item: IDropDown) => item.text)} // Update the value prop to be an array of string values
            onChange={handleChange}
            renderValue={(selected) =>
              selected.map((item: string) => item).join(", ")
            } // Render text values
            displayEmpty
          >
            {data.map((item: IDropDown, index: number) => (
              <MenuItem
                key={index}
                value={item.text} // Update the value prop to be a string value
                className={Style.dropdown_item}
              >
                <Checkbox
                  disabled={readOnly}
                  checkedIcon={
                    <CheckBoxIcon sx={{ color: "var(--icon-primary)" }} />
                  }
                  icon={
                    <CheckBoxOutlineBlankIcon
                      sx={{ stroke: "var(--icon-white)", strokeWidth: 1 }}
                    />
                  }
                  checked={checkBoxItems.some(
                    (checkItem: IDropDown) => checkItem.value === item.value
                  )} // Check if the item is selected
                />
                <ListItemText primary={item.text} /> {/* Render text */}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default CheckBox;
