import React from "react";
import PushPinIcon from "@mui/icons-material/PushPin";
import Style from "./AnouncementLabels.module.scss";
import { getLabelClass } from "../../GetLabelClass";
interface Props {
  label?: string;
}
const AnouncementLabels: React.FC<Props> = ({ label }) => {
  const { iconClass } = getLabelClass(label); 

  return (
    <div className={`${Style.label_container}`}>
      <PushPinIcon className={`${Style.label_icon} ${iconClass}`} />
    </div>
  );
};

export default AnouncementLabels;
