import { Trash } from "iconsax-react";
import React from "react";
import Style from "./DeleteInfo.module.scss";
import { ClassNameViewModel } from "../../../../../../../../viewModels/dataTypes/CommonViewModels";

interface Props extends ClassNameViewModel {
  onClick: () => void;
}
const DeleteInfo: React.FC<Props> = ({ onClick, className = "" }) => {
  return (
    <div className={className} onClick={onClick}>
      <Trash className={Style.delete_icon} />
    </div>
  );
};

export default DeleteInfo;
