import React, { useEffect, useState } from "react";
import Style from "./BuildingServices.module.scss";
import BuildingServiceItem from "../buildingServiceItem/BuildingServiceItem";
import { useParams } from "react-router-dom";
import BuildingDeleteService from "../buildingDeleteService/BuildingDeleteService";
import { BuildingServices } from "../../../../../../../viewModels/api/Models";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../../../redux/Store";
import { GetBuildingServices } from "../../../../../../../redux/slices/BuildingServices";
import {
  IBuildingServicesReducer,
  IReducer,
} from "../../../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useTranslation } from "react-i18next";
import ServisesModifieItems from "../servicesModifieItems/ServicesModifieItems";
import { IServiceResult } from "../../../../../../../viewModels/api/Api";
import { DeleteServicesItems } from "./BuildingServicesList.service";
import { CreateToast } from "../../../../../../../tools/toast/ToastService";
import { ToastType } from "../../../../../../../viewModels/enums/ToastType";
import Loading from "../../../../../../../tools/loading/Loading";
import useBodyScrollLock from "../../../../../../../hook/BodyScrollLock";

const BuildingServicesList = () => {
  //configs
  const { t: translate } = useTranslation();
  //states
  const { buildingId, categoryId } = useParams<Record<string, string>>();
  const [services, setServices] = useState<BuildingServices[] | null>(null);
  const [openDeletePopUp, setOpenDeletePopUp] = useState<boolean>(false);
  const [serviceName, setServiceName] = useState<string>("");
  const [serviceToDelete, setServiceToDelete] = useState<number | null>(null);
  const buildingIdNumber = Number(buildingId);
  const categoryIdNumber = Number(categoryId);
  const [preload, setPreload] = useState<boolean>(true);
  const [progressing, setProgressing] = useState<boolean>(false);
  const [activeItemId, setActiveItemId] = useState<number | null>(null);
  const [isCancelingDeletePopup, setIsCancelingDeletePopup] =
    useState<boolean>(false);
  const buildingServicesInfo: IBuildingServicesReducer = useSelector(
    (state: IReducer) => state.buildingServices
  );

  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    const getServicesInfo = async () => {
      setPreload(true);
      setServices(null);
      await dispatch(
        GetBuildingServices({
          buildingId: buildingIdNumber,
          categoryId: categoryIdNumber,
        })
      );
      setPreload(false);
    };

    getServicesInfo();
  }, [dispatch, buildingIdNumber, categoryIdNumber]);

  useEffect(() => {
    setServices(buildingServicesInfo.services);
  }, [buildingServicesInfo.services]);

  // Handle clicking outside to reset all isActive states
  useEffect(() => {
    const handleClickOutside: (event: MouseEvent) => void = () => {
      if (!openDeletePopUp && !isCancelingDeletePopup) {
        setActiveItemId(null);
      }
      setIsCancelingDeletePopup(false);
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openDeletePopUp, isCancelingDeletePopup]);

  const openDeletePopUpHandler = (title: string, id: number) => {
    setServiceName(title);
    setServiceToDelete(id);
    setOpenDeletePopUp(true);
  };
  const cancelDeleteHandler = () => {
    setIsCancelingDeletePopup(true);
    setOpenDeletePopUp(false);
  };
  const deleteServiceHandler = async () => {
    setProgressing(true);
    if (serviceToDelete !== null) {
      const deleteServiceResult: IServiceResult<boolean> =
        await DeleteServicesItems(serviceToDelete);
      if (deleteServiceResult.data) {
        setOpenDeletePopUp(false);
        setProgressing(false);
        CreateToast(
          ToastType.Success,
          translate("ALERT.DELETED_SUCCESSFULLY", {
            value: translate("SHARED.SERVICE"),
          })
        );
        await dispatch(
          GetBuildingServices({
            buildingId: buildingIdNumber,
            categoryId: categoryIdNumber,
          })
        );
      } else {
        setProgressing(false);
        CreateToast(
          ToastType.Error,
          translate("ERRORS.DELETED_FAILD", {
            value: translate("SHARED.SERVICE"),
          })
        );
      }
    }
  };
  const handleItemClick: (itemId: number, event: React.MouseEvent) => void = (
    itemId: number,
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    setActiveItemId(itemId);
  };
  useBodyScrollLock(openDeletePopUp);

  return (
    <div className={Style.service_items_container}>
      <BuildingServiceItem
        title={translate("BUTTON.ADD")}
        className={Style.add_card}
        color={"var(--text-primary)"}
        icon={"Add"}
        link={`/dashboard/building/services/${buildingId}/${categoryId}/add`}
      />
      {preload ? (
        <Loading />
      ) : (
        services?.map((service: BuildingServices) => (
          <BuildingServiceItem
            key={service.id}
            color={service.iconColor ?? ""}
            icon={service.iconTitle ?? ""}
            title={service.title ?? ""}
            link={`/dashboard/building/services/${buildingId}/${categoryId}/${service.id}/details`}
            callToActionComponent={
              <ServisesModifieItems
                onDelete={() =>
                  openDeletePopUpHandler(service.title ?? "", service.id!)
                }
              />
            }
            isActive={service.id === activeItemId}
            onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
              handleItemClick(service.id!, event)
            }
          />
        ))
      )}

      {openDeletePopUp && (
        <div>
          <BuildingDeleteService
            serviceName={serviceName}
            cancelDeleteHandler={cancelDeleteHandler}
            deleteHandler={deleteServiceHandler}
            loading={progressing}
          />
        </div>
      )}
      {progressing && <Loading />}
    </div>
  );
};

export default BuildingServicesList;
