import { CheckPhoenValidation, EmailRegex } from "../../../../services/Regex";
import {
  CheckEmailToSignUp,
  CheckPhoneNumberToSignUp,
} from "../../../../services/api/User";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../viewModels/api/Api";
import { CountryType } from "../../../../viewModels/dataTypes/country/Country";
import { CheckEmailOrPhoneNumberResponse } from "../../../../viewModels/dataTypes/registration/SingUp";
import { ResponseCode } from "../../../../viewModels/enums/ResponseCode";

export const CheckPhoneNumberService = async (
  phoneNumber: string,
  country?: CountryType | null
) => {
  // define a parameter to handle Api response
  var result: IServiceResult<CheckEmailOrPhoneNumberResponse> = {
    message: "",
    data : null
  };
  // check phone number validation
  let isValdate = CheckPhoenValidation(phoneNumber);
  if (isValdate) {
    const setPhoneNumber: ApiResponse<CheckEmailOrPhoneNumberResponse> =
      await CheckPhoneNumberToSignUp(country?.code + phoneNumber);
    if (setPhoneNumber.responseStatus == ResponseCode.Success) {
      //when the response status of request is 200
      if (setPhoneNumber.data?.status == ResponseCode.Accepted) {
        // when there aren't any problem to sign-up with the phone number
        result.data = setPhoneNumber.data;
      } else if (setPhoneNumber.data?.status == ResponseCode.Created) {
        // when the phone number is resgistred
        result.message = "ERRORS.EXIST_PHONENUMBER";
      } else {
        result.message = "ERRORS.TRY_OTP_LATER";
      }
    } else {
      result.message = "ERRORS.OPERATION_FAILD";
    }
  } else {
    result.message = "ERRORS.INVALID_PHONENUMBER";
  }
  // call check user phone number Api

  return result;
};

export const CheckEmailService = async (email: string) => {
  // define a parameter to handle Api response
  var result: IServiceResult<CheckEmailOrPhoneNumberResponse> & {
    route: string;
  } = {
    message: "",
    route: "",
  };
  // check email validation
  let isValdate = EmailRegex(email);
  if (isValdate) {
    // call check user email address Api
    const setEmail: ApiResponse<CheckEmailOrPhoneNumberResponse> =
      await CheckEmailToSignUp(email);
    if (setEmail.responseStatus == ResponseCode.Success) {
      //when the response status of request is 200
      if (setEmail.data?.status == ResponseCode.Accepted) {
        // when there aren't any problem to sign-up with the email address

        result.route = "/signup/checkEmailOtp";
        result.data = setEmail.data;
      } else if (setEmail.data?.status == ResponseCode.Created) {
        // when the email address is resgistred
        result.message = "ERRORS.EXIST_EMAIL";
      } else {
        result.message = "ERRORS.TRY_OTP_LATER";
      }
    } else {
      result.message = "ERRORS.OPERATION_FAILD";
    }
  } else {
    result.message = "ERRORS.INVALID_EMAIL";
  }
  return result;
};
