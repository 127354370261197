import React from "react";
import Style from "./NotFoundRow.module.scss";

interface Props {
  message: string;
}
const NotFoundRow: React.FC<Props> = ({ message }) => {
  return <div className={Style.text}>{message}</div>;
};

export default NotFoundRow;
