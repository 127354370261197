import { DeleteAdministrator } from "../../../../../../../../services/api/User";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../../../viewModels/api/Api";
import { DeleteAdministratorViewModel } from "../../../../../../../../viewModels/api/Models";
import { ResponseCode } from "../../../../../../../../viewModels/enums/ResponseCode";

export const DeleteAdministratorService = async (
  adminInfo: DeleteAdministratorViewModel
) => {
  var resultModel: IServiceResult<boolean> = {
    message: "",
  };

  var result: ApiResponse<boolean> = await DeleteAdministrator(adminInfo);
  if (result.responseStatus == ResponseCode.Success) {
    resultModel.data = result.data;
  }

  return resultModel;
};
