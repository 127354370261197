import { Attachments } from "../../../../../../../viewModels/api/Models";

export interface ComplaintInfoState {
  subject: string;
  reportedBy: string;
  description: string;
  attachments: Attachments[];
}

export enum ComplaintInfoActionType {
  Subject = "SET_SUBJECT",
  ReportedBy = "SET_REPORTED_BY",
  Description = "SET_DESCRIPTION",
  Attachments = "SET_ATTACHMENTS",
}

export type ComplaintInfoAction =
  | { type: ComplaintInfoActionType.Subject; payload: string }
  | { type: ComplaintInfoActionType.ReportedBy; payload: string }
  | { type: ComplaintInfoActionType.Description; payload: string }
  | { type: ComplaintInfoActionType.Attachments; payload: Attachments[] };

export const InitialComplaintInfoState: ComplaintInfoState = {
  subject: "",
  description: "",
  reportedBy: "",
  attachments : []
};

export const ComplaintInfoReducer = (
  state: ComplaintInfoState,
  action: ComplaintInfoAction
): ComplaintInfoState => {
  switch (action.type) {
    case ComplaintInfoActionType.Subject:
      return { ...state, subject: action.payload };
    case ComplaintInfoActionType.Description:
      return { ...state, description: action.payload };
    case ComplaintInfoActionType.ReportedBy:
      return { ...state, reportedBy: action.payload };
        case ComplaintInfoActionType.Attachments:
      return { ...state, attachments: action.payload };
    default:
      return state;
  }
};
