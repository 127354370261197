import { GoogleMap, Marker } from "@react-google-maps/api";
import { Location } from "iconsax-react";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Style from "./Map.module.scss";
import Loading from "../loading/Loading";
import PlaceAutoComplete from "./placeAutoComplete/PlaceAutoComplete";
import { GoogleMapRequest } from "../../text/Api";

interface Props {
  pinedAddress: string;
  onChangeAddress: (value: string) => void;
}
const Map: React.FC<Props> = ({ onChangeAddress, pinedAddress }) => {
  const [address, setAddress] = useState("");
  const [changeLabelColor, setChangeLabelColor] = useState(false);
  const [position, setPosition] = useState<google.maps.LatLngLiteral | null>(
    null
  );
  const [googleApiLoaded, setGoogleApiLoaded] = useState(false);

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement("script");
      script.src = GoogleMapRequest;
      script.async = true;
      script.onload = () => {
        setGoogleApiLoaded(true);
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      setGoogleApiLoaded(true);
    }
  }, []);

  useEffect(() => {
    const loadMap = async () => {
      const results = await geocodeByAddress(pinedAddress);
      const latLng = await getLatLng(results[0]);
      setPosition({ lat: latLng.lat, lng: latLng.lng });
      setAddress(pinedAddress);
      onChangeAddress(pinedAddress);
      if (pinedAddress.length > 0) {
        setChangeLabelColor(true);
      }
    };
    loadMap();
  }, [googleApiLoaded, pinedAddress]);

  const handleSelect = async (selectedAddress: string) => {
    setChangeLabelColor(selectedAddress.length > 0);
    setAddress(selectedAddress);
    onChangeAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setPosition({ lat: latLng.lat, lng: latLng.lng });
    } catch (error) {
      console.error("Error", error);
    }
  };

  if (!googleApiLoaded) {
    return <Loading />;
  }

  return (
    <div className={Style.map_box}>
      <div className={Style.map_searchbox}>
        <PlacesAutocomplete
          value={address}
          onChange={handleSelect}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className={Style.suggestion_places}>
              <PlaceAutoComplete
                changeLabelColor={changeLabelColor}
                getInputProps={getInputProps}
              />
              <div className={Style.suggestion_auto_complete}>
                {loading && <></>}
                {suggestions.map((suggestion) => {
                  return (
                    <div
                      className={`${Style.suggestions_item} ${
                        suggestion.active ? Style.active_suggestion : ""
                      }`}
                      {...getSuggestionItemProps(suggestion)}
                    >
                      <div>
                        <Location
                          size="18"
                          color="var(--text-gray-medium)"
                          variant={suggestion.active ? "Bold" : "Linear"}
                        />
                      </div>

                      <span> {suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <div className={Style.map}>
        <GoogleMap
          center={position || { lat: 25.2007, lng: 55.258952 }}
          zoom={15}
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
          }}
        >
          {position ? <Marker position={position} /> : <></>}
        </GoogleMap>
      </div>
    </div>
  );
};

export default Map;
