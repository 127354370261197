import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import Style from "./CountrySelect.module.scss";
import { Flag20, Flag40 } from "../../../text/Url";
import { CountryType } from "../../../viewModels/dataTypes/country/Country";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DefaultCountryRegen } from "../../../Setting";
import StyledAutocompletePaper from "../StyledAutocompletePaper";

interface Props {
  countries: CountryType[];
  changeCountry: (country: CountryType) => void;
  setEditCountry: (state: boolean) => void;
  defaultCountry: CountryType;
  readOnly?: boolean;
  onBlur?: () => void;
  className?: string;
}
const CountrySelect: React.FC<Props> = ({
  changeCountry,
  setEditCountry,
  defaultCountry,
  countries,
  readOnly = false,
  onBlur,
  className = "",
}) => {
  const [open, setopen] = useState<boolean>(false);
  const [preload, setPreload] = useState<boolean>(true);
  const [value, setValue] = useState<CountryType>();
  const [autoCompleteEditState, setAutoCompleteEditState] =
    useState<boolean>(false);

  useEffect(() => {
    if (defaultCountry != undefined) {
      setValue(defaultCountry);
      setPreload(false);
    }
  }, [countries, defaultCountry]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const updateCountryState = (theCountry: CountryType) => {
    changeCountry(theCountry);
    setValue(theCountry);
    if (onBlur) {
      onBlur();
    }
  };

  const handleClickToOpenAutoComplete = () => {
    setAutoCompleteEditState(true);
    setopen(true);
    setEditCountry(true);
  };

  const changeCountryHandler = (
    event: SyntheticEvent<Element, Event>,
    newValue: CountryType | null
  ) => {
    if (newValue != null) {
      updateCountryState(newValue);
      setEditCountry(false);
      setAutoCompleteEditState(false);
      setopen(false);
    } else {
      const defaultAutoCompleteValue: CountryType = countries.filter(
        (a: CountryType) => a.symbol == DefaultCountryRegen
      )[0];
      updateCountryState(defaultAutoCompleteValue);
    }
  };
  return (
    <>
      {preload ? null : (
        <Autocomplete
          readOnly={readOnly}
          popupIcon={<KeyboardArrowDownIcon className={Style.popup_icon} />}
          className={`${className} ${Style.country_selector}`}
          id="country-select-demo"
          sx={autoCompleteEditState ? { width: "100%" } : { width: "auto" }}
          options={countries}
          autoHighlight
          open={open}
          onClose={() => {
            setEditCountry(false);
            setAutoCompleteEditState(false);
            setopen(false);
          }}
          onOpen={() => {
            setopen(true);
          }}
          filterOptions={createFilterOptions({
            matchFrom: "start",
            stringify: (option: CountryType) => option.name,
          })}
          getOptionLabel={(option: CountryType) => option.name}
          onChange={changeCountryHandler}
          renderOption={(
            props: React.HTMLAttributes<HTMLLIElement>,
            option: CountryType
          ) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                src={`${Flag20}${option.symbol.toLowerCase()}.png`}
                srcSet={`${Flag40}${option.symbol.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.name}
            </Box>
          )}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <>
              {autoCompleteEditState && !readOnly ? (
                <TextField
                  className={Style.dropdown}
                  {...params}
                  inputRef={inputRef}
                  inputProps={{
                    ...params.inputProps,
                    // disable autocomplete and autofill
                  }}
                />
              ) : (
                <div
                  onClick={!readOnly ? handleClickToOpenAutoComplete : () => {}}
                  className={Style.choose_country}
                >
                  <img
                    loading="lazy"
                    src={`${Flag20}${value?.symbol.toLowerCase()}.png`}
                    srcSet={`${Flag40}${value?.symbol.toLocaleLowerCase()}.png 2x`}
                    alt=""
                  />
                  <span>+{value?.code}</span>
                  <KeyboardArrowDownIcon className={Style.popup_icon} />
                  <div className={Style.country_new_display}>
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          PaperComponent={StyledAutocompletePaper}
        />
      )}
    </>
  );
};

export default CountrySelect;
