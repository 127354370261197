import React, { useEffect, useState } from "react";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../dashboardBody/DashboardBody";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { AppThunkDispatch } from "../../../../redux/Store";
import { GetUserDataAction } from "../../../../redux/slices/UserSlice";
import {
  GetUserId,
  GetUserTokenInfo,
} from "../../../../services/commonServices/CommonServices";
import BuildingList from "./buildingList/BuildingList";
import { GetUserBuildingDataAction } from "../../../../redux/slices/UserBuildingSlice";
import Loading from "../../../../tools/loading/Loading";
import { UserCredentialInfoViewModel } from "../../../../viewModels/api/Models";

const Buildings = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const userCredential = useSelector((state: IReducer) => state.user);
  const userBuilding = useSelector((state: IReducer) => state.building);
  const [, setUserCredentialInfo] = useState<UserCredentialInfoViewModel | null>(null);
  const [preload, setPreload] = useState(true);

  useEffect(() => {
    if (userCredential.userLogin) {
      setUserCredentialInfo(userCredential.user);
    } else {
      var userToken = GetUserTokenInfo();
      dispatch(GetUserDataAction(userToken?.userId ?? ""));
    }
  }, [userCredential]);

  useEffect(() => {
    if (userBuilding.fill == true) {
      setPreload(false);
    } else {
      var userId: string | undefined = GetUserId();
      dispatch(GetUserBuildingDataAction(userId ?? ""));
    }
  }, [userBuilding]);

  return (
    <DashboardContainer>
      {!preload ? (
        <>
          {userBuilding.building.length > 0 ? (
            <DashboardBody>
              <BuildingList items={userBuilding.building} />
            </DashboardBody>
          ) : (
            <Loading />
          )}
        </>
      ) : (
        <Loading />
      )}
    </DashboardContainer>
  );
};

export default Buildings;
