import { Grid } from "@mui/material";
import React, { useState } from "react";
import TextField from "../../../../tools/fields/textField/TextField";
import { useTranslation } from "react-i18next";
import AutoComplete from "../../../../tools/dropDown/autoComplete/AutoComplete";
import { ICity } from "../../../../viewModels/dataTypes/country/Country";

interface Props {
  provinces: ICity[];
  cities: ICity[];
  provinceChange: (province: ICity | null) => void;
  cityChange: (city: ICity | null) => void;
  buildingNameChange: (buildingName: string) => void;
  cityValue: ICity | null;
  provinceValue: ICity | null;
}
const BuildingInfo: React.FC<Props> = ({
  provinces,
  cities,
  provinceChange,
  cityChange,
  buildingNameChange,
  cityValue,
  provinceValue,
}) => {
  const [vla, setvla] = useState("");
  const { t: translate } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          onChange={buildingNameChange}
          label={translate("LABELS.BUILDING_NAME")}
          placeHolder={translate("PLACEHOLDER.BUILDING_NAME")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AutoComplete
          items={provinces}
          label={translate("LABELS.PROVINCE")}
          placeHolder={translate("PLACEHOLDER.PROVINCE")}
          getOptionLabel={(provinces: ICity) => provinces.province}
          getRenderOption={(provinces: ICity) => provinces.province}
          onChange={provinceChange}
          value={provinceValue}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <AutoComplete
          items={cities}
          label={translate("LABELS.CITY")}
          placeHolder={translate("PLACEHOLDER.CITY")}
          getOptionLabel={(cities: ICity) => cities.name}
          getRenderOption={(cities: ICity) => cities.name}
          onChange={cityChange}
          value={cityValue}
        />
      </Grid>
    </Grid>
  );
};

export default BuildingInfo;
