import React from "react";
import Style from "./Header.module.scss";
import { ArrowDown2, ArrowLeft2 } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/Floorins.svg";
interface Props {
  backButton?: boolean;
  backHandler?: () => void;
}
const Header: React.FC<Props> = ({ backButton = true, backHandler }) => {
  const navigate = useNavigate();
  return (
    <div className={Style.header}>
      <div>
        {backButton && (
          <span onClick={() => navigate(-1)}>
            <ArrowLeft2
              onClick={backHandler}
              size="22"
              color="var(--icon-gray-high)"
            />
          </span>
        )}
        <img src={Logo} alt="Floorins Logo" />
      </div>
      <a className={`${Style.menu_item}`}>
        EN
        <ArrowDown2 variant="TwoTone" size="18" color="var(--icon-gray-high)" />
      </a>
    </div>
  );
};

export default Header;
