import {
  Building,
  Category,
  Diagram,
  Headphone,
  MenuBoard,
  ReceiptItem,
  Setting2,
  Grid8,
  User,
} from "iconsax-react";
import { MenuItemsViewModel } from "../../../viewModels/dataTypes/tools/MenuItemsViewModel";
import { MenuIconSize } from "../../../Setting";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";

export const SuperAdminMenuItem = (
  buildingId: string
): MenuItemsViewModel[] => [
  {
    icon: <Category size={MenuIconSize} />,
    boldIcon: <Category size={MenuIconSize} variant="Bold" />,
    path: "/dashboard/dashboard",
    titr: "SHARED.MENU.DASHBOARD",
  },
  {
    icon: <Building size={MenuIconSize} />,
    boldIcon: <Building size={MenuIconSize} variant="Bold" />,
    path: "/dashboard/building",
    titr: "BUILDING.BUILDINGS",
    subMenu: [
      {
        icon: <Building size={MenuIconSize - 2} />,
        path: `/dashboard/building/info/${buildingId}`,
        titr: "BUILDING.BUILDING_INFO",
      },
      {
        icon: <Grid8 size={MenuIconSize - 2} />,
        path: `/dashboard/building/units/${buildingId}`,
        titr: "SHARED.MENU.UNITS",
      },
      {
        icon: <User size={MenuIconSize - 2} />,
        path: "/dashboard/building/users/" + buildingId,
        titr: "SHARED.MENU.USERS",
      },
      {
        icon: (
          <BuildOutlinedIcon
            sx={{
              width: MenuIconSize,
              stroke: "var(--icon-white)",
              strokeWidth: 1,
              marginLeft: "-1px",
            }}
          />
        ),
        path: "/dashboard/building/services/" + buildingId,
        titr: "SHARED.MENU.SERVICES",
      },
      {
        icon: <MenuBoard size={MenuIconSize - 2} />,
        path: "/dashboard/building/complaints/" + buildingId,
        titr: "SHARED.MENU.COMPLAINTS",
      },
      {
        icon: <ReceiptItem size={MenuIconSize - 2} />,
        path: "/dashboard/building/accounting/" + buildingId,
        titr: "SHARED.MENU.ACCOUNTING",
      },
      {
        icon: (
          <CampaignOutlinedIcon
            sx={{
              fontSize: MenuIconSize + 1,
              marginLeft: "-2px",
            }}
          />
        ),
        path: "/dashboard/building/announcements/" + buildingId,
        titr: "SHARED.MENU.ANNOUNCEMENTS",
      },
    ],
  },
  {
    icon: <Diagram size={MenuIconSize} />,
    boldIcon: <Diagram size={MenuIconSize} variant="Bold" />,
    path: "/dashboard/reports",
    titr: "SHARED.MENU.REPORTS",
  },
];

export const appSupportItem: MenuItemsViewModel[] = [
  {
    icon: <Setting2 size={MenuIconSize} />,
    boldIcon: <Setting2 size={MenuIconSize} variant="Bold" />,
    path: "/dashboard/setting",
    titr: "SHARED.MENU.SETTING",
  },
  {
    icon: <Headphone size={16} />,
    path: "/",
    titr: "SHARED.APP_SUPPORT",
    boldIcon: <Headphone size={16} variant="Bold" />,
  },
];
