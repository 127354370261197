import React from "react";
import Style from "./SwitchButton.module.scss";

interface Props {
  isDefault: boolean;
  onChange: (value: boolean) => void;
}
const SwitchButton: React.FC<Props> = ({ isDefault = true, onChange }) => {

  const changeSwitchButtonStateHandler = () => {
    onChange(!isDefault);
  };
  return (
    <div
      className={`${Style.switch_button} ${!isDefault ? Style.custom_mod : ""}`}
      onClick={changeSwitchButtonStateHandler}
    >
      <div className={`${Style.button}`}></div>
      <div className={`${Style.switch_button_text}`}>
        {isDefault ? "Set to all" : "Custom"}
      </div>
    </div>
  );
};

export default SwitchButton;
