import React, { useEffect, useState } from "react";
import { AnnouncementViewModel } from "../../../../../viewModels/dataTypes/announcement/AnnouncementViewModel";
import { DashboardBox } from "../../../../../tools/box/DashboardBox";
import DashboardTitle from "../../../../../tools/dashboardTitle/DashboardTitle";
import { MenuBoard } from "iconsax-react";
import Filter from "../../../../../tools/table/filter/Filter";
import FilterDropDownSelect from "../../../../../tools/dropDown/filterDropDownSelect/FilterDropDownSelect";
import FilterDropDownDate from "../../../../../tools/dropDown/filterDropDownDate/FilterDropDownDate";
import FilterDropDown from "../../../../../tools/dropDown/filterDropDown/FilterDropDown";
import Style from "./AnnouncementList.module.scss";
import { DateRangeType } from "../../../../../viewModels/dataTypes/DateRangeType";
import useWindowSize from "../../../../../hook/WindowSize";
import { useParams } from "react-router-dom";
import {
  FormatDateDefault,
  MediumDeviceTrigger,
  UltraSmallDeviceTrigger,
} from "../../../../../Setting";
import { useTranslation } from "react-i18next";
import { Dayjs } from "dayjs";
import { CalenderEnum } from "../../../../../viewModels/enums/CalenderEnum";
import { DateItems } from "../../../../../tools/dropDown/filterDropDownDate/DateItems";
import { FilterConditionsEnum } from "../../../../../viewModels/enums/FilterConditionsEnum";
import { IDropDown } from "../../../../../viewModels/dataTypes/tools/DropDownItems";
import { useDispatch, useSelector } from "react-redux";
import {
  IBuildingInfoReducer,
  IReducer,
} from "../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { FilterAnnouncements } from "./AnnouncementList.service";
import { GetBuildingInfoDataAction } from "../../../../../redux/slices/BuildingInfoSlice";
import { AppThunkDispatch } from "../../../../../redux/Store";
import AnnouncementItems from "./announcementItem/AnnouncementItems";
interface Props {
  annoncements: AnnouncementViewModel[];
}
const AnouncementList: React.FC<Props> = ({ annoncements }) => {
  const [buildingName, setBuildingName] = useState<string>("");
  const [selectedMode, setSelectedMode] = useState<string>("");
  const [announcementFilterList, setAnnouncementFilterList] = useState<
    AnnouncementViewModel[]
  >([]);
  const [searchText, setSearchText] = useState<string>("");
  const [isActiveCloseIcon, setIsActiveCloseIcon] = useState<boolean>(false);
  const [announcementList, setAnnouncementList] = useState<string | null>(null);
  const [checkedItemsLabel, setCheckedItemsLabel] = useState<string[]>([]);
  const [labelFilterLogic, setLabelFilterLogic] = useState<string>(
    FilterConditionsEnum.OR
  );
  const [labelDropDown, setLabelDropDown] = useState<IDropDown[]>([]);
  const windowsSize = useWindowSize();
  const { buildingId } = useParams<{
    buildingId: string;
  }>();
  const userBuildingInfo: IBuildingInfoReducer = useSelector(
    (state: IReducer) => state.buildingInfo
  );
  const dispatch = useDispatch<AppThunkDispatch>();

  const { t } = useTranslation();

  const [selectedDateRange, setSelectedDateRange] =
    useState<DateRangeType | null>(null);
  const labels = useSelector((state: IReducer) => state.announcementList);

  const isMediumToUltraSmallDevice: boolean =
    windowsSize.width <= MediumDeviceTrigger &&
    windowsSize.width > UltraSmallDeviceTrigger;

  useEffect(() => {
    const getBuildingInfo = async () => {
      if (userBuildingInfo.buildingInfo != null) {
        setBuildingName(userBuildingInfo.buildingInfo.buildingName);
      } else {
        await dispatch(GetBuildingInfoDataAction(buildingId ?? ""));
      }
    };
    getBuildingInfo();
  }, [userBuildingInfo, dispatch, buildingId]);
  const resetFilters = () => {
    setSearchText("");
    setAnnouncementList(null);
    setCheckedItemsLabel([]);
    setSelectedMode("");
    if (setCheckedItemsLabel.length > 0 || searchText.length > 0) {
      setLabelFilterLogic(FilterConditionsEnum.OR);
    }
  };
  useEffect(() => {
    const dataFilter = FilterAnnouncements(
      annoncements,
      announcementList ?? "",
      labelFilterLogic ?? "",
      searchText,
      selectedDateRange,
      selectedMode
    );

    setAnnouncementFilterList(dataFilter);
  }, [
    annoncements,
    announcementList,
    labelFilterLogic,
    searchText,
    selectedDateRange,
    selectedMode,
  ]);

  useEffect(() => {
    setIsActiveCloseIcon(
      checkedItemsLabel.length > 0 ||
        searchText.length > 0 ||
        selectedMode !== ""
    );
  }, [checkedItemsLabel.length, searchText.length, selectedMode]);
  // Handle date change
  const handleDateChange = (startDate: Dayjs, endDate: Dayjs) => {
    setSelectedDateRange({
      start: startDate.format(FormatDateDefault),
      end: endDate.format(FormatDateDefault),
    });
  };
  useEffect(() => {
    let _labels: IDropDown[] = [];
    const uniqueLabels = new Set<string>();
    labels?.annoncements?.forEach((item: AnnouncementViewModel) => {
      const label = item.label ?? "";
      if (!uniqueLabels.has(label)) {
        uniqueLabels.add(label);
        _labels.push({
          text: label,
          value: label,
        });
      }
    });

    setLabelDropDown(_labels);
  }, [labels]);
  return (
    <DashboardBox>
      <DashboardTitle
        icon={<MenuBoard className={Style.user_icon} />}
        label={`${buildingName} ${t("ANNOUNCEMENTS.ANNOUNCEMENT")}`}
      />
      <Filter
        onSearch={setSearchText}
        onClose={resetFilters}
        valueSearch={searchText}
        isActiveCloseIcon={isActiveCloseIcon}
        filterItemsDate={
          <>
            {windowsSize.width >= UltraSmallDeviceTrigger && (
              <div className={Style.container_date_desktop_filter}>
                {isMediumToUltraSmallDevice ? (
                  selectedMode && (
                    <FilterDropDownSelect
                      items={DateItems}
                      label={t("ANNOUNCEMENTS.TIME")}
                      onModeChange={setSelectedMode}
                      value={selectedMode}
                    />
                  )
                ) : (
                  <FilterDropDownSelect
                    items={DateItems}
                    label={t("ANNOUNCEMENTS.TIME")}
                    onModeChange={setSelectedMode}
                    value={selectedMode}
                  />
                )}
                {selectedMode && (
                  <FilterDropDownDate
                    className={Style.announcements_list_dropdown}
                    mode={selectedMode}
                    onDateChange={(start: Dayjs, end: Dayjs) =>
                      handleDateChange(start, end)
                    }
                  />
                )}
              </div>
            )}
          </>
        }
        filterItems={
          <>
            {windowsSize.width < MediumDeviceTrigger && (
              <>
                <p
                  className={`${Style.announcement_mobile_report} ${
                    selectedMode !== "" ? Style.active : ""
                  }`}
                  onClick={() =>
                    setSelectedMode(selectedMode !== "" ? "" : CalenderEnum.DAY)
                  }
                >
                  {t("ANNOUNCEMENTS.TIME")}
                </p>
              </>
            )}
            <FilterDropDown
              className={Style.announcements_list_dropdown}
              onChange={setAnnouncementList}
              onChangeConditionsLogic={setLabelFilterLogic}
              valueConditionsLogic={labelFilterLogic}
              items={labelDropDown}
              label={t("ANNOUNCEMENTS.LABELS")}
              checkedItems={checkedItemsLabel}
              setCheckedItems={setCheckedItemsLabel}
              showRadioButton={false}
            />
          </>
        }
      />
      {selectedMode && windowsSize.width < UltraSmallDeviceTrigger && (
        <div className={`${Style.container_filter_Date}`}>
          <FilterDropDownSelect
            items={DateItems}
            label={t("ANNOUNCEMENTS.TIME")}
            onModeChange={setSelectedMode}
            value={selectedMode}
          />
          {selectedMode && (
            <FilterDropDownDate
              className={Style.announcements_list_dropdown}
              mode={selectedMode}
              onDateChange={(start: Dayjs, end: Dayjs) =>
                handleDateChange(start, end)
              }
            />
          )}
        </div>
      )}
      <div className={Style.announcement_items_container}>
        <AnnouncementItems
          icon={"Add"}
          link={`/dashboard/building/AddAnnouncement/${buildingId}`}
          title={t("ANNOUNCEMENTS.ADD")}
          text=""
          colorTitle=""
          colorLabel=""
          addType={true}
        />
        {announcementFilterList.map((announcement) => (
          <AnnouncementItems
            key={announcement.id}
            number={`${announcement.id}`}
            link={`/dashboard/building/anouncements/${buildingId}/${announcement.id}`}
            title={announcement?.title ?? ""}
            text={announcement?.description ?? ""}
            colorTitle=""
            colorLabel={announcement?.label ?? ""}
            createdDateTime={announcement.createdDateTime}
          />
        ))}
      </div>
    </DashboardBox>
  );
};

export default AnouncementList;
