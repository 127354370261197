import React, { useEffect, useReducer, useState } from "react";
import {
  BuildingCommunicationViewModel,
  Complaint,
  ComplaintState,
  UserCredentialInfoViewModel,
} from "../../../../../../viewModels/api/Models";
import { ContentContainer } from "../../../../../../tools/container/customeContainer/DashboardContentContainer";
import Accordion from "../../../../../../tools/accordion/Accordion";
import ComplaintInfo from "./complaintInfo/ComplaintInfo";
import ComplaintDetails from "./complaintDetails/ComplaintDetails";
import { useTranslation } from "react-i18next";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import Button from "../../../../../../tools/button/Button";
import {
  ButtonState,
  ButtonTheme,
} from "../../../../../../viewModels/enums/ButtonEnum";
import Style from "./ComplaintInfoForm.module.scss";
import ComplaintComments from "./complaintComments/ComplaintComments";
import {
  ComplaintInfoReducer,
  InitialComplaintInfoState,
  ComplaintInfoActionType,
} from "./complaintInfo/ComplaintInfo.State";
import {
  ComplaintDetailsActionType,
  ComplaintDetailsReducer,
  InitialComplaintDetailsState,
} from "./complaintDetails/ComplaintDetails.State";
import {
  FloorinsDateTimeFormat,
  GetUserTokenInfo,
  IsNullString,
} from "../../../../../../services/commonServices/CommonServices";
import ImageBoxSlider from "../../../../../../tools/slider/imageBoxSlider/ImageBoxSlider";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { CancelAddService } from "../../../services/buildingServices/servicesComponent/addBuildingService/addBuildingServiceContainer/ServiceInfoContainerPopUp";
import Loading from "../../../../../../tools/loading/Loading";
import {
  GetComplaintStates,
  GetDropDownItem,
  UpdateComplaintService,
} from "./ComplaintInfo.Service";
import { ToastType } from "../../../../../../viewModels/enums/ToastType";
import { CreateToast } from "../../../../../../tools/toast/ToastService";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../../redux/Store";
import { IReducer } from "../../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { GetUserDataAction } from "../../../../../../redux/slices/UserSlice";
import ChangeState from "../../../../../../tools/dropDown/changeState/ChangeState";
import { IStateDropDown } from "../../../../../../viewModels/dataTypes/tools/DropDownItems";
import { GetBuildingComplaintsAction } from "../../../../../../redux/slices/ComplaintsSlice";
import { setHasUnsavedChanges } from "../../../../../../redux/slices/UnsavedChangesSlice";

interface Props {
  complaintInfo: Complaint;
}
const ComplaintInfoForm: React.FC<Props> = ({ complaintInfo }) => {
  //config
  const { t: translate } = useTranslation();
  const { buildingId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const complaintStatesInfo = GetComplaintStates();
  //states
  const userCredential = useSelector((state: IReducer) => state.user);
  const [mandatoryChanges, setMandatoryChanges] = useState<boolean>(false);
  const [complaintState, setComplaintState] = useState<IStateDropDown | null>(
    null
  );
  const [userCredentialInfo, setUserCredentialInfo] =
    useState<UserCredentialInfoViewModel | null>(null);

  const [openImageBox, setOpenImageBox] = useState<boolean>(false);
  const [currentSliderImage, setcurrentSliderImage] = useState<string>("");
  const [showCancelPopUp, setShowCancelPopUp] = useState(false);
  const [complaintComments, setComplaintComments] = useState<
    BuildingCommunicationViewModel[]
  >(complaintInfo.complaintComments ?? []);
  const [newComment, setNewComment] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.disable
  );
  const [complaintDetailsStates, complaintDetailsdispatch] = useReducer(
    ComplaintDetailsReducer,
    InitialComplaintDetailsState
  );

  const [complaintInfoState, complaintInfoDispatch] = useReducer(
    ComplaintInfoReducer,
    InitialComplaintInfoState
  );

  useEffect(() => {
    if (userCredential.userLogin) {
      setUserCredentialInfo(userCredential.user);
    } else {
      const userToken = GetUserTokenInfo();
      dispatch(GetUserDataAction(userToken?.userId ?? ""));
    }
  }, [userCredential]);

  useEffect(() => {
    if (complaintInfo) {
      setComplaintDetails();
      setComplaintInfo();
      const selectedItem = GetDropDownItem(
        complaintInfo.status ?? ComplaintState.New,
        complaintStatesInfo
      );

      setComplaintState(selectedItem);
    }
  }, [complaintInfo]);

  useEffect(() => {
    if (
      !IsNullString(newComment) ||
      complaintState?.value !== complaintInfo.status?.toString()
    ) {
      setMandatoryChanges(true);
      setButtonState(ButtonState.default);
    } else {
      setMandatoryChanges(false);
      setButtonState(ButtonState.disable);
    }
  }, [newComment, complaintState]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(mandatoryChanges));
  }, [mandatoryChanges]);

  const setComplaintDetails = () => {
    complaintDetailsdispatch({
      type: ComplaintDetailsActionType.ComplaintNumber,
      payload: complaintInfo.complaintNumber ?? "",
    });

    complaintDetailsdispatch({
      type: ComplaintDetailsActionType.ComplaintDate,
      payload: FloorinsDateTimeFormat(
        complaintInfo.createdDateTime?.toString() ?? ""
      ),
    });

    complaintDetailsdispatch({
      type: ComplaintDetailsActionType.LastUpdate,
      payload: FloorinsDateTimeFormat(
        complaintInfo.lastUpdateDateTime?.toString() ?? ""
      ),
    });
  };

  const setComplaintInfo = () => {
    complaintInfoDispatch({
      type: ComplaintInfoActionType.Subject,
      payload: complaintInfo.subject ?? "",
    });
    complaintInfoDispatch({
      type: ComplaintInfoActionType.ReportedBy,
      payload: complaintInfo.unitNumber ?? "",
    });
    complaintInfoDispatch({
      type: ComplaintInfoActionType.Description,
      payload: complaintInfo.description ?? translate("SHARED.NO_DESCRIPTION"),
    });
    complaintInfoDispatch({
      type: ComplaintInfoActionType.Attachments,
      payload: complaintInfo.complaintAttachment ?? [],
    });
  };

  const openAttachmentFile = (value: string) => {
    setcurrentSliderImage(value);
    setOpenImageBox(true);
  };

  const clsoeAttachmentFile = () => {
    setOpenImageBox(false);
  };

  const cancelEditComplaint = () => {
    if (buttonState === ButtonState.default) {
      setShowCancelPopUp(true);
    } else {
      navigate(-1);
    }
  };

  const updateComplaint = async () => {
    if (!loading) {
      setLoading(true);
      let comments = [...complaintComments];
      if (!IsNullString(newComment)) {
        comments = addComments();
      }
      complaintInfo.complaintComments = comments;
      complaintInfo.status = Number(complaintState?.value) as ComplaintState;

      const updateResult = await UpdateComplaintService(complaintInfo);

      if (updateResult.data) {
        CreateToast(ToastType.Success, translate("ALERT.EDIT_SUCCESS"));
        setComplaintComments(comments);
        dispatch(GetBuildingComplaintsAction(buildingId ?? ""));
        refreshPage();
        setLoading(false);
        dispatch(setHasUnsavedChanges(false));
        navigate(`/dashboard/building/complaints/${buildingId}`);
      } else {
        setLoading(false);
        CreateToast(ToastType.Error, translate("ERRORS.EDITION_FAILD"));
      }
    }
  };

  const addComments = () => {
    const comments = [...complaintComments];

    const newCommentInfo: BuildingCommunicationViewModel = {
      senderMessage: newComment,
      sendDateTime: new Date(),
      senderName: "Super Admin",
      sederAvatar: userCredentialInfo?.userAvatar,
      sederId: userCredentialInfo?.userId,
      isAdmin: true,
    };
    comments.push(newCommentInfo);
    comments.sort(
      (
        a: BuildingCommunicationViewModel,
        b: BuildingCommunicationViewModel
      ) => {
        // Handle undefined or null dates by treating them as the "smallest" value
        const dateA = a.sendDateTime ? new Date(a.sendDateTime).getTime() : 0;
        const dateB = b.sendDateTime ? new Date(b.sendDateTime).getTime() : 0;

        // Descending order: dateB - dateA
        return dateB - dateA;
      }
    );
    return comments;
  };

  const refreshPage = () => {
    setNewComment("");
    setButtonState(ButtonState.disable);
  };

  const changeComplaintStateHandler = (value: IStateDropDown | null) => {
    setComplaintState(value);
  };

  return (
    <ContentContainer>
      <div>
        <Accordion
          className={Style.complaint_info_container}
          title={translate("COMPLAINT.COMPLAINT_INFO")}
          icon={
            <DescriptionOutlinedIcon className={Style.complaint_box_icon} />
          }
          accordionIsOpen={false}
          callToActionNode={
            <>
              <ChangeState
                data={complaintStatesInfo}
                onChange={changeComplaintStateHandler}
                value={complaintState}
              />
            </>
          }
          content={
            <>
              <ComplaintInfo
                sliderOnClick={openAttachmentFile}
                complaintInfo={complaintInfoState}
              />
              {openImageBox && (
                <ImageBoxSlider
                  onClose={clsoeAttachmentFile}
                  imagesList={complaintInfoState.attachments}
                  currentImage={currentSliderImage}
                />
              )}
            </>
          }
        />
        <ComplaintDetails detailsStates={complaintDetailsStates} />
        <Accordion
          title={translate("SHARED.COMMENTS")}
          icon={<NotesOutlinedIcon className={Style.complaint_box_icon} />}
          accordionIsOpen={false}
          content={
            <div className={Style.comments}>
              <ComplaintComments
                onChange={setNewComment}
                value={newComment}
                comments={complaintComments}
              />
            </div>
          }
        />
      </div>
      <div className={Style.complaint_buttons}>
        <Button
          theme={ButtonTheme.primaryReverse}
          text={translate("BUTTON.BACK")}
          clickMethod={cancelEditComplaint}
        />
        <Button
          text={translate("BUTTON.SAVE")}
          clickMethod={updateComplaint}
          theme={ButtonTheme.primary}
          state={buttonState}
        />
        {loading && <Loading />}
      </div>
      {showCancelPopUp && (
        <CancelAddService cancelButton={() => setShowCancelPopUp(false)} />
      )}
    </ContentContainer>
  );
};

export default ComplaintInfoForm;
