import React from "react";
import MessagePopUp from "../../../../../../tools/popup/MessagePopUp";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../tools/button/Button";
import { InfoCircle } from "iconsax-react";
import { SizeType } from "../../../../../../viewModels/dataTypes/tools/ListComponentType";
import Style from "./AddAdminForm.module.scss";
import { useNavigate } from "react-router-dom";
import { ButtonTheme } from "../../../../../../viewModels/enums/ButtonEnum";
import { useDispatch } from "react-redux";
import { AppThunkDispatch } from "../../../../../../redux/Store";
import { setHasUnsavedChanges } from "../../../../../../redux/slices/UnsavedChangesSlice";

export const ExistAdminPopup: React.FC = () => {
  const { t: translate } = useTranslation();

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <MessagePopUp
      icon={<InfoCircle />}
      title={translate("ADMIN.EXIST_ADMIN_TITR")}
      description={translate("ADMIN.EXIST_ADMIN_DESC")}
      className={Style.exist_admin_popup}
      buttons={
        <Button
          className={Style.ok_button}
          text={translate("BUTTON.OK")}
          size={SizeType.small}
          clickMethod={refreshPage}
        />
      }
    />
  );
};

interface Props {
  cancelButton: () => void;
}
export const CancelAddAdmin: React.FC<Props> = ({ cancelButton }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();

  const lastPage = () => {
    dispatch(setHasUnsavedChanges(false));
    navigate(-1);
  };

  return (
    <MessagePopUp
      icon={<InfoCircle />}
      title={translate("SHARED.LEAVE_PAGE")}
      description={translate("ALERT.LEAVE_PAGE")}
      buttons={
        <>
          <Button
            className={Style.cancel_button}
            text={translate("BUTTON.CANCEL")}
            size={SizeType.small}
            theme={ButtonTheme.primaryReverse}
            clickMethod={cancelButton}
          />
          <Button
            className={Style.cancel_button}
            text={translate("BUTTON.DISCARD")}
            size={SizeType.small}
            clickMethod={lastPage}
            theme={ButtonTheme.primary}
          />
        </>
      }
    />
  );
};
