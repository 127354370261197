export const CheckPhoenValidation = (number: string) => {
  const mobileNumberPattern: RegExp = /^(?!\d*?[a-zA-Z])\d{9,12}$/;

  return mobileNumberPattern.test(number);
};
export const CheckIsNumber = (number: string) => {
  const numericPattern = /^[0-9]*$/;
  return numericPattern.test(number);
};
export const NumberRegex = (value: string) => {
  value = value.replace(/[^0-9.]/g, "");
  return value;
};
export const PasswordRegex = (Password: string): boolean => {
  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})"
  );
  return strongRegex.test(Password);
};

export const EmailRegex = (email: string): boolean => {
  const emailPattern =
    /^(?![.-])([A-Za-z0-9!#$%&'*+/=?^_`{|}~](?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~._-]{0,62}[A-Za-z0-9!#$%&'*+/=?^_`{|}~])?)(?<![.-]{2,})@([A-Za-z0-9](?!.*--)(?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z]{2,}$/;

  if (email.length > 254) return false;
  return emailPattern.test(email);
};
export const CheckLengthtRegex = (value: string, min: number = 3) => {
  if (value.length < min) {
    return false;
  } else {
    return true;
  }
};
export const ZipCodeRegex = (value: string) => {
  var zipCodePattern = new RegExp(/^[0-9]{5,9}$/);
  return zipCodePattern.test(value);
};
