import { useState, useRef } from "react";

type Position = "top" | "bottom";

const useDropdownPosition = () => {
  const [menuPosition, setMenuPosition] = useState<Position>("bottom");
  const selectRef = useRef<HTMLDivElement | null>(null);

  const calculatePosition = () => {
    const rect: DOMRect | undefined =
      selectRef.current?.getBoundingClientRect();
    const viewHeight: number = window.innerHeight;

    if (rect) {
      const spaceBelow: number = viewHeight - rect.bottom;
      const spaceAbove: number = rect.top;

      if (spaceBelow < 250 && spaceAbove > spaceBelow) {
        setMenuPosition("top");
      } else {
        setMenuPosition("bottom");
      }
    }
  };

  return { selectRef, menuPosition, calculatePosition };
};
export default useDropdownPosition;
