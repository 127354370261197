import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IServiceResult } from "../../viewModels/api/Api";
import { IBuildingServiceCategoriesReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";
import { ServiceCategory } from "../../viewModels/api/Models";
import { GetServicesCategoryItems } from "../../components/dashboard/dashboardContents/services/serviceCategory/servicesCategoryList/ServicesCategoryList.Service";

const initialState = {
  categories: [],
  loading: true,
  fill: false,
  err: "",

} as IBuildingServiceCategoriesReducer;

const BuildingServiceCategoriesSlice = createSlice({
  name: "buildingServiceCategories",
  initialState,
  reducers: {},
  extraReducers: (builder : ActionReducerMapBuilder<IBuildingServiceCategoriesReducer>) => {
    builder.addCase(GetBuildingServiceCategories.pending, (State) => {
      State.loading = true;
    });
    builder.addCase(
      GetBuildingServiceCategories.fulfilled,
      (State, action: PayloadAction<ServiceCategory[]>) => {
        State.categories = action.payload;
        State.loading = false;
        State.fill = true;
      }
    );
    builder.addCase(GetBuildingServiceCategories.rejected, (State) => {
      State.loading = false;
      State.err = "Loading Faild";
    });
  },
});

export const GetBuildingServiceCategories = createAsyncThunk(
  "GetBuildingServiceCategories",
  async (buildingId: number) => {
    try {
      const BuildingServiceCategories: IServiceResult<
        ServiceCategory[] | null
      > = await GetServicesCategoryItems(buildingId);
      if (BuildingServiceCategories.data) {
        return BuildingServiceCategories.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);

export default BuildingServiceCategoriesSlice.reducer;
