import React from "react";
import Button from "../../../tools/button/Button";
import { useTranslation } from "react-i18next";
import BeforeDashboardForm from "../BeforeDashboardForm";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Style from "./ChangePassword.module.scss";

const AfterChangePassword = () => {

  const { t: translate } = useTranslation();
  
  return (
    <>
      <BeforeDashboardForm
        backButton={false}
        body={
          <>
            <div className={Style.successfully_change}>
              <CheckCircleIcon />
              <h3>{translate("ALERT.CHANGE_PASSWORD_SUCCESSFULLY")}</h3>
              <p>{translate("ALERT.CHANGE_PASSWORD_SUCCESSFULLY_DESC")}</p>
            </div>
            <div className={`mt-3`}>
              <Button text={translate("BUTTON.BACK_TO_LOGIN")} link="/login" />
            </div>
          </>
        }
      />
    </>
  );
};

export default AfterChangePassword;
