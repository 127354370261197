import React from "react";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../dashboardBody/DashboardBody";
import BuildingUserInfo from "./userInfo/BuildingUserInfo";

const BuildingUserPage = () => {
  return (
    <DashboardContainer>
      <DashboardBody>
        <BuildingUserInfo />
      </DashboardBody>
    </DashboardContainer>
  );
};

export default BuildingUserPage;
