import React from "react";
import { IStepperItem } from "../../../viewModels/dataTypes/tools/Stepper";
import Style from "./MobileStepper.module.scss";
interface CustomStepperProps {
  activeStep: number;
  steps: IStepperItem[];
}

const MobileStepper: React.FC<CustomStepperProps> = ({ steps, activeStep }) => {
  return (
    <div className={Style.custom_stepper}>
      {steps.map((step: IStepperItem, index: number) => (
        <>
          <div key={index} className={`${Style.step}`}>
            <div
              className={`${Style.step_title} ${
                index <= activeStep ? Style.active : ""
              }`}
            >
              {index + 1 + "." + step.titr}
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default MobileStepper;
