import React, { useState } from "react";
import Style from "./MainHeader.module.scss";
import Logo from "../../../assets/images/logo3.png";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { ArrowDown2, ArrowRight, Global, HambergerMenu } from "iconsax-react";
import useWindowSize from "../../../hook/WindowSize";
import { SmallDeviceTrigger } from "../../../Setting";

interface Props {
  login?: boolean;
}

const MainHeadeer: React.FC<Props> = () => {
  const [openmenu, setopenmenu] = useState<boolean>(false);

  const { width: screenSize } = useWindowSize();

  const { t: translate } = useTranslation();

  return (
    <>
      <header
        className={
          !openmenu ? `${Style.header}` : `${Style.header} ${Style.open_menu}`
        }
      >
        <>
          <div className={Style.header_logo}>
            <img src={Logo} />
          </div>
          {screenSize > SmallDeviceTrigger ? (
            <ul>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? `${Style.menu_item} ${Style.active}`
                      : `${Style.menu_item}`
                  }
                >
                  {translate("SHARED.HOME")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/aboutus"
                  className={({ isActive }) =>
                    isActive
                      ? `${Style.menu_item} ${Style.active}`
                      : `${Style.menu_item}`
                  }
                >
                  {translate("SHARED.ABOUT_US")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/network"
                  className={({ isActive }) =>
                    isActive
                      ? `${Style.menu_item} ${Style.active}`
                      : `${Style.menu_item}`
                  }
                >
                  {translate("SHARED.NETWORK")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/product"
                  className={({ isActive }) =>
                    isActive
                      ? `${Style.menu_item} ${Style.active}`
                      : `${Style.menu_item}`
                  }
                >
                  {translate("SHARED.PRODUCT")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/faq"
                  className={({ isActive }) =>
                    isActive
                      ? `${Style.menu_item} ${Style.active}`
                      : `${Style.menu_item}`
                  }
                >
                  {translate("SHARED.FAQ")}
                </NavLink>
              </li>
              <li>
                <a className={`${Style.menu_item}`}>
                  <Global size="22" color="var(--icon-gray-medium)" /> EN
                  <ArrowDown2 size="16" color="var(--icon-gray-medium)" />
                </a>
              </li>
            </ul>
          ) : (
            <div className={Style.mobile_menu}>
              <a onClick={() => setopenmenu(!openmenu)}>
                {openmenu === false ? (
                  <HambergerMenu size="32" color="var(--icon-gray-medium)" />
                ) : (
                  <ArrowRight size="32" color="var(--icon-gray-medium)" />
                )}
              </a>
              <div>
                {!openmenu ? (
                  <Global size="32" color="var(--icon-gray-medium)" />
                ) : null}
              </div>
            </div>
          )}
        </>
      </header>
      {screenSize < SmallDeviceTrigger && (
        <>
          <nav
            className={
              !openmenu
                ? `${Style.nav_bar} ${Style.nav_deactivce}`
                : `${Style.nav_bar}`
            }
          >
            <ul>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? `${Style.menu_item} ${Style.active}`
                      : `${Style.menu_item}`
                  }
                >
                  {translate("SHARED.HOME")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/aboutus"
                  className={({ isActive }) =>
                    isActive
                      ? `${Style.menu_item} ${Style.active}`
                      : `${Style.menu_item}`
                  }
                >
                  {translate("SHARED.ABOUT_US")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/network"
                  className={({ isActive }) =>
                    isActive
                      ? `${Style.menu_item} ${Style.active}`
                      : `${Style.menu_item}`
                  }
                >
                  {translate("SHARED.NETWORK")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/product"
                  className={({ isActive }) =>
                    isActive
                      ? `${Style.menu_item} ${Style.active}`
                      : `${Style.menu_item}`
                  }
                >
                  {translate("SHARED.PRODUCR")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/faq"
                  className={({ isActive }) =>
                    isActive
                      ? `${Style.menu_item} ${Style.active}`
                      : `${Style.menu_item}`
                  }
                >
                  {translate("SHARED.FAQ")}
                </NavLink>
              </li>
              <li>
                <a className={`${Style.menu_item}`}>
                  <Global size="22" color="var(--gray-700)" /> EN
                  <ArrowDown2 size="16" color="var(--gray-700)" />
                </a>
              </li>
            </ul>
          </nav>
        </>
      )}
    </>
  );
};

export default MainHeadeer;
