import { FormControl, MenuItem, Select } from "@mui/material";
import styled from "styled-components";

export const StyledListItemButton = styled.div<{
  hovered?: boolean;
  borderColor: string;
}>`
  border: 1px solid;
  background-color: ${({ hovered, borderColor }) =>
    hovered ? borderColor : "transparent"};
  border-color: ${({ borderColor }) => borderColor};
  transition: background-color 0.3s;
`;

export const DotIcon = styled.div<{ hovered?: boolean; borderColor: string }>`
  background-color: ${({ hovered, borderColor }) =>
    hovered ? "var(--text-secondary)" : borderColor} !important;
  transition: background-color 0.3s;
`;

export const StateLabel = styled.span`
  color: inherit;
`;

export const ExpandMoreIcon = styled.div<{ hovered: boolean }>`
  color: ${({ hovered }) =>
    hovered ? "var(--text-secondary)" : "var(--text-gray-high)"};
  margin-top: 0.4rem;
  // margin-left: 0.5rem;
  transition: color 0.3s;
`;

export const CustomMenuItem = styled(MenuItem)(() => ({
  "&": {
    minHeight: "28px !important",
    color: "var(--text-gray-high) !important",
  },
  "&.Mui-selected": {
    backgroundColor: "var(--bg-gray-low) !important",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "var(--bg-gray-low) !important",
  },
  "&:hover": {
    backgroundColor: "var(--bg-gray-low) !important",
  },
}));

export const CustomSelect = styled(Select)<{
  hovered?: boolean;
  borderColor: string;
}>`
  & {
    padding-top: 0 !important;
  }
  && {
    border: 1px solid ${({ borderColor }) => borderColor};
    background-color: ${({ hovered, borderColor }) =>
      hovered ? borderColor : "transparent"};
    transition: background-color 0.3s;

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiSelect-select {
      padding-left: 0;
      padding-right: 0 !important;
      color: ${({ hovered }) =>
        hovered ? "var(--text-secondary)" : "var(--text-gray-high)"};
    }
  }
`;

export const CustomFormControl = styled(FormControl)`
  padding: 0;
  margin: 0;
`;
