import React, { ReactElement, useEffect, useState } from "react";
import Style from "./TextField.module.scss";
import { FieldState } from "../../../viewModels/enums/PublicEnum";
import { MessageType } from "../../../viewModels/enums/MessageType";
import { MessageHandler } from "../../messagesHandler/MessagesHandler";
import { SizeType } from "../../../viewModels/dataTypes/tools/ListComponentType";
import useSyncInputValue from "../../../hook/SyncInputValue";

interface Props {
  icon?: ReactElement;
  label?: string;
  errorMessage?: string | null;
  placeHolder?: string;
  value?: string;
  onChange?: (param: string) => void;
  onBlur?: () => void;
  type?: string;
  condotion?: (value: string) => boolean;
  readOnly?: boolean;
  fieldState?: FieldState;
  messageType?: MessageType;
  size?: SizeType;
  requiredField?: boolean;
  maxLength?: number;
  className?: string;
}

const TextField: React.FC<Props> = ({
  icon,
  label,
  errorMessage,
  onChange,
  onBlur,
  placeHolder,
  value,
  type = "text",
  readOnly = false,
  fieldState = FieldState.Default,
  messageType = MessageType.Error,
  size = SizeType.large,
  requiredField = false,
  maxLength = 100,
  className,
}) => {
  const [_fieldState, setFieldState] = useState<FieldState>(FieldState.Default);
  const [changeLabelColor, setChangeLabelColor] = useState<boolean>(false);
  const [handleChange] = useSyncInputValue(value, onChange);

  useEffect(() => {
    setFieldState(fieldState);
  }, [fieldState]);

  useEffect(() => {
    setChangeLabelColor(value != null && value.length > 0);
  }, [value]);

  const handleBlur = () => {
    setFieldState(
      _fieldState === FieldState.Error ? FieldState.Error : FieldState.Default
    );
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <div className={Style.floor_field}>
      {errorMessage?.length === 0 || errorMessage == null ? (
        <label className={`${changeLabelColor ? Style.fill_field : ""}`}>
          {icon}
          <span
            style={{
              color: _fieldState === FieldState.Error ? "var(--text-red)" : "",
            }}
            className={Style.label}
          >
            {label}
            {requiredField && " *"}
          </span>
        </label>
      ) : (
        <MessageHandler type={messageType} message={errorMessage ?? ""} />
      )}
      <div
        className={`${Style.field} ${
          size === SizeType.small ? Style.small_field : ""
        } ${_fieldState === FieldState.Error ? Style.error : ""} ${
          readOnly ? Style.readonly_field : ""
        }`}
      >
        <input
          className={`${className}`}
          autoCorrect="off"
          autoCapitalize="off"
          value={value}
          type={type}
          min={0}
          onChange={handleChange}
          placeholder={
            errorMessage?.length === 0 || errorMessage == null
              ? placeHolder
              : ""
          }
          readOnly={readOnly}
          onBlur={handleBlur}
          maxLength={maxLength}
        />
      </div>
    </div>
  );
};

export default TextField;
