import React from "react";
import Style from "./ProfileInfo.module.scss";
import {
  CheckNullText,
  MakeAvatarWithName,
} from "../../../../services/commonServices/CommonServices";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserCredentialInfoViewModel } from "../../../../viewModels/api/Models";

interface Props {
  userInfo: UserCredentialInfoViewModel | null;
}
const ProfileInfo: React.FC<Props> = ({ userInfo }) => {
  const { t: translate } = useTranslation();
  return (
    <Link to={""} className={Style.profile_info}>
      <div className={Style.avatar}>
        {userInfo?.userAvatar != null ? (
          <img src={userInfo?.userAvatar} />
        ) : (
          <div>
            {userInfo?.companyName != null
              ? MakeAvatarWithName(userInfo?.companyName)
              : MakeAvatarWithName(
                  userInfo?.firstName ?? "" + userInfo?.lastName ?? ""
                )}
          </div>
        )}
      </div>
      <div className={Style.profile_name}>
        <h3>
          {userInfo?.companyName != null
            ? userInfo?.companyName
            : CheckNullText(userInfo?.firstName ?? "") +
              " " +
              CheckNullText(userInfo?.lastName ?? "")}
        </h3>
        <h4>{translate("AUTHENTICATION.MANAGE_PROFILE")}</h4>
      </div>
    </Link>
  );
};

export default ProfileInfo;
