export const ContainsSearchTerm = <T>(item: T, searchTerm: string): boolean => {
  for (const key in item) {
    if (Object.prototype.hasOwnProperty.call(item, key)) {
      const value = item[key as keyof T];

      if (typeof value === "string" || typeof value === "number") {
        if (value.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
          return true;
        }
      }
    }
  }
  return false;
};
