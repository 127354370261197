import React from "react";
import ServiceAddSession from "../serviceInfoTime/ServiceAddSession";
import {
  DaySchedule,
  SessionServiceViewModel,
  DayOfWeek,
} from "../../../../../../../../viewModels/api/Models";
import CounterAvatar from "../../../../../../../../tools/counterAvatar/CounterAvatar";
import { GetWeekDayName } from "../../../../../../../../services/commonServices/CommonServices";
import ContainerStyle from "../ServiceInfoComponent.module.scss";
import ServiceInfoTime from "../serviceInfoTime/ServiceInfoTime";

interface Props {
  daySchedule: DaySchedule[];
  onChnageSessionInfo: (day: DayOfWeek[], value: SessionServiceViewModel) => void;
  addSessionHandler: (days: DayOfWeek[]) => void;
  deleteSession: (day: DayOfWeek[], sessionId: number) => void;
}

const ServiceSessionInfo: React.FC<Props> = ({
  daySchedule,
  onChnageSessionInfo,
  addSessionHandler,
  deleteSession,
}) => {
  return (
    <>
      {daySchedule.length > 0 ? (
        daySchedule.map((mainItem: DaySchedule, dayScheduleIndex: number) => (
          <div>
            {daySchedule.length > 1 && (
              <CounterAvatar
                className={ContainerStyle.service_box_container}
                counterValue={GetWeekDayName(mainItem.days[0])}
                counterText={GetWeekDayName(mainItem.days[0])}
              />
            )}

            {mainItem.sessionInfo?.map(
              (item: SessionServiceViewModel, sessionInfoIndex: number) => (
                <ServiceInfoTime
                  index={
                    daySchedule[dayScheduleIndex].sessionInfo?.length === 1
                      ? 0
                      : sessionInfoIndex + 1
                  }
                  selectedDay={mainItem.days ?? []}
                  sessionInfo={item}
                  onChange={onChnageSessionInfo}
                  onDelete={deleteSession}
                />
              )
            )}
            <ServiceAddSession
              onClick={() => addSessionHandler(mainItem.days ?? [])}
            />
          </div>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default ServiceSessionInfo;
