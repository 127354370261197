import { BodyOfGetRequest } from "../../Setting";
import {
  BaseUrl,
  GetComplaintInfoRequest,
  GetComplaints,
  UpdateComplaintsRequest,
  UpdateComplaintsState,
} from "../../text/Api";
import { ApiResponse } from "../../viewModels/api/Api";
import {
  Complaint,
  UpdateComplaintStateViewModel,
} from "../../viewModels/api/Models";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CallApi } from "../CallApi";
import { DefaultApiHeader } from "./ApiHeaders";

export const GetComplaintsList = async (buildingId: string) => {
  const Url: string = `${BaseUrl}${GetComplaints}/${buildingId}`;

  let Result: ApiResponse<Complaint[]> = await CallApi(
    Url,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Get
  );

  return Result;
};

export const UpdateComplaintState = async (
  stateInfo: UpdateComplaintStateViewModel
) => {
  const Url: string = `${BaseUrl}${UpdateComplaintsState}`;
  const raw = JSON.stringify(stateInfo);

  let Result: ApiResponse<UpdateComplaintStateViewModel> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Put
  );

  return Result;
};

export const UpdateComplaint = async (
  info: Complaint
) => {
  const Url: string = `${BaseUrl}${UpdateComplaintsRequest}`;
  const raw = JSON.stringify(info);

  let Result: ApiResponse<boolean> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Put
  );

  return Result;
};

export const GetComplaintInfo = async (complaintId: number) => {
  const Url: string = `${BaseUrl}${GetComplaintInfoRequest}${complaintId}`;
  let Result: ApiResponse<Complaint> = await CallApi(
    Url,
    BodyOfGetRequest,
    DefaultApiHeader,
    ApiMethod.Get
  );

  return Result;
};
