import React, { useEffect, useReducer, useRef, useState } from "react";
import Style from "./AddServiceForm.module.scss";
import ServiceInfoTitle from "../../../serviceInfoComponent/serviceInfoTitle/ServiceInfoTitle";
import ServiceInfoFee from "../../../serviceInfoComponent/serviceInfoFee/ServiceInfoFee";
import ServiceInfoDescription from "../../../serviceInfoComponent/serviceInfoDescription/ServiceInfoDescription";
import Accordion from "../../../../../../../../../tools/accordion/Accordion";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { useTranslation } from "react-i18next";
import ServiceDate from "../../../serviceInfoComponent/serviceInfoDate/ServiceDate";
import {
  ComponentScrollTop,
  GetWeekDays,
} from "../../../../../../../../../services/commonServices/CommonServices";
import { WeekDaysType } from "../../../../../../../../../viewModels/dataTypes/tools/WeekDaysType";
import SwitchButton from "../../../../../../../../../tools/switchButton/SwitchButton";
import Button from "../../../../../../../../../tools/button/Button";
import {
  ButtonState,
  ButtonTheme,
} from "../../../../../../../../../viewModels/enums/ButtonEnum";
import {
  BuildingServices,
  DaySchedule,
  ServiceCategory,
  SessionServiceViewModel,
  SessionType,
  TimeInterval,
  DayOfWeek,
} from "../../../../../../../../../viewModels/api/Models";
import {
  AddBuildingServiceInfo,
  AddSessoionToDaySchedule,
  MakeDaySchedules,
  UpdateDaySchedules,
} from "./AddServiceForm.Service";
import ServiceSessionInfo from "../../../serviceInfoComponent/serviceSessionInfo/ServiceSessionInfo";
import Loading from "../../../../../../../../../tools/loading/Loading";
import {
  InitialServiceDateState,
  ServiceDateActionType,
  ServiceDateReducer,
} from "../../../serviceInfoComponent/serviceInfoDate/ServiceDate.State";
import { useNavigate, useParams } from "react-router-dom";
import { CreateToast } from "../../../../../../../../../tools/toast/ToastService";
import { ToastType } from "../../../../../../../../../viewModels/enums/ToastType";
import {
  IBuildingServicesReducer,
  IReducer,
} from "../../../../../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../../../../../redux/Store";
import { GetBuildingServices } from "../../../../../../../../../redux/slices/BuildingServices";
import { CancelAddService } from "../ServiceInfoContainerPopUp";
import { IDurationRepeat } from "../../../../../../../../../viewModels/dataTypes/tools/IDurationRepeat";
import { NumberRegex } from "../../../../../../../../../services/Regex";
import { setHasUnsavedChanges } from "../../../../../../../../../redux/slices/UnsavedChangesSlice";

interface Props {
  serviceCategory: ServiceCategory;
}
const AddServiceForm: React.FC<Props> = ({ serviceCategory }) => {
  //states
  const [serviceName, setServiceName] = useState<string>("");
  const [existServiceName, setExistServiceName] = useState<boolean>(false);
  const [serviceNameErrorMessage, setServiceNameErrorMessage] =
    useState<string>("");
  const [serviceIconId, setServiceIconId] = useState<number>(0);
  const [serviceFee, setServiceFee] = useState<string>("");
  const [serviceDescription, setServiceDescription] = useState<string>("");
  const [weekDays, setWeekDays] = useState<WeekDaysType[]>([]);
  const [activeDays, setActiveDays] = useState<WeekDaysType[]>([]);
  const [setToAllMode, setSetToAllMode] = useState<boolean>(true);
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.disable
  );
  const [inProgressJobs, setInProgressJobs] = useState<number>(0);
  const [showCancelPopUp, setShowCancelPopUp] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(
    InitialServiceDateState.startDate
  );
  const [endDate, setEndDate] = useState<Date | null>(
    InitialServiceDateState.endDate
  );
  const [durationRepeatValue, setDurationRepeatValue] =
    useState<IDurationRepeat>(InitialServiceDateState.durationRepeat);
  const [loading, setLoading] = useState<boolean>(false);
  const [daySchedule, setDaySchedule] = useState<DaySchedule[]>([]);

  const buildingServices: IBuildingServicesReducer = useSelector(
    (state: IReducer) => state.buildingServices
  );
  //configs
  const divRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { buildingId } = useParams();
  const { t: translate } = useTranslation();
  const [state, dispatch] = useReducer(
    ServiceDateReducer,
    InitialServiceDateState
  );
  const reduxDispatch = useDispatch<AppThunkDispatch>();

  //effects
  useEffect(() => {
    setServiceNameErrorMessage("");
    setButtonState(
      serviceName.trim().length > 0 ? ButtonState.default : ButtonState.disable
    );
    const findServiceName = buildingServices.services?.find(
      (item: BuildingServices) =>
        item.title?.trim().toLocaleLowerCase() ===
        serviceName.trim().toLocaleLowerCase()
    );
    setExistServiceName(findServiceName ? true : false);
  }, [serviceName]);

  useEffect(() => {
    const days = GetWeekDays();
    setWeekDays(days);
    loadBuildingServices();
  }, []);

  useEffect(() => {
    if (weekDays.length > 0) {
      const activeDays = weekDays.filter(
        (item: WeekDaysType) => item.isActive == true
      );
      setActiveDays(activeDays);
    }
  }, [weekDays]);

  useEffect(() => {
    if (daySchedule.length > 0) {
      const result = UpdateDaySchedules(activeDays, setToAllMode, daySchedule);
      setDaySchedule(result);
    } else {
      const result = MakeDaySchedules(activeDays, setToAllMode);
      setDaySchedule(result);
    }
  }, [activeDays]);

  useEffect(() => {
    const result = MakeDaySchedules(activeDays, setToAllMode);
    setDaySchedule(result);
  }, [setToAllMode]);

  useEffect(() => {
    setStartDate(state.startDate);
    setEndDate(state.endDate);
    setDurationRepeatValue(state.durationRepeat);
  }, [state]);

  useEffect(() => {
    setServiceIconId(Number(serviceCategory?.iconId));
  }, [serviceCategory]);

  //functions
  const refreshForm = () => {
    setServiceName("");
    setServiceIconId(serviceCategory.iconId ?? 0);
    dispatch({ type: ServiceDateActionType.StartDate, payload: new Date() });
    dispatch({ type: ServiceDateActionType.EndDate, payload: new Date() });
    dispatch({
      type: ServiceDateActionType.DurationRepeat,
      payload: { durationType: TimeInterval.Weekly, value: 1 },
    });
    setServiceFee("");
    setServiceDescription("");
    setActiveDays([]);
    const days = GetWeekDays();
    setWeekDays(days);
    setSetToAllMode(true);
    const result = MakeDaySchedules(activeDays, setToAllMode);
    setDaySchedule(result);
  };

  const addSessionHandler = (days: DayOfWeek[]) => {
    // Create a new array by spreading the existing sessions and adding new sessions
    const result = AddSessoionToDaySchedule(daySchedule, days);
    // Set the new array as the state
    setDaySchedule(result);
  };

  const loadBuildingServices = () => {
    if (buildingServices.fill && buildingServices.services) {
      if (
        buildingServices.services.length > 0 &&
        buildingServices.services[0].buildingId === Number(buildingId) &&
        buildingServices.services[0].serviceCategoryId === serviceCategory.id
      ) {
        return;
      } else {
        reduxDispatch(
          GetBuildingServices({
            buildingId: Number(buildingId),
            categoryId: Number(serviceCategory.id),
          })
        );
      }
    } else {
      reduxDispatch(
        GetBuildingServices({
          buildingId: Number(buildingId),
          categoryId: Number(serviceCategory.id),
        })
      );
    }
  };

  useEffect(() => {
    if (buttonState === ButtonState.disable) {
      reduxDispatch(setHasUnsavedChanges(false));
    } else {
      reduxDispatch(setHasUnsavedChanges(true));
    }
  }, [buttonState]);

  const cancelAddService = () => {
    if (buttonState === ButtonState.default) {
      setShowCancelPopUp(true);
    } else {
      navigate(-1);
    }
  };

  const addServiceHandler = async () => {
    if (inProgressJobs === 0) {
      setLoading(true);
      setInProgressJobs(inProgressJobs + 1);
      const newServiceInfo: BuildingServices = {
        buildingId: Number(buildingId),
        description: serviceDescription,
        fee: Number(serviceFee),
        iconId: serviceIconId,
        endDate: endDate ?? new Date(),
        repeatInterval: durationRepeatValue.value,
        repeatIntervalType: durationRepeatValue.durationType,
        serviceCategoryId: serviceCategory.id,
        serviceSessions: daySchedule,
        title: serviceName,
        startDate: startDate ?? new Date(),
        serviceSessionsType: setToAllMode
          ? SessionType.Default
          : SessionType.Customize,
      };
      if (existServiceName) {
        ComponentScrollTop(divRef);
        setServiceNameErrorMessage(
          translate("ERRORS.DUPLICATE_SERVICE_NAME") ?? ""
        );
        setLoading(false);
        setInProgressJobs(0);
        setButtonState(ButtonState.default);
        return;
      }
      const addServiceResult = await AddBuildingServiceInfo(newServiceInfo);
      if (addServiceResult.data) {
        CreateToast(
          ToastType.Success,
          translate("ALERT.ADD_SUCCESSFULLY", {
            value: translate("SHARED.SERVICE"),
          })
        );
        setLoading(false);
        setInProgressJobs(0);
        reduxDispatch(
          GetBuildingServices({
            buildingId: Number(buildingId),
            categoryId: Number(serviceCategory.id),
          })
        );
        refreshForm();
      } else {
        CreateToast(
          ToastType.Error,
          translate("ERRORS.ADDED_FAILD", {
            value: translate("SHARED.SERVICE"),
          })
        );
        setLoading(false);
        setInProgressJobs(0);
      }
    }
  };

  const onChangeSession = (
    day: DayOfWeek[],
    value: SessionServiceViewModel
  ) => {
    if (!value) return;
    const updatedDaySchedule = daySchedule.map((mainItem: DaySchedule) => {
      if (mainItem.days === day) {
        return {
          ...mainItem,
          sessionInfo: mainItem.sessionInfo?.map(
            (item: SessionServiceViewModel) =>
              item.id === value.id
                ? {
                    ...item,
                    endTime: value.endTime,
                    startTime: value.startTime,
                    capacity: value.capacity,
                    durationSession: value.durationSession,
                  }
                : item
          ),
        };
      }
      return mainItem;
    });
    setDaySchedule(updatedDaySchedule);
  };

  const onChangeServiceTitleInfoHandler = (iconId: number, title: string) => {
    setServiceName(title);
    setServiceIconId(iconId);
  };

  const deleteSessionHandler = (day: DayOfWeek[], sessionId: number) => {
    const updatedDaySchedule = daySchedule.map((mainItem: DaySchedule) => {
      if (mainItem.days === day) {
        return {
          ...mainItem,
          sessionInfo: mainItem.sessionInfo?.filter(
            (item: SessionServiceViewModel) => item.id !== sessionId
          ),
        };
      }
      return mainItem;
    });
    setDaySchedule(updatedDaySchedule);
  };

  const changePriceHandelr = (value: string) => {
    setServiceFee(NumberRegex(value));
  };
  return (
    <div className={Style.add_service_form} ref={divRef}>
      <ServiceInfoTitle
        serviceName={serviceName}
        onChange={onChangeServiceTitleInfoHandler}
        serviceIconId={serviceIconId}
        errorMessage={serviceNameErrorMessage}
      />
      <Accordion
        title={translate("TITLES.SERVICE_DATE")}
        icon={<EventOutlinedIcon className={Style.accordion_icon} />}
        accordionIsOpen={false}
        callToActionNode={<></>}
        content={
          <>
            <ServiceDate
              weekDayInfo={weekDays}
              onChangeWeekDayInfo={setWeekDays}
              changeDispatch={dispatch}
              states={state}
            />
          </>
        }
      />
      <Accordion
        title={translate("TITLES.SERVICE_TIME")}
        icon={<AccessTimeOutlinedIcon className={Style.accordion_icon} />}
        accordionIsOpen={false}
        callToActionNode={
          <SwitchButton onChange={setSetToAllMode} isDefault={setToAllMode} />
        }
        content={
          <>
            <ServiceSessionInfo
              addSessionHandler={addSessionHandler}
              daySchedule={daySchedule}
              onChnageSessionInfo={onChangeSession}
              deleteSession={deleteSessionHandler}
            />
          </>
        }
      />
      <ServiceInfoFee onChange={changePriceHandelr} value={serviceFee} />
      <ServiceInfoDescription
        onChange={setServiceDescription}
        value={serviceDescription}
      />
      <div className={Style.add_service_buttons}>
        <Button
          theme={ButtonTheme.primaryReverse}
          text={translate("BUTTON.BACK")}
          clickMethod={cancelAddService}
        />
        <Button
          text={translate("BUTTON.ADD")}
          clickMethod={addServiceHandler}
          state={buttonState}
          theme={ButtonTheme.primary}
        />
      </div>
      {loading && <Loading />}
      {showCancelPopUp && (
        <CancelAddService cancelButton={() => setShowCancelPopUp(false)} />
      )}
    </div>
  );
};

export default AddServiceForm;
