export enum FieldState {
  Default = 0,
  Success = 1,
  Error = 2,
}

export enum ContactVerificationState {
  Empty = 0,
  Checking = 1,
  Verified = 2,
  Blocked = 3,
  Fill = 4
}
