import {
  ActionReducerMapBuilder,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { IServiceResult } from "../../viewModels/api/Api";
import { IBuildingAnnouncementReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";
import { AnnouncementViewModel } from "../../viewModels/dataTypes/announcement/AnnouncementViewModel";
import { GetAnnouncementItems } from "../../components/dashboard/dashboardContents/anouncement/announcementList/AnnouncementList.service";

const initialState = {
  annoncements: [],
  loading: true,
  fill: false,
  error: "",
} as IBuildingAnnouncementReducer;

const AnnouncementSlice = createSlice({
  name: "anouncementList",
  initialState,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<IBuildingAnnouncementReducer>
  ) => {
    builder.addCase(
      GetAnnouncementAction.fulfilled,
      (state, action: PayloadAction<AnnouncementViewModel[]>) => {
        state.annoncements = action.payload;
        state.loading = false;
        state.fill = true;
      }
    );
    builder.addCase(
      GetAnnouncementAction.pending,
      (state: IBuildingAnnouncementReducer) => {
        state.loading = true;
      }
    );
    builder.addCase(
      GetAnnouncementAction.rejected,
      (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.err = action.payload;
      }
    );
  },
});

export const GetAnnouncementAction = createAsyncThunk<
  AnnouncementViewModel[],
  string,
  { rejectValue: string }
>("GetAnnouncementAction", async (buildingId: string, { rejectWithValue }) => {
  try {
    const BuildingAnnouncements: IServiceResult<AnnouncementViewModel[]> =
      await GetAnnouncementItems(buildingId);       
    if (BuildingAnnouncements.data && BuildingAnnouncements.data.length > 0) {
      return BuildingAnnouncements.data;
    } else {
      return rejectWithValue("");
    }
  } catch (err) {
    return rejectWithValue("");
  }
});

export default AnnouncementSlice.reducer;
