import { GetApplicationFeatures } from "../../../../../../services/api/Feature";
import {
  AddAdministrator,
  CheckExistAdmin,
} from "../../../../../../services/api/User";
import {
  GetAdminRoles,
  GetUserPermissions,
} from "../../../../../../services/api/UserRole";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../viewModels/api/Api";
import { AddAdministratorViewModel } from "../../../../../../viewModels/api/Models";
import { IFeature } from "../../../../../../viewModels/dataTypes/IFeature";
import { IDropDown } from "../../../../../../viewModels/dataTypes/tools/DropDownItems";
import { IAdministratorInfo } from "../../../../../../viewModels/dataTypes/user/IAdministrator";
import {
  IUserPermissions,
  IUserRole,
} from "../../../../../../viewModels/dataTypes/user/User";
import { ResponseCode } from "../../../../../../viewModels/enums/ResponseCode";

export const GetAdminRolesService = async () => {
  var resultModel: IServiceResult<IDropDown[]> = {
    message: "",
  };
  const combinedResponse: IDropDown[] = [];
  var result: ApiResponse<IUserRole[]> = await GetAdminRoles();
  if (result.responseStatus == ResponseCode.Success) {
    result.data?.map((item: IUserRole) => {
      combinedResponse.push({
        text: item.roleName,
        value: item.id.toString(),
      });
    });
    resultModel.data = combinedResponse;
  }
  return resultModel;
};

export const CheckExistAdminService = async (
  userId: string,
  buildingId: string
) => {
  var resultModel: IServiceResult<IAdministratorInfo[]> = {
    message: "",
  };
  var result: ApiResponse<IAdministratorInfo[]> = await CheckExistAdmin(
    userId,
    buildingId
  );
  resultModel.data = result.data;

  return resultModel;
};

export const GetApplicationFeaturesService = async () => {
  var resultModel: IServiceResult<IDropDown[]> = {
    message: "",
  };
  const combinedResponse: IDropDown[] = [];
  var result: ApiResponse<IFeature[]> = await GetApplicationFeatures();
  if (result.responseStatus == ResponseCode.Success) {
    result.data?.map((item: IFeature) => {
      combinedResponse.push({
        text: item.title,
        value: item.id.toString(),
      });
    });
    resultModel.data = combinedResponse;
  }

  return resultModel;
};

export const GetUserPermissionsService = async (userRoleIds: string) => {
  var resultModel: IServiceResult<IDropDown[]> = {
    message: "",
  };
  const combinedResponse: IDropDown[] = [];
  var result: ApiResponse<IUserPermissions[]> = await GetUserPermissions(
    userRoleIds
  );
  if (result.responseStatus == ResponseCode.Success) {
    result.data?.map((item: IUserPermissions) => {
      combinedResponse.push({
        text: item.featureTitle,
        value: item.featureId.toString(),
      });
    });
    resultModel.data = combinedResponse;
  }

  return resultModel;
};

export const AddAdministratorService = async (
  adminInfo: AddAdministratorViewModel
) => {
  var resultModel: IServiceResult<boolean> = {
    message: "",
    data: false,
  };
  var result: ApiResponse<boolean> = await AddAdministrator(adminInfo);
  if (result.responseStatus == ResponseCode.Success) {
    resultModel.data = result.data;
  }

  return resultModel;
};
