import React, { useEffect, useState } from "react";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../dashboardBody/DashboardBody";
import UsersList from "./usersList/UsersList";
import {
  IBuildingUsersReducer,
  IReducer,
} from "../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../redux/Store";
import { GetBuildingUsersAction } from "../../../../redux/slices/BuildingUsersSlice";
import { useParams } from "react-router-dom";

const Users = () => {
  const { buildingId } = useParams();

  const buildingUsersInfo: IBuildingUsersReducer = useSelector(
    (state: IReducer) => state.buildingUsers
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const [preload, setPreload] = useState<boolean>(true);

  useEffect(() => {
    const getBuildingUsers = async () => {
      if (buildingUsersInfo.fill) {
        setPreload(false);
      } else {
        dispatch(GetBuildingUsersAction(buildingId ?? ""));
      }
    };
    getBuildingUsers();
  }, [buildingUsersInfo]);

  return (
    <DashboardContainer>
      <DashboardBody>
        {!preload ? <UsersList users={buildingUsersInfo.users} /> : <></>}
      </DashboardBody>
    </DashboardContainer>
  );
};

export default Users;
