import { TickCircle } from "iconsax-react";
import React from "react";
import Style from "./ConfirmComponent.module.scss";

interface Props {
  confirm: boolean;
}

const ConfirmComponent: React.FC<Props> = ({ confirm }) => {
  return (
    <div className={Style.confirm_state}>
      {confirm ? (
        <TickCircle size="28" color="var(--icon-green-high)" variant="Bold" />
      ) : (
        <TickCircle size="28" color="var(--icon-disable-low)" variant="Bold" />
      )}
    </div>
  );
};

export default ConfirmComponent;
