import React, { ReactNode, useEffect, useRef, useState } from "react";
import Style from "./FilterItems.module.scss";
import CustomExpandMoreIcon from "../../../icons/CustomeExpandMoreIcon";
import useWindowSize from "../../../../hook/WindowSize";
import { MediumDeviceTrigger } from "../../../../Setting";
import { useTranslation } from "react-i18next";
interface Props {
  filterItems: ReactNode;
  filterItemsDate?: ReactNode;
}

const FilterItems: React.FC<Props> = ({ filterItems, filterItemsDate }) => {
  const [open, setOpen] = useState<boolean>(false);
  const windowSize = useWindowSize();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        // Logic to handle click outside of the div
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuRef = useRef<HTMLDivElement>(null);
  const { t: translate } = useTranslation();
  return (
    <>
      <div className={Style.tablet_filter_date}>{filterItemsDate}</div>
      <div ref={menuRef} className={Style.mobile_filter}>
        {windowSize.width <= MediumDeviceTrigger ? (
          <div
            className={Style.mobile_filter_label}
            onClick={() => setOpen(!open)}
          >
            {translate("DASHBOARD.FILTER")}
            <CustomExpandMoreIcon open={open} />
          </div>
        ) : (
          <></>
        )}
        <div
          className={`${Style.filter_items} ${
            open || windowSize.width > MediumDeviceTrigger
              ? Style.open_filter
              : ""
          }`}
        >
          {filterItems}
        </div>
      </div>
    </>
  );
};

export default FilterItems;
