import { ITabelCellSize } from "./tools/TabelCellSize";

export interface DataTableItemType {
  id?: number | string;
}

export interface HeadCellsType<T> {
  id: keyof T;
  label: string;
  align: AlignmentEnum | undefined;
  sortable: boolean;
  specialComponent: React.ComponentType<any> | null; // Accepts any component
  specialComponentProps?: (keyof T)[];
  cellSize?: ITabelCellSize;
  isLink?: boolean;
}

export enum AlignmentEnum {
  Center = "center",
  Left = "left",
  Right = "right",
  Inherit = "inherit",
  Justify = "justify",
}
