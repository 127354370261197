import { TickCircle } from "iconsax-react";
import React from "react";
import { useTranslation } from "react-i18next";
import Style from "./FirstLogin.module.scss";

interface Props{
    isFirstLogin: boolean;
}
const FirstLogin : React.FC<Props>= ({isFirstLogin}) => {
  const { t: translate } = useTranslation();
  return (
    <div className={`${Style.first_login} ${isFirstLogin ? Style.isChecked : ""}`}>
      <TickCircle variant="Bold" />
      {translate("AUTHENTICATION.FIRST_LOGIN")}
    </div>
  );
};

export default FirstLogin;
