import {
  CheckPhoenValidation,
  EmailRegex,
} from "../../../../../../services/Regex";
import { CreateUnitResidents } from "../../../../../../services/api/Unit";
import { DeleteResident, GetUnitResident } from "../../../../../../services/api/User";
import {
  ApiResponse,
  IServiceResult,
} from "../../../../../../viewModels/api/Api";
import { CreateUnitResidentsViewModel } from "../../../../../../viewModels/api/Models";
import { GetUnitResidentViewModel } from "../../../../../../viewModels/dataTypes/building/IUnitInfo";
import {
  IResident,
} from "../../../../../../viewModels/dataTypes/user/IResident";
import { FieldState } from "../../../../../../viewModels/enums/PublicEnum";
import { ResponseCode } from "../../../../../../viewModels/enums/ResponseCode";

export const GetUnitResidentsService = async (
  unitInfo: GetUnitResidentViewModel
) => {
  var resultModel: IServiceResult<IResident[]> = {
    message: "",
  };
  var result: ApiResponse<IResident[]> = await GetUnitResident(unitInfo);
  if (result.responseStatus == ResponseCode.Success) {
    resultModel.data = result.data;
  }

  return resultModel;
};

export const CreateUnitResidentsService = async (
  residentInfo: CreateUnitResidentsViewModel
) => {
  var resultModel: IServiceResult<boolean> = {
    message: "",
  };

  var result: ApiResponse<boolean> = await CreateUnitResidents(residentInfo);
  if (result.responseStatus == ResponseCode.Success) {
    resultModel.data = result.data;
  }

  return resultModel;
};

export const FindResidentInfo = (values: IResident[], isPrimary: boolean) => {
  return values.filter(
    (resident: IResident) => resident.isPrimary === isPrimary
  )[0];
};

export const CheckPhoneNumberValidation = (
  setErrorMessage: (errorMessage: string) => void,
  setFieldState: (fieldState: FieldState) => void,
  phoneNumber: string
) => {
  const phoneNumberValidationResult: boolean =
    CheckPhoenValidation(phoneNumber);
  if (!phoneNumberValidationResult) {
    setErrorMessage("ERRORS.INVALID_PHONENUMBER");
    setFieldState(FieldState.Error);
    return false;
  } else {
    setFieldState(FieldState.Default);
    return true;
  }
};

export const CheckEmailValidation = (
  setErrorMessage: (errorMessage: string) => void,
  setFieldState: (fieldState: FieldState) => void,
  email: string
) => {
  const emailValidationResult: boolean = EmailRegex(email);
  if (!emailValidationResult) {
    setErrorMessage("ERRORS.INVALID_EMAIL");
    setFieldState(FieldState.Error);
    return false;
  } else {
    setFieldState(FieldState.Default);
    return true;
  }
};

export const DeleteResidentService = async (
  residentId: number
) => {
  var resultModel: IServiceResult<boolean> = {
    message: "",
  };

  var result: ApiResponse<boolean> = await DeleteResident(residentId.toString());
  if (result.responseStatus == ResponseCode.Success) {
    resultModel.data = result.data;
  }

  return resultModel;
};
