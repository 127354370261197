import {
  ActionReducerMapBuilder,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { IUserBuildingReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";
import { GetUserBuilding } from "../../services/api/Building";
import { IUserBuilding } from "../../viewModels/dataTypes/building/UserBuildingViewModel";

const initialState = {
  building: [],
  loading: true,
  fill: false,
  error: "",
} as IUserBuildingReducer;

const BuildingSlice = createSlice({
  name: "userBuilding",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<IUserBuildingReducer>) => {
    builder.addCase(GetUserBuildingDataAction.pending, (State) => {
      State.loading = true;
    });
    builder.addCase(
      GetUserBuildingDataAction.fulfilled,
      (State, action: PayloadAction<IUserBuilding[]>) => {
        State.building = action.payload;
        State.loading = false;
        State.fill = true;
      }
    );
    builder.addCase(GetUserBuildingDataAction.rejected, (State) => {
      State.loading = false;
      State.err = "Loading Faild";
    });
  },
});
export const GetUserBuildingDataAction = createAsyncThunk(
  "GetUserBuildingDataAction",
  async (id: string) => {
    try {
      const userBuilding: IUserBuilding[] = await GetUserBuilding(id);
      return userBuilding;
    } catch (err) {
      return [];
    }
  }
);

export default BuildingSlice.reducer;
