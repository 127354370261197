import styled from "styled-components";

export const DashboardContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  width: 100%;
  padding: 2rem;
  min-height: 100vh;
  height: 100%;
  position: relative;
  @media (max-width: 900px) {
    padding: 0;
  }
  @media screen and (max-width: 1100px), screen and (max-height: 900px) {
    padding: 1.75rem;
  }
  @media screen and (max-width: 900px) {
    padding: 0 !important;
    padding-left: 0 !important;
  }
  @media (max-width: 900px) {
    background-color: var(--bg-secondary);
  }
  @media (min-width: 15000px) {
    padding-left: 4rem;
    padding-right: 4em;
  }
  @media (min-width: 1600px) {
    padding-left: 6rem;
    padding-right: 6rem;
  }
`;
