import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICountryReducer } from "../../viewModels/reduxInterfaces/ReduxInterfaces";
import { GetAllCountriesAction } from "../../services/api/GeneralApi";
import { CountryType } from "../../viewModels/dataTypes/country/Country";

const initialState = {
  countries: [],
  loading: true,
  err: "",
  fill: false,
} as ICountryReducer;
const CountrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetCountriesDataAction.pending, (State) => {
      State.loading = true;
    });
    builder.addCase(
      GetCountriesDataAction.fulfilled,
      (State, action: PayloadAction<CountryType[]>) => {
        State.countries = action.payload;
        State.loading = false;
        State.fill = true;
      }
    );
    builder.addCase(GetCountriesDataAction.rejected, (State) => {
      State.loading = false;
      State.err = "Loading Faild";
    });
  },
});

export const GetCountriesDataAction = createAsyncThunk(
  "GetCountriesDataAction",
  async () => {
    try {
      const TheCountries: CountryType[] | null = await GetAllCountriesAction();
      if (TheCountries) {
        return TheCountries;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);

export default CountrySlice.reducer;
