import React from "react";
import Style from "./FilterDropDownDate.module.scss";
import DatePickerContainer from "./DatePickerContainer";
import { Dayjs } from "dayjs";
import "./CustomDatePickerStyles.css";

interface Props {
  className?: string;
  mode: string;
  onDateChange: (start: Dayjs, end: Dayjs) => void;
}

const FilterDropDownDate: React.FC<Props> = ({
  className,
  mode,
  onDateChange,
}) => {
  return (
    <div className={`${Style.mobile_dropdown} ${className}`}>
      <DatePickerContainer mode={mode} onDateChange={onDateChange} />
    </div>
  );
};

export default FilterDropDownDate;
