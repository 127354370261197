import React from "react";
import { PricingTypeEnum } from "../../../../viewModels/enums/PricingType";
import { useTranslation } from "react-i18next";
import Style from "./CurrentPlan.module.scss";
interface Props {
  planType: string;
  planImage?: string;
}

const CurrentPlan: React.FC<Props> = ({ planType, planImage }) => {

  const { t: translate } = useTranslation();
  return (
    <>
      {planType != PricingTypeEnum.Trial ? (
        <div className={Style.current_plan}>
          <img src={planImage} />
          <h4>{planType + " " + translate("PLANS.PLAN")}</h4>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CurrentPlan;
