import React, { useEffect } from "react";
import Style from "./HeroSection.module.scss";
import { Container } from "../../../tools/container/customeContainer/Container";
import MainHeadeer from "../../header/mainHeader/MainHeadeer";
import { Grid } from "@mui/material";
import { SecurityUser, User } from "iconsax-react";
import Button from "../../../tools/button/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SmallDeviceTrigger } from "../../../Setting";
import useWindowSize from "../../../hook/WindowSize";

const HeroSections: React.FC = () => {
  const windowSize = useWindowSize();

  useEffect(() => {
    localStorage.removeItem("UserValidation");
  }, []);
  const { t: translate } = useTranslation();

  return (
    <div className={`${Style.hero_slide}`}>
      <div>
        <Container top="0">
          <MainHeadeer />
          <Grid container spacing={2}>
            <Grid item lg={6} md={7} sm={6} xs={100}>
              <div className={`${Style.hrero_title}`}>
                <h1>Lorem ipsum dolor sit amet, consectetur adipiscing</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              {windowSize.width > SmallDeviceTrigger ? (
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <Link
                      to="./signup"
                      className={`${Style.user_role_card}`}
                      target="_blank"
                    >
                      <span>
                        <SecurityUser size="26" color="var(--icon-primary)" />
                        {translate("SHARED.GET_START")}
                      </span>
                      <p>
                        Lorem ipsum dolor sit ametLorem ipsum dolor sit amet
                      </p>
                    </Link>
                  </Grid>
                  <Grid item md={6}>
                    <Link
                      to="./login"
                      className={`${Style.user_role_card}`}
                      target="_blank"
                    >
                      <span>
                        <User size="26" color="var(--icon-primary)" />
                        {translate("AUTHENTICATION.LOGIN")}
                      </span>
                      <p>
                        Lorem ipsum dolor sit ametLorem ipsum dolor sit amet
                      </p>
                    </Link>
                  </Grid>
                </Grid>
              ) : (
                <div className={Style.userrole_btn}>
                  <Link to="./signup" target="_blank">
                    <Button text={translate("SHARED.GET_START")} />
                  </Link>
                  <Link to="./login" target="_blank">
                    <Button text={translate("AUTHENTICATION.LOGIN")} />
                  </Link>
                </div>
              )}
            </Grid>
            <Grid item lg={6} md={5} sm={6} xs={100}>
              <div className={Style.role_details}>
                <div>
                  <h3>{translate("SHARED.ROLE_DETAILS")}</h3>
                  <ul>
                    <li>
                      Lorem ipsum dolor sit ametLorem ipsum dolor sit amet
                    </li>
                    <li>
                      Lorem ipsum dolor sit ametLorem ipsum dolor sit amet
                    </li>
                    <li>
                      Lorem ipsum dolor sit ametLorem ipsum dolor sit amet
                    </li>
                    <li>
                      Lorem ipsum dolor sit ametLorem ipsum dolor sit amet
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default HeroSections;
