import React, { useState } from "react";
import { MenuItemsViewModel } from "../../../../viewModels/dataTypes/tools/MenuItemsViewModel";
import Style from "./MenuItem.module.scss";
import MenuItem from "./MenuItem";

interface Props {
  items: MenuItemsViewModel[];
}

const MenuList: React.FC<Props> = ({ items }) => {
  const [selectedItemPath, setSelectedItemPath] = useState<string>("");

  const handleItemClick = (path: string) => {
    setSelectedItemPath(path);
  };

  return (
    <ul className={Style.menu_items}>
      {items.map((item: MenuItemsViewModel, index: number) => (
        <MenuItem
          key={index}
          item={item}
          onItemClick={handleItemClick}
          selectedItemPath={selectedItemPath}
        />
      ))}
    </ul>
  );
};

export default MenuList;
