import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../tools/button/Button";
import PasswordField from "../../../tools/fields/passwordField/PasswordField";
import Style from "./CheckPassword.module.scss";
import { useDispatch } from "react-redux";
import { AppThunkDispatch } from "../../../redux/Store";
import { CheckUserValidation } from "../../../services/commonServices/CommonServices";
import Loading from "../../../tools/loading/Loading";
import {
  Localstorage_UserToken,
  Localstorage_UserValidation,
} from "../../../text/LocalStorageValues";
import { useTranslation } from "react-i18next";
import { CheckUserPasswordService } from "./CheckPassword.Service";
import { IServiceResult } from "../../../viewModels/api/Api";
import { UserTokenModel } from "../../../viewModels/dataTypes/registration/TokenViewModel";
import { UserContactValidationModel } from "../../../viewModels/dataTypes/Cookie";
import BeforeDashboardForm from "../BeforeDashboardForm";
import { ButtonState } from "../../../viewModels/enums/ButtonEnum";
import { GetUserBuildingDataAction } from "../../../redux/slices/UserBuildingSlice";
import { FieldState } from "../../../viewModels/enums/PublicEnum";
import { TwoFactorAuthViewModel } from "../../../viewModels/api/Models";

const CheckPassword = () => {
  const [messageContent, setMessageContent] = useState<string>("");
  const [fieldState, setFieldState] = useState<FieldState>(FieldState.Default);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const [receiverValidation, setreceiverValidation] =
    useState<UserContactValidationModel>();

  useEffect(() => {
    if (!localStorage.getItem(Localstorage_UserValidation)) {
      navigate("/");
    } else {
      var userValidation = CheckUserValidation();
      if (userValidation.data != null) {
        setreceiverValidation(userValidation.data);
      } else {
        navigate("/");
      }
    }
  }, []);

  const [password, setPassword] = useState<string>("");

  const checkPassword = async () => {
    if (receiverValidation != undefined) {
      setLoading(true);
      let userinfo: TwoFactorAuthViewModel = {
        password: password,
        userContact:
          receiverValidation.userCountry != null
            ? receiverValidation.userCountry.code +
              receiverValidation.userContact
            : receiverValidation.userContact,
      };

      var checkPasswordResult: IServiceResult<UserTokenModel> =
        await CheckUserPasswordService(userinfo);
      if (checkPasswordResult.data != null) {
        localStorage.setItem(
          Localstorage_UserToken,
          JSON.stringify(checkPasswordResult.data)
        );
        localStorage.removeItem(Localstorage_UserValidation);
        dispatch(GetUserBuildingDataAction(checkPasswordResult.data.userId));
        navigate("/dashboard/dashboard");
      } else {
        setMessageContent(checkPasswordResult.message);
        setLoading(false);
        setFieldState(FieldState.Error);
      }
    } else {
      setMessageContent("ERRORS.OPERATION_FAILD");
      setLoading(false);
      setFieldState(FieldState.Error);
    }
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
    setMessageContent("");
    setFieldState(FieldState.Default);
  };

  const { t: translate } = useTranslation();
  return (
    <>
      <BeforeDashboardForm
        title={translate("AUTHENTICATION.LOGIN")}
        description={translate("AUTHENTICATION.ENTER_PASSWORD")}
        body={
          <>
            <div>
              <PasswordField
                errorMessage={messageContent}
                label={translate("AUTHENTICATION.PASSWORD")}
                onChange={onChangePassword}
                placeholder={translate("PLACEHOLDER.ENTER_PASSWORD")}
                fieldState={fieldState}
              />
              <div className={Style.forgot_password}>
                <Link to={"/login/forgetpassword"}>
                  {translate("AUTHENTICATION.FORGOT_PASSWORD")}?
                </Link>
              </div>
              <div className={`mt-3`}>
                {password.length > 0 ? (
                  <Button
                    text={translate("BUTTON.NEXT")}
                    clickMethod={checkPassword}
                  />
                ) : (
                  <Button
                    state={ButtonState.disable}
                    text={translate("BUTTON.NEXT")}
                  />
                )}
              </div>
            </div>
            {loading && <Loading />}
          </>
        }
      />
    </>
  );
};

export default CheckPassword;
