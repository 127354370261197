import React from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

interface DownloadButtonProps {
  fileUrl: string;
  fileName: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  fileUrl,
  fileName,
}) => {
  function handleAnchorClick(event : React.MouseEvent<HTMLAnchorElement>) {
    event.stopPropagation(); // Prevents the event from bubbling up to the parent div
}
  return (
    <a onClick={handleAnchorClick} href={fileUrl} download={fileName} target="_blank">
      <FileUploadOutlinedIcon />
    </a>
  );
};

export default DownloadButton;
