import { ApiResponse } from "../../viewModels/api/Api";
import { CallApi } from "../CallApi";
import { BaseUrl, FileUploadRequest } from "../../text/Api";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";

var myFileUploadHeaders = new Headers();

export const UploadFile = async (file: File) => {
  const Url: string = BaseUrl + FileUploadRequest;
  var formdata = new FormData();
  formdata.append("File", file, file.name);

  var Result: ApiResponse<null>= await CallApi(
    Url,
    formdata,
    myFileUploadHeaders,
    ApiMethod.Post
  );
  return Result;
};