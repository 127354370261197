import React from "react";
import Style from "./ServiceAddSession.module.scss";
import { useTranslation } from "react-i18next";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

interface Props {
  onClick: () => void;
}
const ServiceAddSession : React.FC<Props>= ({onClick}) => {
  const { t: translate } = useTranslation();
  return (
    <div className={Style.add_session_description}>
      <p>{translate("SERVICE.ADD_SESSION_DESCRIPTION")}</p>
      <a onClick={onClick}>
        <AddCircleOutlineOutlinedIcon />
        {translate("BUTTON.ADD_SESSION")}
      </a>
    </div>
  );
};

export default ServiceAddSession;
