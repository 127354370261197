import React, { useState } from "react";
import { DeletePopUp } from "../../../../addUser/addResidentForm/AddResidentPopup";
import Style from "./DeleteResidebt.module.scss";
import { Trash } from "iconsax-react";
import { IServiceResult } from "../../../../../../../../viewModels/api/Api";
import { DeleteResidentService } from "../../../../addUser/addResidentForm/AddResident.Service";
import { CreateToast } from "../../../../../../../../tools/toast/ToastService";
import { ToastType } from "../../../../../../../../viewModels/enums/ToastType";
import { useTranslation } from "react-i18next";
import Loading from "../../../../../../../../tools/loading/Loading";
import useBodyScrollLock from "../../../../../../../../hook/BodyScrollLock";

interface Props {
  isPrimary: boolean;
  userRoleName: string;
  residentId: number;
  deleteHandler: (residentId: number, residentType: string) => void;
}
const DeleteResident: React.FC<Props> = ({
  deleteHandler,
  isPrimary,
  userRoleName,
  residentId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progressing, setProgressing] = useState(false);
  const { t: translate } = useTranslation();

  const deleteResidentHandler = async (
    residentId: number | null,
    isPrimary: boolean,
    residentType: string
  ) => {
    if (residentId) {
      setLoading(true);
      setProgressing(true);
      const deleteResult: IServiceResult<boolean> = await DeleteResidentService(
        residentId
      );
      if (deleteResult.data) {
        setIsOpen(false);
        setLoading(false);
        setProgressing(false);
        deleteHandler(
          residentId,
          isPrimary
            ? residentType
            : translate("RESIDENT.CO_RESIDENT", { value: residentType })
        );
      } else {
        CreateToast(
          ToastType.Error,
          translate("ERRORS.DELETED_FAILD", {
            value: isPrimary
              ? residentType
              : translate("RESIDENT.CO_RESIDENT", { value: residentType }),
          })
        );
        setLoading(false);
        setProgressing(false);
      }
    }
  };

  const showPopUpHandler = () => {
    setIsOpen(!isOpen);
  };
  // Use custom hook to lock body scroll
  useBodyScrollLock(isOpen);
  return (
    <>
      <div onClick={showPopUpHandler}>
        <Trash className={Style.list_delete_icon} />
      </div>
      {isOpen && (
        <DeletePopUp
          title={translate("RESIDENT.DELETE_TYPE_OF_RESIDENT_TITR", {
            value: isPrimary
              ? userRoleName
              : translate("RESIDENT.CO_RESIDENT", {
                  value: userRoleName,
                }),
          })}
          description={translate("ALERT.DELETE_ITEM_DESC", {
            value: isPrimary
              ? userRoleName
              : translate("RESIDENT.CO_RESIDENT", {
                  value: userRoleName,
                }),
          })}
          setPopUpState={showPopUpHandler}
          deleteAction={() =>
            deleteResidentHandler(residentId, isPrimary, userRoleName)
          }
          loading={progressing}
        />
      )}
      {loading && <Loading />}
    </>
  );
};

export default DeleteResident;
