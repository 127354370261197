import { ApiResponse, IServiceResult } from "../../../../../viewModels/api/Api";
import { BookServiceViewModel } from "../../../../../viewModels/dataTypes/building/BookServiceViewModel";
import {
  GetServicesRequests,
  UpdateServiceRequestsState,
} from "../../../../../services/api/BuildingServiceRequests";
import {
  BookService,
  BookServiceUpdateStateViewModel,
  RequestState,
} from "../../../../../viewModels/api/Models";
import {
  FloorinsDateTimeFormat,
  IsNullString,
} from "../../../../../services/commonServices/CommonServices";
import { ContainsSearchTerm } from "../../../../../tools/table/filter/filterSearch/FIlterSearch.Service";
import { FilterConditionsEnum } from "../../../../../viewModels/enums/FilterConditionsEnum";
import dayjs from "dayjs";

// Ensure to import dayjs plugins
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FormatDateDefault } from "../../../../../Setting";
import { CalenderEnum } from "../../../../../viewModels/enums/CalenderEnum";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

export const GetServiceRequestItems = async (
  buildingId: number,
  categoryId: number
): Promise<IServiceResult<BookServiceViewModel[]>> => {
  const resultModel: IServiceResult<BookServiceViewModel[]> = {
    message: "",
    data: null,
  };

  const getServiceRequestsResult: ApiResponse<BookService[]> =
    await GetServicesRequests(buildingId, categoryId);

  const transformedData: BookServiceViewModel[] =
    getServiceRequestsResult.data?.map(
      (service: BookService, index: number) => ({
        ...service,
        lastUpdateDateTime: FloorinsDateTimeFormat(
          service.lastUpdateDateTime?.toString() ?? ""
        ),
        createdDateTime: FloorinsDateTimeFormat(
          service.createdDateTime?.toString() ?? ""
        ),
        number: index + 1,
      })
    ) || [];

  resultModel.data = transformedData;

  return resultModel;
};

export const UpdateServiceRequestState = async (
  stateInfo: BookServiceUpdateStateViewModel
) => {
  const resultModel: IServiceResult<boolean> = {
    message: "",
    data: false,
  };

  const getServiceRequestStateResult: ApiResponse<BookServiceUpdateStateViewModel> =
    await UpdateServiceRequestsState(stateInfo);
  resultModel.data = !!getServiceRequestStateResult.data;
  return resultModel;
};

const SearchBuildingServiceRequests = (
  data: BookServiceViewModel[],
  searchTerm: string
) => {
  if (IsNullString(searchTerm)) {
    return data;
  }

  return data.filter((item: BookServiceViewModel) =>
    ContainsSearchTerm(item, searchTerm)
  );
};

export const FilterBuildingServiceRequests = (
  data: BookServiceViewModel[],
  searchText: string,
  requestServices: string,
  requestServicesLogic: string,
  states: string,
  dateRange: { start: string; end: string } | null,
  selectedMode: string
) => {
  const searchedData = SearchBuildingServiceRequests(data, searchText);

  if (IsNullString(states) && IsNullString(requestServices) && !dateRange) {
    return searchedData;
  }

  const requestServiceList: string[] = requestServices
    ? requestServices.split(",").map((u) => u.trim())
    : [];

  const stateList: number[] = states
    ? states.split(",").map((s) => parseInt(s.trim(), 10))
    : [];

  const filteredData = searchedData.filter((item: BookServiceViewModel) => {
    // Check request services match
    const requestServicesMatch =
      requestServicesLogic === FilterConditionsEnum.OR
        ? requestServiceList.length === 0 ||
          requestServiceList.some(
            (service: string) => item.buildingServicesId === Number(service)
          )
        : requestServiceList.length === 0 ||
          requestServiceList.every(
            (service: string) => item.buildingServicesId === Number(service)
          );

    // Check states match
    const statesMatch =
      stateList.length === 0 ||
      stateList.some((state: RequestState) => item.status === state);

    // Filter by date range based on selectedMode
    const dateMatch =
      !dateRange ||
      (() => {
        const itemDate = dayjs(item.createdDateTime);
        const startDate = dayjs(dateRange.start, FormatDateDefault, true);
        const endDate = dayjs(dateRange.end, FormatDateDefault, true);

        if (!itemDate.isValid() || !startDate.isValid() || !endDate.isValid()) {
          return false; // Return false if any date is invalid
        }

        switch (selectedMode) {
          case CalenderEnum.DAY:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.DAY) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.DAY)
            );
          case CalenderEnum.WEEK:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.DAY) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.DAY)
            );
          case CalenderEnum.MONTH:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.MONTH) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.MONTH)
            );
          case CalenderEnum.YEAR:
            return (
              itemDate.isSameOrAfter(startDate, CalenderEnum.YEAR) &&
              itemDate.isSameOrBefore(endDate, CalenderEnum.YEAR)
            );
          default:
            return true; // If the mode is not recognized, consider all dates as valid
        }
      })();

    // Combine all matching criteria
    return requestServicesMatch && statesMatch && dateMatch;
  });

  return filteredData;
};
