import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Style from "./DatePicker.module.scss";
import { SizeType } from "../../viewModels/dataTypes/tools/ListComponentType";
import dayjs, { Dayjs } from "dayjs";
import { StyledPaper, StyledStaticDatePicker } from "./DatePickerContainer";

interface Props {
  size?: SizeType;
  label: string;
  onChange: (date: Date | null) => void;
  value: Date | null;
  readOnly?: boolean;
  shouldDisable?: (day: Dayjs) => boolean;
  disablePast?: boolean;
}

const FloorinsDatePicker: React.FC<Props> = ({
  size = SizeType.large,
  label,
  onChange,
  value,
  readOnly = false,
  shouldDisable = null,
  disablePast = false,
}) => {
  const [internalValue, setInternalValue] = useState<Dayjs | null>(
    dayjs(value)
  );
  const [changeLabelColor, setChangeLabelColor] = useState<boolean>(false);

  const changeDateHandler = (dateValue: Dayjs | null) => {
    setChangeLabelColor(Boolean(dateValue));
    setInternalValue(dateValue);
    onChange(dateValue ? dateValue.toDate() : new Date());
  };

  useEffect(() => {
    setChangeLabelColor(Boolean(value));
    setInternalValue(dayjs(value));
  }, [value]);

  const shouldDisableDate = (day: Dayjs) => {
    if (shouldDisable) {
      return shouldDisable(day);
    }
    return false; // Allow all dates if shouldDisable is not provided
  };

  return (
    <div className={Style.floor_field}>
      <label className={`${changeLabelColor ? Style.fill_field : ""}`}>
        <span className={Style.label}>{label}</span>
      </label>
      <div
        className={`${Style.field} ${
          size === SizeType.small ? Style.small_field : ""
        }`}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            shouldDisableDate={shouldDisable ? shouldDisableDate : undefined}
            value={internalValue}
            onChange={changeDateHandler}
            slots={{
              layout: StyledStaticDatePicker,
              desktopPaper: StyledPaper,
            }}
            className={Style.date_time}
            readOnly={readOnly}
            showDaysOutsideCurrentMonth
            disablePast={disablePast}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default FloorinsDatePicker;
