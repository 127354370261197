import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { IRadioButton } from "../../viewModels/dataTypes/tools/DropDownItems";
import Style from "./RadioButtonGroup.module.scss";

interface Pros {
  items: IRadioButton[];
  onChangeConditionsLogic: (valeu: string) => void;
  value: string
}
const RadioButtonGroup: React.FC<Pros> = ({
  items,
  onChangeConditionsLogic,
  value
}) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeConditionsLogic((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl>
      <RadioGroup
        value={value}
        onChange={handleChange}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        {items.map((item: IRadioButton, index: number) => (
          <FormControlLabel
            key={index}
            className={Style.radio_item}
            value={item.value}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "var(--icon-primary)",
                  },
                  "&:not(.Mui-checked)": {
                    color: "var(--border-gray-low)", // Change the color when unchecked
                  },
                  "&:hover": {
                    backgroundColor: "transparent", // Remove hover effect
                  },
                  stroke: "var(--icon-white)",
                  strokeWidth: 1,
                }}
              />
            }
            label={item.text}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroup;
