import React from "react";
import styled from "styled-components";

interface Props {
  color: string;
  textColor?: string;
  children?: React.ReactNode; // Define children prop explicitly
  dimensions: number;
  isActive?: boolean; // New prop for active state
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void; // Allow the event parameter
}

const StyledServiceItem = styled.div<Props>`
  width: ${(props: Props) => `${props.dimensions}rem`};
  height: ${(props: Props) => `${props.dimensions}rem`};
  background-color: var(--text-secondary);
  border-radius: 20px;
  border: 1px solid var(--border-white); // Default border color
  color: ${(props: Props) => props.color};
  box-shadow: 0px 14.798px 59.192px 0px rgba(0, 0, 0, 0.08);
  align-self: self-start;
  > div > a {
    color: ${(props: Props) => props.color};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    &:focus-visible {
      outline: none;
    }
  }
  &:not(:first-child) p {
    color: var(--text-black);
    font-family: var(--regular);
  }
  @media (min-width: 768px) {
    &:hover,
    &:focus,
    &:active {
      border-color: ${(props: Props) => props.color};
      cursor: pointer;
      > div > a {
        svg,
        p {
          color: ${(props: Props) => props.color};
        }
      }
      button {
        svg {
          color: ${(props: Props) => props.color};
        }
      }
    }
  }

  border-color: ${(props: Props) =>
    props.isActive ? props.color : "var(--border-white)"};
  > div > a {
    svg,
    p {
      color: ${(props: Props) =>
        props.isActive ? props.color : ""} !important;
    }
  }
  button {
    svg {
      color: ${(props: Props) =>
        props.isActive ? props.color : ""};
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      width: ${(props: Props) => `${props.dimensions * 16}px`};
      height: ${(props: Props) => `${props.dimensions * 16}px`};
    }
  }
`;

const ServicesItemBox: React.FC<Props> = ({
  color,
  children,
  dimensions,
  isActive,
  onClick,
}) => {
  return (
    <StyledServiceItem
      dimensions={dimensions}
      color={color}
      isActive={isActive}
      onClick={onClick}
    >
      {children}
    </StyledServiceItem>
  );
};

export default ServicesItemBox;
