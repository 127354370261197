import React, { useEffect, useState } from "react";
import CheckBox from "../../../../../../../../tools/checkBox/CheckBox";
import { useTranslation } from "react-i18next";
import Style from "./CheckResident.module.scss";
import { UpdateResidenViewModel } from "../../../../../../../../viewModels/dataTypes/user/IResident";

interface Props {
  userInfo: UpdateResidenViewModel;
  changeHandler: (userInfo: UpdateResidenViewModel) => void;
}
const CheckResident: React.FC<Props> = ({ userInfo, changeHandler }) => {
  const { t: translate } = useTranslation();
  const [isResident, setIsResident] = useState(userInfo.isResident);
  useEffect(() => {
    if (isResident != userInfo.isResident) {
      changeHandler({
        endDate: userInfo.endDate,
        startDate: userInfo.startDate,
        isResident: isResident,
        residentId: userInfo.residentId,
      });
    }
  }, [isResident]);
  return (
    <CheckBox
      className={Style.resident_check_box}
      isChecked={isResident}
      onChecked={setIsResident}
      checkBoxText={translate("RESIDENT.OWNER_IS_RESIDENT")}
    />
  );
};

export default CheckResident;
