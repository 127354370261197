import React, { useEffect, useState } from "react";
import {
  ContactVerificationState,
  FieldState,
} from "../../../../viewModels/enums/PublicEnum";
import { useTranslation } from "react-i18next";
import TextField from "../../../../tools/fields/textField/TextField";
import { CheckBlockTime } from "../../../../services/commonServices/CommonServices";
import { GeneralOtpCount, OtpTimerValue } from "../../../../Setting";
import { ButtonState } from "../../../../viewModels/enums/ButtonEnum";
import { IServiceResult } from "../../../../viewModels/api/Api";
import { ResponseCode } from "../../../../viewModels/enums/ResponseCode";
import { CheckOtpService } from "../../checkOtp/CheckOtp.Service";
import { MessageType } from "../../../../viewModels/enums/MessageType";
import { CheckEmailService } from "./ConfirmUserContact.Service";
import ConfirmUserContactSteps from "./confirmUserContactStaps/ConfirmUserContactSteps";
import Loading from "../../../../tools/loading/Loading";
import {
  CheckOtpRequestViewModel,
  ReceiverType,
} from "../../../../viewModels/api/Models";
import Style from "./ConfirmUserContact.module.scss";

interface Props {
  setEmail: (value: string) => void;
  email: string;
  setComplete?: (value: boolean) => void;
  defaultState?: ContactVerificationState;
}

const ConfirmUserEmail: React.FC<Props> = ({
  setEmail,
  email,
  setComplete,
  defaultState = ContactVerificationState.Empty,
}) => {
  const [contactVerification, setContactVerification] = useState(
    ContactVerificationState.Empty
  );
  const [timer, setTimer] = useState(OtpTimerValue);
  const [, setTimerText] = useState("");
  const [buttonState, setButtonState] = useState(ButtonState.disable);
  const [messageContent, setMessageContent] = useState<string>("");
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>("");
  const [resetForm, setResetForm] = useState(false);
  const [otpFieldState, setOtpFieldState] = useState(FieldState.Default);
  const [loading, setLoading] = useState<boolean>(false);
  const [emailFieldState, setEmailFieldState] = useState<FieldState>(
    FieldState.Default
  );
  const [messageType, setMessageType] = useState<MessageType>();
  const [block, setBlock] = useState(false);

  const [otpDisabled, setOtpDisabled] = useState<boolean>(false);
  const [otpBlocked, setOtpBlocked] = useState<boolean>(false);
  const checkUserEmail = async () => {
    setLoading(true);
    var isBlock = CheckBlockTime();
    if (!isBlock) {
      var checkEmailAddressResult: IServiceResult<any> =
        await CheckEmailService(email);
      if (checkEmailAddressResult.data != null) {
        setContactVerification(ContactVerificationState.Checking);
        setLoading(false);
        setEmailFieldState(FieldState.Default);
        setMessageContent("");
      } else {
        setMessageContent(checkEmailAddressResult.message);
        setLoading(false);
        setMessageType(MessageType.Error);
        setEmailFieldState(FieldState.Error);
      }
    } else {
      setEmailFieldState(FieldState.Error);
      setMessageContent("ERRORS.TRY_LATER");
      setMessageType(MessageType.Error);
      setLoading(false);
      setupTimer();
    }
  };

  const setupTimer = () => {
    setButtonState(ButtonState.disable);
    setTimer(timer - 1);
    setContactVerification(ContactVerificationState.Blocked);
    setTimerText(`00:${timer}`);
    setMessageContent("ERRORS.TRY_LATER");
    setBlock(true);
  };

  const submitOtp = async (value: string) => {
    setLoading(true);
    setOtpErrorMessage("");
    setOtpFieldState(FieldState.Default);
    setResetForm(false);
    let otpInfo: CheckOtpRequestViewModel = {
      code: value,
      receiver: email,
      receiverType: ReceiverType.Email,
    };
    let result: IServiceResult<any> = await CheckOtpService(
      otpInfo,
      setOtpDisabled
    );

    if (result.data != null) {
      setContactVerification(ContactVerificationState.Verified);
      setButtonState(ButtonState.success);
      setComplete != null && setComplete(true);
      setLoading(false);
      setOtpErrorMessage("");
    } else {
      setOtpErrorMessage(result.message);
      setOtpFieldState(FieldState.Error);
      setResetForm(true);
      setLoading(false);
      if (result.data?.status === ResponseCode.BlockedOtpCode) {
        setupTimer();
      }
    }
  };

  const onChangeEmail = (value: string) => {
    setMessageContent("");
    setEmailFieldState(FieldState.Default);
    setEmail(value);
    if (value.length > 0) {
      setButtonState(ButtonState.default);
    } else {
      setButtonState(ButtonState.disable);
    }
  };
  useEffect(() => {
    if (otpBlocked) {
      setOtpDisabled(true);
    }
  }, [otpBlocked]);

  useEffect(() => {
    if (!otpDisabled) {
      setOtpBlocked(false);
    }
  }, [otpDisabled]);

  useEffect(() => {
    localStorage.setItem("otpDisabled", JSON.stringify(otpDisabled));
    localStorage.setItem("isOtpBlocked", JSON.stringify(otpBlocked));
    localStorage.setItem("otpFieldState", JSON.stringify(otpFieldState));
    localStorage.removeItem("timerValue");
  }, [otpDisabled, otpBlocked, otpFieldState]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("otpMessageContent");
      localStorage.removeItem("otpMessageType");
      localStorage.removeItem("otpDisabled");
      localStorage.removeItem("isOtpBlocked");
      localStorage.removeItem("otpFieldState");
      localStorage.removeItem("timerValue");
    };
  }, []);
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (block) {
      // Function to be executed every 1 second
      intervalId = setInterval(() => {
        const isBlock = CheckBlockTime();
        if (!isBlock) {
          // Your logic when the condition is met
          setButtonState(ButtonState.default);
          setContactVerification(ContactVerificationState.Empty);
          setBlock(false);
          clearInterval(intervalId); // Clear the interval when the condition is met
        }
      }, 1000);
    }

    // Clean up the interval when the component unmounts or when 'block' becomes false
    return () => clearInterval(intervalId);
  }, [block]);

  useEffect(() => {
    setContactVerification(defaultState);
  }, [defaultState]);
  const { t: translate } = useTranslation();

  return (
    <>
      <TextField
        className={Style.email_input}
        value={email}
        placeHolder={`${translate("PLACEHOLDER.EMAIL")}`}
        errorMessage={translate(messageContent)}
        messageType={messageType}
        label={translate("LABELS.EMAIL")}
        onChange={onChangeEmail}
        maxLength={254}
        readOnly={
          contactVerification === ContactVerificationState.Empty ||
          contactVerification === ContactVerificationState.Fill
            ? false
            : true
        }
        fieldState={emailFieldState}
      />
      <ConfirmUserContactSteps
        buttonState={buttonState}
        checkUserContactMethod={checkUserEmail}
        contactVertificationStep={contactVerification}
        deliveryMethod={ReceiverType.Email}
        setContactVerificationStep={setContactVerification}
        submitOtp={submitOtp}
        userContact={email}
        blockTimerMethod={setupTimer}
        errorMessage={otpErrorMessage}
        fieldState={otpFieldState}
        resetFromAction={resetForm}
        otpLength={GeneralOtpCount}
        otpDisabled={otpDisabled}
        setOtpDisabled={setOtpDisabled}
      />
      {loading === true && <Loading />}
    </>
  );
};

export default ConfirmUserEmail;
