import React, { useEffect, useState } from "react";
import StateComponent from "./StateComponent";
import { RequestState } from "../../../../viewModels/api/Models";
import {
  GetServiceRequestItems,
  UpdateServiceRequestState,
} from "../../../../components/dashboard/dashboardContents/services/requests/ServiceRequest.Service";
import { AppThunkDispatch } from "../../../../redux/Store";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { GetBuildingServiceRequests } from "../../../../redux/slices/BuildingServiceRequestsSlice";
import { CreateToast } from "../../../toast/ToastService";
import { ToastType } from "../../../../viewModels/enums/ToastType";
import { useTranslation } from "react-i18next";

interface Props {
  id: number;
  status: number;
  isHovered?: boolean | undefined;
}

const RequestStateComponent: React.FC<Props> = ({ status, isHovered, id }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(status);
  const [openCancelPopUp, setOpenCancelPopUp] = useState<boolean>(false);
  const [cancellationReason, setCancellationReason] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const stateitemForPopup: number = 1;

  const { buildingId, categoryId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t: translate } = useTranslation();

  const getRequestStateList = (): string[] => {
    return Object.keys(RequestState)
      .filter((key: string) => isNaN(Number(key)))
      .map((key: string) => key);
  };

  const handleSelectState = async (index: number) => {
    if (index === 1) {
      setOpenCancelPopUp(true);
    } else {
      await updateState(index);
      setSelectedIndex(index);
    }
    dispatch(
      GetBuildingServiceRequests({
        buildingId: Number(buildingId),
        categoryId: Number(categoryId),
      })
    );
  };

  const handleCancelRequest = async () => {
    const cancellationStatus = 1;
    await updateState(cancellationStatus);
    setOpenCancelPopUp(false);
    setSelectedIndex(cancellationStatus);
    setCancellationReason("");
    setLoading(false);
    dispatch(
      GetBuildingServiceRequests({
        buildingId: Number(buildingId),
        categoryId: Number(categoryId),
      })
    );
  };

  const updateState = async (newStatus: number) => {
    const newStateInfo = {
      id,
      cancellationReason,
      status: newStatus,
    };

    setLoading(true);
    const updateRequestStateHandler = await UpdateServiceRequestState(
      newStateInfo
    );
    if (updateRequestStateHandler.data) {
      const result = await GetServiceRequestItems(
        Number(buildingId),
        Number(categoryId)
      );
      if (newStateInfo.status === 1) {
        CreateToast(
          ToastType.Success,
          translate("ALERT.CANCEL_SUCCESSFULLY", {
            value: translate("SHARED.SERVICE"),
          })
        );
      }
      setLoading(false);
      dispatch({
        type: "SET_SERVICE_REQUEST_ITEMS",
        payload: result.data,
      });
    } else {
      if (newStateInfo.status === 1) {
        CreateToast(
          ToastType.Error,
          translate("ERRORS.CANCEL_FAILED", {
            value: translate("SHARED.SERVICE"),
          })
        );
        setLoading(false);
      }
    }
  };

  const statusLabels = getRequestStateList();
  const stateColors = [
    "var(--bg-blue-medium)",
    "var(--bg-red-medium)",
    "var(--bg-green-medium)",
  ];

  return (
    <StateComponent
      status={status}
      stateItems={statusLabels}
      stateColors={stateColors}
      selectedIndex={selectedIndex}
      hovered={isHovered}
      onSelect={handleSelectState}
      onCancelRequest={handleCancelRequest}
      openCancelPopUp={openCancelPopUp}
      setOpenCancelPopUp={setOpenCancelPopUp}
      cancellationReason={cancellationReason}
      onCancellationReasonChange={setCancellationReason}
      stateitemForPopup={stateitemForPopup}
      loading={loading}
    />
  );
};

export default RequestStateComponent;
