import React, { ReactElement } from "react";
import Style from "./AbilityItem.module.scss";
import { TickCircle } from "iconsax-react";

interface Props {
  Title: ReactElement;
  planColor: string;
}

export const PlanAbilityItems: React.FC<Props> = ({ Title, planColor }) => {
  return (
    <div className={`${Style.ability_item} ${Style.exist}`}>
      <TickCircle size="12" color={planColor} variant="Bold" />
      {Title}
    </div>
  );
};
