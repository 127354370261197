import { BaseUrl, CreateUnitResidentsRequest, GetBuldingUnitsRequest } from "../../text/Api";
import { ApiResponse } from "../../viewModels/api/Api";
import { CreateUnitResidentsViewModel } from "../../viewModels/api/Models";
import { IUnitInfo } from "../../viewModels/dataTypes/building/IUnitInfo";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CallApi } from "../CallApi";
import { DefaultApiHeader } from "./ApiHeaders";

export const GetBuildingUnits = async (buildingId : string) => {
    const Url: string = BaseUrl + GetBuldingUnitsRequest + buildingId;
  
    var Result: ApiResponse<IUnitInfo[]> = await CallApi(
      Url,
      "{}",
      DefaultApiHeader,
      ApiMethod.Get
    );
    return Result;
  };


  export const CreateUnitResidents = async (residentsInfo : CreateUnitResidentsViewModel) => {
    const Url: string = BaseUrl + CreateUnitResidentsRequest;
    var raw = JSON.stringify(residentsInfo);
    var Result: ApiResponse<boolean> = await CallApi(
      Url,
      raw,
      DefaultApiHeader,
      ApiMethod.Post
    );
    return Result;
  }; 