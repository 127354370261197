import React, { useEffect, useRef } from "react";
import { MenuItem } from "@mui/material";
import Style from "./FilterDropDownSelect.module.scss";
import { IDropDown } from "../../../viewModels/dataTypes/tools/DropDownItems";
import CustomExpandMoreIcon from "../../icons/CustomeExpandMoreIcon";

interface Props {
  label: string;
  items: IDropDown[];
  onModeChange: (mode: string) => void;
  value: string;
}
const FilterDropDownSelect: React.FC<Props> = ({
  label,
  items,
  onModeChange,
  value,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedValue, setSelectedValue] = React.useState<string>(value);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleSelect = (value: string) => {
    setSelectedValue(value);
    onModeChange(value);
    setOpen(false);
  };
  return (
    <div ref={divRef} className={`${Style.mobile_dropdown}`}>
      <div
        className={`${Style.mobile_dropdown_label} ${Style.mobile_dropdown_label_title}`}
        onClick={() => setOpen(!open)}
      >
        <span>{label}</span>
        <CustomExpandMoreIcon open={open} />
      </div>
      <ul
        className={`${Style.custom_dropdown_options} ${
          open ? Style.open_dropdown : ""
        }`}
      >
        {items.map((item: IDropDown, index: number) => (
          <MenuItem
            key={index}
            value={item.value}
            onClick={() => handleSelect(item.value)}
          >
            <div
              className={`${Style.mobile_dropdown_label} ${
                item.value === selectedValue ? Style.selected_item : ""
              }`}
            >
              <span> {item.text}</span>
            </div>
          </MenuItem>
        ))}
      </ul>
    </div>
  );
};

export default FilterDropDownSelect;
