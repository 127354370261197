import React from 'react';

const BuildingServicesDetails = () => {
    return (
        <div>
            
        </div>
    );
}

export default BuildingServicesDetails;
