import Style from "./FileUpload.module.scss";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaximumFileSize } from "../../Setting";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { MessageHandler } from "../messagesHandler/MessagesHandler";
import { MessageType } from "../../viewModels/enums/MessageType";
import { BaseFilesPath } from "../../text/Api";
import { Trash } from "iconsax-react";
import { FilesUploadType } from "../../viewModels/dataTypes/tools/FilesUploadType";
import { RoundSizeToDecimal } from "../../services/commonServices/CommonServices";
import { CheckFileService } from "./File.Service";
import { IServiceResult } from "../../viewModels/api/Api";
import { IFileReader } from "../../viewModels/dataTypes/File";

interface Props {
  onchange: (e: any) => void;
  onDelete: () => void;
  placeHolder?: string;
  label: string;
  fileName: string | null;
  fileType: FilesUploadType;
  fileDimensions?: number;
}

const FileUpload: React.FC<Props> = ({
  onchange,
  placeHolder,
  label,
  fileName,
  onDelete,
  fileType,
  fileDimensions,
}) => {
  const [file, setfile] = useState<File | null>(null);
  const [image, setImage] = useState<string>("");
  const [errorMessage, seterrorMessage] = useState<string>("");
  const [changeLabelColor, setChangeLabelColor] = useState(false);

  const ChangeFile = async (element: any) => {
    var theFile: File = element?.target.files[0];
    var resultUploadFile: IServiceResult<IFileReader> = await CheckFileService(
      theFile,
      fileType,
      fileDimensions
    );
    if (resultUploadFile.data != null) {
      setChangeLabelColor(true);
      onchange(resultUploadFile.data.file);
      setfile(resultUploadFile.data.file);
      setImage(resultUploadFile.data.filePath);
      seterrorMessage("");
    } else {
      seterrorMessage(resultUploadFile.message);
      setChangeLabelColor(false);
    }
  };

  const DeleteFile = () => {
    onchange(null);
    setImage("");
    setfile(null);
    onDelete();
    setChangeLabelColor(false);
  };

  useEffect(() => {
    setImage(BaseFilesPath + fileName ?? "");
    if (fileName !== null) {
      setChangeLabelColor(true);
    }
  }, [fileName]);

  const { t: translate } = useTranslation();

  return (
    <div className={Style.fileupload_card}>
      <div>
        {errorMessage?.length === 0 || errorMessage == null ? (
          <label
            className={`${Style.label} ${
              changeLabelColor ? Style.fill_field : ""
            }`}
          >
            <span>
              {translate(label !== undefined ? label : "")}{" "}
              {fileDimensions !== null && (
                <span>
                  ({fileDimensions + "px"}-{fileDimensions + "px"},{" "}
                  {RoundSizeToDecimal(MaximumFileSize, 1)} MB)
                </span>
              )}
            </span>
          </label>
        ) : (
          <MessageHandler
            message={translate(errorMessage)}
            type={MessageType.Error}
          />
        )}
      </div>
      <div
        className={`${Style.file_upload} ${
          errorMessage.length > 0 ? Style.error_field : ""
        }`}
      >
        <div className={Style.upload_box}>
          <input
            placeholder={placeHolder}
            type="file"
            onChange={ChangeFile}
            accept={fileType}
          />
          {fileName != null ? (
            <div className={Style.file}>
              <div className={Style.file_name}>
                <img src={image} />
                <span> {fileName}</span>
              </div>
              <div className={Style.delete_file}>
                <Trash onClick={DeleteFile} size="20" color="var(--icon-red)" />
              </div>
            </div>
          ) : file != null ? (
            <div className={Style.file}>
              <div className={Style.file_name}>
                <img src={image} />
                <span> {file?.name}</span>
              </div>
              <div className={Style.delete_file}>
                <span> {RoundSizeToDecimal(file?.size, 3) + " MB"}</span>
                <Trash onClick={DeleteFile} size="20" color="var(--icon-red)" />
              </div>
            </div>
          ) : (
            <span>
              <FileUploadOutlinedIcon className={Style.fild_upload_icon} />
              {translate("LABELS.CHOOSE_FILE")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
