import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Style from "./Icons.module.scss";

interface Props {
  open: boolean;
  className?: string;
}
const CustomExpandMoreIcon: React.FC<Props> = ({ open, className }) => {
  return open ? (
    <ExpandLessIcon className={`${Style.expend_icon} ${className}`} />
  ) : (
    <ExpandMoreIcon className={`${Style.expend_icon} ${className}`} />
  );
};

export default CustomExpandMoreIcon;
