import React, { useEffect, useState } from "react";
import DashboardTitle from "../../../../../tools/dashboardTitle/DashboardTitle";
import { User } from "iconsax-react";
import { useTranslation } from "react-i18next";
import { DashboardBox } from "../../../../../tools/box/DashboardBox";
import {
  IBuildingInfoReducer,
  IReducer,
} from "../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../redux/Store";
import { GetBuildingInfoDataAction } from "../../../../../redux/slices/BuildingInfoSlice";
import { useParams } from "react-router-dom";
import Style from "./addResidentForm/AddResidentForm.module.scss"
import AddResidentForm from "./addResidentForm/AddResidentForm";
import { ResidentType } from "../../../../../viewModels/enums/ResidentType";

interface Props {
  residentType: ResidentType;
}
const AddResident : React.FC<Props> = ({residentType}) => {
  const { t: translate } = useTranslation();
  const { buildingId } = useParams();
  const userBuildingInfo: IBuildingInfoReducer = useSelector(
    (state: IReducer) => state.buildingInfo
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const [buildingName, setBuildingName] = useState("");

  useEffect(() => {
    const getBuildingInfo = async () => {
      if (userBuildingInfo.buildingInfo != null) {
        setBuildingName(userBuildingInfo.buildingInfo.buildingName);
      } else {
        dispatch(GetBuildingInfoDataAction(buildingId ?? ""));
      }
    };
    getBuildingInfo();
  }, [userBuildingInfo]);

  return (
    <>
      <DashboardBox>
        <DashboardTitle
          className={Style.new_resident_title}
          icon={<User size="22" color="var(--icon-gray-high)" />}
          label={`${buildingName} ${translate("RESIDENT.NEW_RESIDENT" , {value : residentType})}`}
        />
        <AddResidentForm residentType={residentType}/>
      </DashboardBox>
    </>
  );
};

export default AddResident;
