import React, { useEffect, useState } from "react";
import Style from "./PricingSection.module.scss";
import { GetMonetaryPlans } from "../../../../services/api/Plan";
import { PricingItem } from "../../../../viewModels/dataTypes/plan/Pricing";
import { Grid } from "@mui/material";
import PlanItem from "../../../beforeDashboard/pricing/items/planItem/PlanItem";
import {
  DaysLeftUntilToday,
  PriceConvertor,
} from "../../../../services/commonServices/CommonServices";
import { useTranslation } from "react-i18next";
import { PricingCallToActionType } from "../../../../viewModels/dataTypes/tools/PricingCallToActionType";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import CurrentPlanItem from "./currentPlanItem/CurrentPlanItem";
import useWindowSize from "../../../../hook/WindowSize";
import { PricingTypeEnum } from "../../../../viewModels/enums/PricingType";
import { GridBox } from "../../../../tools/box/GridBox";
import { SmallDeviceTrigger } from "../../../../Setting";

interface Props {
  expireDate: Date;
}

const PricingSection: React.FC<Props> = ({ expireDate }) => {
  const [plans, setPlans] = useState<PricingItem[] | null>();
  const [remainingTime, setRemainingTime] = useState(0);

  const { width : screenSize } = useWindowSize();
  const [planType, setPlanType] = useState<PricingTypeEnum>(PricingTypeEnum.Gold)

  useEffect(() => {
    const getPlansInfo = async () => {
      const getPlansResult = await GetMonetaryPlans();
      setPlans(getPlansResult.data);
    };

    setRemainingTime(DaysLeftUntilToday(expireDate.toString()));
    getPlansInfo();
  }, []);

  const { t: translate } = useTranslation();

  const planOnClick = (value: PricingItem) => {
    setPlanType(value.title);
  };

  return (
    <GridBox>
      <h2 className={Style.section_title}>
        {translate("PLANS.PLAN_MANAGMENT")}
      </h2>
      <div className={Style.trial_plan_details}>
        {screenSize > SmallDeviceTrigger ? (
          <Grid container spacing={2}>
            <Grid item lg={3} md={6} sm={12}>
              <CurrentPlanItem remainingTime={remainingTime} />
            </Grid>
            {plans != null &&
              plans.map((item: PricingItem) => (
                <Grid item lg={3} md={6} sm={12}>
                  <PlanItem
                    callToActionType={PricingCallToActionType.Button}
                    planTitle={item.title}
                    planImage={item.planImageUrl}
                    planPrice={PriceConvertor(item.price)}
                    buildingLimitNumber={item.buildingCount}
                    planColor={`var(--${item.title.toLowerCase()})`}
                    description={item.description}
                    isSelect={planType == item.title}
                    onClick={() => planOnClick(item)}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <div>
            <CurrentPlanItem remainingTime={remainingTime} />
            <Swiper
              spaceBetween={24}
              slidesPerView={1.4}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className={Style.slider}
            >
              {plans != null &&
                plans.map((item: PricingItem) => (
                  <SwiperSlide>
                    <PlanItem
                      callToActionType={PricingCallToActionType.Button}
                      planTitle={item.title}
                      planImage={item.planImageUrl}
                      planPrice={PriceConvertor(item.price)}
                      buildingLimitNumber={item.buildingCount}
                      planColor={`var(--${item.title.toLowerCase()})`}
                      description={item.description}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        )}
      </div>
    </GridBox>
  );
};

export default PricingSection;
