import {
  BaseUrl,
  CreateFirstBuildingRequest,
  GetApplicationPlansRequest,
  GetMonetaryPlansRequest,
} from "../../text/Api";
import { ApiResponse, IResponseStatus } from "../../viewModels/api/Api";
import { ChoosePlanToCreateBuildingViewModel } from "../../viewModels/api/Models";
import { PricingItem } from "../../viewModels/dataTypes/plan/Pricing";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CallApi } from "../CallApi";
import { DefaultApiHeader } from "./ApiHeaders";


export const GetApplicationPlans = async () => {

  const Url: string = BaseUrl + GetApplicationPlansRequest;
  var Result: ApiResponse<PricingItem[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;
  
};

export const GetMonetaryPlans = async () => {

  const Url: string = BaseUrl + GetMonetaryPlansRequest;
  var Result: ApiResponse<PricingItem[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;

};

export const ChoosePlanToCreateBuilding = async (
  chooseBuildingItems: ChoosePlanToCreateBuildingViewModel
) => {

  const Url: string = BaseUrl + CreateFirstBuildingRequest;
  var raw = JSON.stringify(chooseBuildingItems);
  var Result: ApiResponse<IResponseStatus> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;

};
