import { ApiResponse } from "../../viewModels/api/Api";
import { CallApi } from "../CallApi";
import {
  BaseUrl,
  BuildingUsersRequest,
  CheckEmailToLoginRequest,
  CheckEmailToSignUpRequest,
  AdministratorRequest,
  CheckOtpRequest,
  CheckPhoneNumberToLoginRequest,
  CheckPhoneNumberToSignUpRequest,
  CreateNewAdministratorRequest,
  DeleteResidentRequest,
  GetBuildingUserInfoRequest,
  GetUnitResidentsRequest,
  GetUserCredentialRequest,
  GetUserInfoRequest,
  GetUserRolesRequest,
  SendEmailOtpRequest,
  SendSMSOtpRequest,
  UserInfoConfirmationRequest,
  UserInfoRegistrationRequest,
  UpdateRangeOfResidentsRequest,
} from "../../text/Api";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { IUser, IUserRole } from "../../viewModels/dataTypes/user/User";
import { UserTokenModel } from "../../viewModels/dataTypes/registration/TokenViewModel";
import { ResponseCode } from "../../viewModels/enums/ResponseCode";
import { BuildingUsersResponseViewModel } from "../../viewModels/dataTypes/user/IBuildingUsers";
import { CheckEmailOrPhoneNumberResponse } from "../../viewModels/dataTypes/registration/SingUp";
import { ICheckOtpResponse } from "../../viewModels/dataTypes/registration/Otp";
import {
  IAdministrator,
  IAdministratorInfo,
} from "../../viewModels/dataTypes/user/IAdministrator";
import { GetUnitResidentViewModel } from "../../viewModels/dataTypes/building/IUnitInfo";
import { IResident } from "../../viewModels/dataTypes/user/IResident";
import { DefaultApiHeader } from "./ApiHeaders";
import {
  AddAdministratorViewModel,
  CheckOtpRequestViewModel,
  DeleteAdministratorViewModel,
  UserCredentialInfoViewModel,
  UserInfoRegistrationViewModel,
} from "../../viewModels/api/Models";
import { BuildingUserInfoViewModel } from "../../viewModels/dataTypes/building/IBuildingUserInfoViewModels";

export const InfoRegistration = async (data: UserInfoRegistrationViewModel) => {
  const Url: string = BaseUrl + UserInfoRegistrationRequest;
  const raw = JSON.stringify(data);

  const Result: ApiResponse<UserTokenModel> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const InfoConfirmation = async (
  userInfo: UserInfoRegistrationViewModel
) => {
  const Url: string = BaseUrl + UserInfoConfirmationRequest;
  const raw = JSON.stringify(userInfo);

  const Result: ApiResponse<UserTokenModel> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const GetUserCredentialInfo = async (userId: string) => {
  const Url: string = BaseUrl + GetUserCredentialRequest + userId;
  const Result: ApiResponse<UserCredentialInfoViewModel> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Post
  );

  if (Result.responseStatus == ResponseCode.BadRequest) {
    window.location.replace("/");
  } else {
    return Result;
  }
};

export const GetUserActiveRoles = async (userId: string) => {
  const Url: string = BaseUrl + GetUserRolesRequest + userId;

  const Result: ApiResponse<IUserRole[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

//SingUp validation
export const CheckEmailToSignUp = async (email: string) => {
  const Url: string = BaseUrl + CheckEmailToSignUpRequest + email;

  const Result: ApiResponse<CheckEmailOrPhoneNumberResponse> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const CheckPhoneNumberToSignUp = async (phoneNumber: string) => {
  const Url: string = BaseUrl + CheckPhoneNumberToSignUpRequest + phoneNumber;

  const Result: ApiResponse<CheckEmailOrPhoneNumberResponse> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};
//Login validation
export const CheckEmailToLogIn = async (email: string) => {
  const Url: string = BaseUrl + CheckEmailToLoginRequest + email;

  const Result: ApiResponse<CheckEmailOrPhoneNumberResponse> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const GetUserInfoByUserContact = async (userContact: string) => {
  const Url: string = BaseUrl + GetUserInfoRequest + userContact;

  const Result: ApiResponse<IUser> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;
};

export const CheckPhoneNumberToLogIn = async (phoneNumber: string) => {
  const Url: string = BaseUrl + CheckPhoneNumberToLoginRequest + phoneNumber;

  const Result: ApiResponse<CheckEmailOrPhoneNumberResponse> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const CheckOtpCode = async (otpInfo: CheckOtpRequestViewModel) => {
  const Url: string = BaseUrl + CheckOtpRequest;
  const raw = JSON.stringify(otpInfo);
  const Result: ApiResponse<ICheckOtpResponse> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const ReSendEmailOtp = async (email: string) => {
  const Url: string = BaseUrl + SendEmailOtpRequest + email;

  const Result: ApiResponse<CheckEmailOrPhoneNumberResponse> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const ReSendSMSOtp = async (phoneNumber: string) => {
  const Url: string = BaseUrl + SendSMSOtpRequest + phoneNumber;

  const Result: ApiResponse<CheckEmailOrPhoneNumberResponse> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const GetBuildingUsers = async (buildingId: string) => {
  const Url: string = BaseUrl + BuildingUsersRequest + buildingId;
  const Result: ApiResponse<BuildingUsersResponseViewModel[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const CheckExistAdmin = async (userId: string, buildingId: string) => {
  const Url: string = `${
    BaseUrl + AdministratorRequest
  }/${userId}/${buildingId}`;

  const Result: ApiResponse<IAdministratorInfo[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;
};

export const AddAdministrator = async (
  newAdminInfo: AddAdministratorViewModel
) => {
  const Url: string = BaseUrl + CreateNewAdministratorRequest;
  const raw = JSON.stringify(newAdminInfo);
  const Result: ApiResponse<boolean> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Post
  );
  return Result;
};

export const UpdateAdministrator = async (
  newAdminInfo: AddAdministratorViewModel
) => {
  const Url: string = BaseUrl + AdministratorRequest;
  const raw = JSON.stringify(newAdminInfo);
  const Result: ApiResponse<IAdministrator[]> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Put
  );
  return Result;
};

export const UpdateRangeOfResidents = async (residents: IResident[]) => {
  const Url: string = BaseUrl + UpdateRangeOfResidentsRequest;
  const raw = JSON.stringify(residents);
  const Result: ApiResponse<IResident[]> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Put
  );
  return Result;
};

export const GetUnitResident = async (info: GetUnitResidentViewModel) => {
  const Url: string = `${BaseUrl + GetUnitResidentsRequest}/${info.unitId}/${
    info.userRole
  }`;
  const Result: ApiResponse<IResident[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );

  return Result;
};

export const GetBuildingUserInfo = async (
  buildingId: string,
  userId: string
) => {
  const Url: string = `${
    BaseUrl + GetBuildingUserInfoRequest
  }${buildingId}/${userId}`;

  const Result: ApiResponse<BuildingUserInfoViewModel> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );

  return Result;
};

export const DeleteResident = async (residentId: string) => {
  const Url: string = `${BaseUrl + DeleteResidentRequest + residentId}`;
  const Result: ApiResponse<boolean> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Delete
  );

  return Result;
};

export const DeleteAdministrator = async (
  adminInfo: DeleteAdministratorViewModel
) => {
  const Url: string = `${BaseUrl + AdministratorRequest}`;
  const raw: string = JSON.stringify(adminInfo);
  const Result: ApiResponse<boolean> = await CallApi(
    Url,
    raw,
    DefaultApiHeader,
    ApiMethod.Delete
  );

  return Result;
};
