import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Dashboard from "./components/dashboard/Dashboard";
import CheckOtp from "./components/beforeDashboard/checkOtp/CheckOtp";
import SuperAdminLogin from "./components/beforeDashboard/login/supperAdminLogin/SuperAdminLogin";
import UserLogin from "./components/beforeDashboard/login/userLogin/UserLogin";
import Pricing from "./components/beforeDashboard/pricing/Pricing";
import UserRegistration from "./components/beforeDashboard/registration/UserRegistration";
import CheckPassword from "./components/beforeDashboard/checkPassword/CheckPassword";
import Home from "./components/home/Home";
import ForgetPassword from "./components/beforeDashboard/changePassword/ForgetPassword";
import ChangePassword from "./components/beforeDashboard/changePassword/ChangePassword";
import AfterChangePassword from "./components/beforeDashboard/changePassword/AfterChangePassword";
import ConfirmationUserInfo from "./components/beforeDashboard/login/confirmationUserInfo/ConfirmationUserInfo";
import Buildings from "./components/dashboard/dashboardContents/building/Buildings";
import BuildingInfo from "./components/dashboard/dashboardContents/building/BuildingInfo";
import Users from "./components/dashboard/dashboardContents/users/Users";
import NewAdminPage from "./components/dashboard/dashboardContents/users/NewAdminPage";
import NewResidentPage from "./components/dashboard/dashboardContents/users/NewResidentPage";
import { ResidentType } from "./viewModels/enums/ResidentType";
import BuildingUserPage from "./components/dashboard/dashboardContents/users/BuildingUserPage";
import { ReceiverType } from "./viewModels/api/Models";
import BuildingServiceCategories from "./components/dashboard/dashboardContents/services/BuildingServiceCategories";
import BuildingServices from "./components/dashboard/dashboardContents/services/BuildingServices";
import { GeneralOtpCount } from "./Setting";
import BuildingServicesDetails from "./components/dashboard/dashboardContents/services/buildingServices/servicesComponent/buildingServicesDetails/BuildingServicesDetails";
import AddBuildingService from "./components/dashboard/dashboardContents/services/buildingServices/servicesComponent/addBuildingService/AddBuildingService";
import Complaints from "./components/dashboard/dashboardContents/complaints/Complaints";
import ComplaintInfo from "./components/dashboard/dashboardContents/complaints/ComplaintInfo";
import Announcements from "./components/dashboard/dashboardContents/anouncement/Announcements";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/signup" element={<SuperAdminLogin />}></Route>
      <Route path="/login" element={<UserLogin />}></Route>
      <Route
        path="/signup/checkEmailOtp"
        element={
          <CheckOtp
            title="AUTHENTICATION.SIGNUP"
            deliveryType={ReceiverType.Email}
            description="AUTHENTICATION.CONFIRM_EMAIL"
            otpLength={GeneralOtpCount}
          />
        }
      ></Route>
      <Route
        path="/login/checkEmailOtp"
        element={
          <CheckOtp
            title="AUTHENTICATION.LOGIN"
            deliveryType={ReceiverType.Email}
            description="AUTHENTICATION.CONFIRM_EMAIL"
            otpLength={GeneralOtpCount}
          />
        }
      ></Route>
      <Route
        path="/signup/checkSmsOtp"
        element={
          <CheckOtp
            title="AUTHENTICATION.SIGNUP"
            deliveryType={ReceiverType.PhoneNumber}
            description="AUTHENTICATION.CONFIRM_PHONENUMBER"
            otpLength={GeneralOtpCount}
          />
        }
      ></Route>
      <Route
        path="/login/checkSmsOtp"
        element={
          <CheckOtp
            title="AUTHENTICATION.LOGIN"
            deliveryType={ReceiverType.PhoneNumber}
            description="AUTHENTICATION.CONFIRM_PHONENUMBER"
            otpLength={GeneralOtpCount}
          />
        }
      ></Route>
      <Route path="/login/checkPassword" element={<CheckPassword />}></Route>

      <Route
        path="signup/registrationInfo"
        element={<UserRegistration />}
      ></Route>
      <Route path="/pricing" element={<Pricing />}></Route>
      <Route
        path="/login/forgetpassword"
        element={<ForgetPassword otpLength={GeneralOtpCount} />}
      ></Route>

      <Route path="/login/changePassword" element={<ChangePassword />}></Route>
      <Route path="/completeInfo" element={<ConfirmationUserInfo />}></Route>
      <Route
        path="/login/resetPasswordSuccessfully"
        element={<AfterChangePassword />}
      ></Route>
      <Route path="/dashboard/dashboard" element={<Dashboard />}></Route>
      <Route path="/dashboard/building" element={<Buildings />}></Route>
      <Route
        path="/dashboard/building/info/:buildingId"
        element={<BuildingInfo />}
      ></Route>
      <Route
        path="/dashboard/building/users/:buildingId"
        element={<Users />}
      ></Route>
      <Route
        path="/dashboard/building/complaints/:buildingId"
        element={<Complaints />}
      ></Route>
      <Route
        path="/dashboard/building/announcements/:buildingId"
        element={<Announcements />}
      ></Route>
      <Route
        path="/dashboard/building/complaints/:buildingId/:complaintId"
        element={<ComplaintInfo />}
      ></Route>
      <Route
        path="/dashboard/building/users/:buildingId/addAdmin"
        element={<NewAdminPage />}
      ></Route>
      <Route
        path="/dashboard/building/users/:buildingId/addTenant"
        element={<NewResidentPage residentType={ResidentType.Tenant} />}
      ></Route>
      <Route
        path="/dashboard/building/users/:buildingId/addOwner"
        element={<NewResidentPage residentType={ResidentType.Owner} />}
      ></Route>
      <Route
        path="/dashboard/building/users/:buildingId/userInfo/:userId"
        element={<BuildingUserPage />}
      ></Route>
      <Route
        path="/dashboard/building/services/:buildingId"
        element={<BuildingServiceCategories />}
      ></Route>
      <Route
        path="/dashboard/building/services/:buildingId/:categoryId"
        element={<BuildingServices />}
      ></Route>
      <Route
        path="/dashboard/building/services/:buildingId/:categoryId/details"
        element={<BuildingServicesDetails />}
      ></Route>
      <Route
        path="/dashboard/building/services/:buildingId/:categoryId/add"
        element={<AddBuildingService />}
      />
    </Routes>
  );
}

export default App;
