import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonTheme } from "../../../../viewModels/enums/ButtonEnum";
import MessagePopUp from "../../../popup/MessagePopUp";
import { CloseCircle } from "iconsax-react";
import Style from "./CancelStatePopup.module.scss";
import { SizeType } from "../../../../viewModels/dataTypes/tools/ListComponentType";
import Button from "../../../button/Button";
import TextField from "../../../fields/textField/TextField";
import Loading from "../../../loading/Loading";

interface Props {
  cancelDeleteHandler: () => void;
  discardHandler: () => void;
  loading?: boolean;
  cancellationReason?: string;
  onCancellationReasonChange?: (value: string) => void;
}

const CancelStatePopup: React.FC<Props> = ({
  cancelDeleteHandler,
  discardHandler,
  cancellationReason,
  onCancellationReasonChange,
  loading,
}) => {
  const { t: translate } = useTranslation();
  return (
    <MessagePopUp
      className={Style.cancel_state_popup}
      icon={<CloseCircle className={Style.delete_icon} />}
      title={translate("TITLES.CANCEL_REQUEST")}
      description={
        <>
          <TextField
            value={cancellationReason}
            label={translate("ALERT.CANCELLATION_REASON")}
            placeHolder={translate("PLACEHOLDER.CANCELLATION_REASON")}
            onChange={onCancellationReasonChange}
            maxLength={25}
          />
        </>
      }
      buttons={
        <>
          <Button
            className={Style.delete_button}
            text={translate("BUTTON.DISCARD")}
            theme={ButtonTheme.deleteReverse}
            size={SizeType.small}
            clickMethod={discardHandler}
          />
          <Button
            className={Style.cancel_button}
            text={translate("BUTTON.CANCEL_REQ")}
            theme={ButtonTheme.delete}
            size={SizeType.small}
            clickMethod={cancelDeleteHandler}
          />
          {loading && <Loading />}
        </>
      }
    />
  );
};

export default CancelStatePopup;
