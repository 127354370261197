import ConfirmComponent from "../../../../../tools/table/confirmComponent/ConfirmComponent";
import UserProfileComponent from "../../../../../tools/table/profileComponent/UserProfileComponent";
import {
  AlignmentEnum,
  HeadCellsType,
} from "../../../../../viewModels/dataTypes/DataTableType";
import { IDropDown } from "../../../../../viewModels/dataTypes/tools/DropDownItems";
import { ITabelCallToAction } from "../../../../../viewModels/dataTypes/tools/TabelCellSize";
import { IBuildingUsers } from "../../../../../viewModels/dataTypes/user/IBuildingUsers";
import { UsersAccess } from "../../../../../viewModels/enums/UserAccess";
import DeleteUserFromList from "./deleteUserFromList/DeleteUserFromList";

export const BuildingUserAccess: IDropDown[] = [
  {
    value: UsersAccess.All,
    text: UsersAccess.All,
  },
  {
    value: UsersAccess.Book,
    text: UsersAccess.Book,
  },
  {
    value: UsersAccess.Edit,
    text: UsersAccess.Edit,
  },
  {
    value: UsersAccess.Lobby,
    text: UsersAccess.Lobby,
  },
  {
    value: UsersAccess.Parking,
    text: UsersAccess.Parking,
  },
  {
    value: UsersAccess.Request,
    text: UsersAccess.Request,
  },
];

export const headCells: HeadCellsType<IBuildingUsers & ITabelCallToAction>[] = [
  {
    id: "contact",
    label: "USERS.CONTACT",
    align: AlignmentEnum.Left,
    sortable: true,
    specialComponent: UserProfileComponent,
    specialComponentProps: ["contact", "avatar", "bgAvatar"],
    cellSize: {
      lg: 250,
      md: 210,
      sm: 200,
    },
    isLink: true,
  },
  {
    id: "fullName",
    label: "USERS.FULLNAME",
    align: AlignmentEnum.Left,
    sortable: true,
    cellSize: {
      lg: 250,
      md: 200,
      sm: 200,
    },
    specialComponent: null,
    isLink: true,
  },
  {
    id: "userRole",
    label: "USERS.USERS",
    align: AlignmentEnum.Left,
    sortable: true,
    cellSize: {
      lg: 200,
      md: 160,
      sm: 150,
    },
    specialComponent: null,
    isLink: true,
  },
  {
    id: "permissions",
    label: "USERS.PERMISSION",
    align: AlignmentEnum.Left,
    sortable: false,
    cellSize: {
      lg: 200,
      md: 160,
      sm: 150,
    },
    specialComponent: null,
    isLink: true,
  },
  {
    id: "unitNumber",
    label: "USERS.UNIT_NUMBER",
    align: AlignmentEnum.Left,
    sortable: true,
    cellSize: {
      lg: 150,
      md: 100,
      sm: 100,
    },
    specialComponent: null,
    isLink: true,
  },
  {
    id: "confirm",
    label: "USERS.FIRST_LOGIN",
    align: AlignmentEnum.Center,
    sortable: false,
    specialComponent: ConfirmComponent,
    specialComponentProps: ["confirm"],
    cellSize: {
      lg: 120,
      md: 120,
      sm: 120,
    },
    isLink: true,
  },
  {
    id: "action",
    label: "SHARED.ACTION",
    align: AlignmentEnum.Center,
    sortable: false,
    specialComponent: DeleteUserFromList,
    specialComponentProps: ["id", "contact"],
  },
];
