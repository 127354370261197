export const GetDayWithMonth = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  // Define options for formatting the date
  const options: Intl.DateTimeFormatOptions = {
    month: "short", // Short month name (e.g., "Sep")
    day: "2-digit", // Two-digit day (e.g., "07")
  };

  // Create a formatter with the specified options
  const formatter = new Intl.DateTimeFormat("en-US", options);

  // Format the date
  return formatter.format(date);
};
