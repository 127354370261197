import React, { ReactNode } from "react";
import Style from "./DashboardTitle.module.scss";
import { ClassNameViewModel } from "../../viewModels/dataTypes/CommonViewModels";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useParams } from "react-router-dom";
interface Props extends ClassNameViewModel {
  label: string;
  icon: ReactNode;
  button?: ReactNode;
  backButton?: boolean;
}
const DashboardTitle: React.FC<Props> = ({
  label,
  icon,
  button,
  className = "",
  backButton = false,
}) => {
  const navigate = useNavigate();
  const { buildingId } = useParams<{ buildingId?: string }>();
  return (
    <div className={`${Style.building_titr} ${className}`}>
      <h1>
        {backButton && (
          <div
            className={Style.back_button}
            onClick={() =>
              navigate(`/dashboard/building/services/${buildingId}`)
            }
          >
            <ArrowBackIosIcon className={Style.back_button_icon} />
          </div>
        )}
        {icon}
        <span> {label}</span>
      </h1>
      {button != null && <div>{button}</div>}
    </div>
  );
};

export default DashboardTitle;
