import { Grid } from "@mui/material";
import React from "react";
import useWindowSize from "../../../../../../../../hook/WindowSize";
import { MediumDeviceTrigger } from "../../../../../../../../Setting";
import { useTranslation } from "react-i18next";
import DashboardFormTitle from "../../../../../../../../tools/dashboardTitle/dashboardFormTitle/DashboardFormTitle";
import ContainerStyle from "../ServiceInfoComponent.module.scss";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import SpecialValueField from "../../../../../../../../tools/fields/specilaValueField/SpecialValueField";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const ServiceInfoFee: React.FC<Props> = ({ value, onChange }) => {
  const windowSize = useWindowSize();
  const { t: translate } = useTranslation();
  return (
    <div>
      <DashboardFormTitle
        title={translate("TITLES.SERVICE_FEE")}
        icon={
          <CreditCardOutlinedIcon className={ContainerStyle.service_box_icon} />
        }
        className={`${ContainerStyle.service_box_title} ${ContainerStyle.more_space}`}
      />
      <Grid
        className={ContainerStyle.service_box_container}
        container
        columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
      >
        <Grid item md={6} sm={12} xs={12}>
          <SpecialValueField
            value={value}
            label={translate("LABELS.SERVICE_FEE")}
            placeHolder={translate("PLACEHOLDER.SERVICE_FEE")}
            onChange={onChange}
            specialSymbol="$"
            maxLength={9}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceInfoFee;
