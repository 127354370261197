import { PasswordRegex } from "../../../../services/Regex";
import { UserInfoRegistrationViewModel } from "../../../../viewModels/api/Models";
import { FieldState } from "../../../../viewModels/enums/PublicEnum";

export const CheckPasswordService = (
  password: string,
  setMessage: (value: string) => void,
  setFieldState: (value: FieldState) => void
) => {
  if (PasswordRegex(password)) {
    setMessage("");
    setFieldState(FieldState.Default);
    return true;
  } else {
    setMessage("ERRORS.WEAK_PASSWORD");
    setFieldState(FieldState.Error);
    return false;
  }
};
export const CheckConfirmPasswordService = (
  password: string,
  confirmPassword: string,
  setMessage: (value: string) => void,
  setFieldState: (value: FieldState) => void
) => {
  if (password === confirmPassword) {
    setMessage("");
    setFieldState(FieldState.Default);
    return true;
  } else {
    if (confirmPassword.length == 0) {
      setMessage("ERRORS.CONFIRM_PASSWORD");
      setFieldState(FieldState.Error);
      return false;
    } else {
      setMessage("ERRORS.MISMATCH_PASSWORD");
      setFieldState(FieldState.Error);
      return false;
    }
  }
};
export const CheckIsCompanyService = (
  userInfo: UserInfoRegistrationViewModel,
  setFirstNameErrorMessage: (value: string) => void,
  setFirstNameFieldState: (value: FieldState) => void,
  setLastNameErrorMessage: (value: string) => void,
  setLastNameFieldState: (value: FieldState) => void
) => {
  if (userInfo.companyName == null) {
    var state = true;
    if (userInfo.firstName == null || userInfo.firstName.length == 0) {
      if (userInfo.lastName == null || userInfo.lastName.length == 0) {
        setFirstNameErrorMessage("ERRORS.INVALID_NAME");
        setFirstNameFieldState(FieldState.Error);
        state = false;
      } else {
        setFirstNameErrorMessage("");
        setFirstNameFieldState(FieldState.Default);
        setLastNameErrorMessage("");
        setLastNameFieldState(FieldState.Default);
      }
    } else {
      setFirstNameErrorMessage("");
      setFirstNameFieldState(FieldState.Default);
      setLastNameErrorMessage("");
      setLastNameFieldState(FieldState.Default);
    }
    return state;
  } else {
    setFirstNameErrorMessage("");
    setLastNameErrorMessage("");
    setFirstNameFieldState(FieldState.Default);
    setLastNameFieldState(FieldState.Default);
    return true;
  }
};
export const CheckRuleService = (
  isChecked: boolean,
  setFieldState: (value: FieldState) => void
) => {
  if (isChecked) {
    setFieldState(FieldState.Default);
    return true;
  } else {
    setFieldState(FieldState.Error);
    return false;
  }
};
