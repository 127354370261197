import React from "react";
import { Link } from "react-router-dom";
import Style from "./PrimaryFooter.module.scss";
import { Copyright } from "iconsax-react";

import { useTranslation } from "react-i18next";

const PrimaryFooter: React.FC = () => {
  const { t: translate } = useTranslation();
  return (
    <footer className={Style.primary_footer}>
      <div>
        <ul>
          <li>
            <Link to={""}>{translate("SHARED.TERMS_AND_CONDITION")}</Link>
          </li>
          <li>
            <Link to={""}>{translate("SHARED.REPORT_PROBLEM")}</Link>
          </li>
        </ul>
      </div>
      <span>
        <span>{translate("SHARED.COPYRIGHT")}</span>
        <Copyright size="16" color="var(--icon-gray-high)" />
        <span>{translate("SHARED.COPYRIGHT_DESCRIPTION")}</span>
      </span>
    </footer>
  );
};

export default PrimaryFooter;
