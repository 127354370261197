import styled from "styled-components";

interface Props {
  lg?: number;
  md?: number;
  sm?: number;
}

export const FloorinsBox = styled.div<Props>`
  min-height: 68vh;
  margin: 5rem 1rem;
  overflow: hidden;
  box-shadow: var(--box-shadow);
  padding: 2rem 3rem;
  width: ${(a) => (a.sm !== undefined ? a.sm + "px" : "auto")};
  background-color: white;
  border-radius: 12px;
  position: relative;
  @media (min-width: 768px) {
    width: ${(a) =>
      a.md !== undefined ? a.md + "px" : a.sm !== undefined ? a.sm : "auto"};
  }
  @media (min-width: 1200px) {
    width: ${(a) =>
      a.lg !== undefined
        ? a.lg + "px"
        : a.md !== undefined
        ? a.md + "px"
        : a.sm !== undefined
        ? a.sm + "px"
        : "auto"};
  }
  @media (max-width: 600px) {
    padding: 1.25rem;
    margin: 0;
    width: 100%;
  }
`;
