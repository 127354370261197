import React from "react";
import Style from "./Loading.module.scss";
import LoadingPic from "../../assets/images/loading.svg";
const Loading = () => {
  return (
    <div data-testid="loading" className={Style.loading}>
      <img src={LoadingPic} alt="loading..." />
    </div>
  );
};

export default Loading;
