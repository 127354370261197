import React, { useEffect, useState } from "react";
import MessagePopUp from "../../../../../../../../tools/popup/MessagePopUp";
import { Trash } from "iconsax-react";
import { useTranslation } from "react-i18next";
import Style from "./DeleteServiceCategory.module.scss";
import { ButtonTheme } from "../../../../../../../../viewModels/enums/ButtonEnum";
import { SizeType } from "../../../../../../../../viewModels/dataTypes/tools/ListComponentType";
import Button from "../../../../../../../../tools/button/Button";
import { ButtonState } from "../../../../../../../../viewModels/enums/ButtonEnum";

interface Props {
  categoryName: string;
  cancelDeleteHandler: () => void;
  deleteHandler: () => void;
  loading?: boolean;
}
const DeleteServiceCategory: React.FC<Props> = ({
  categoryName,
  cancelDeleteHandler,
  deleteHandler,
  loading = false,
}) => {
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.disable
  );
  const { t: translate } = useTranslation();

  useEffect(() => {
    const changeStatePolice: boolean = loading;
    setButtonState(
      changeStatePolice ? ButtonState.disable : ButtonState.default
    );
  }, [loading]);
  return (
    <MessagePopUp
      className={Style.delete_user_popup}
      icon={<Trash className={Style.delete_icon} />}
      title={translate("TITLES.DELETE_CATEGORY")}
      description={
        <p className={Style.description}>
          {translate("ALERT.DELETE_CATEGORY_ALERT")}
          <span>{categoryName}?</span>
        </p>
      }
      buttons={
        <>
          <Button
            text={translate("BUTTON.DISCARD")}
            theme={ButtonTheme.deleteReverse}
            size={SizeType.small}
            clickMethod={cancelDeleteHandler}
          />
          <Button
            text={translate("BUTTON.DELETE")}
            theme={ButtonTheme.delete}
            size={SizeType.small}
            clickMethod={deleteHandler}
            state={buttonState}
          />
        </>
      }
    />
  );
};

export default DeleteServiceCategory;
