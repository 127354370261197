import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlanItemStyled } from "./PlanItemStyle";
import Style from "./PlanItem.module.scss";
import { PlanAbilityItems } from "../../abilityItem/AbilityItems";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import Button from "../../../../../tools/button/Button";
import { SizeType } from "../../../../../viewModels/dataTypes/tools/ListComponentType";
import { ButtonTheme } from "../../../../../viewModels/enums/ButtonEnum";
import { PricingCallToActionType } from "../../../../../viewModels/dataTypes/tools/PricingCallToActionType";

interface Props {
  planTitle: string;
  planImage?: string;
  planPrice: string;
  buildingLimitNumber: number;
  planColor: string;
  isSelect?: boolean;
  description: string;
  onClick?: () => void;
  callToActionType?: PricingCallToActionType;
}

const PlanItem: React.FC<Props> = ({
  planTitle,
  planImage,
  planPrice,
  buildingLimitNumber,
  planColor,
  isSelect = false,
  description,
  onClick,
  callToActionType = PricingCallToActionType.CheckBox,
}) => {
  const [itemSelect, setItemSelect] = useState<boolean>();
  useEffect(() => {
    setItemSelect(isSelect);
  }, [isSelect]);
  const { t: translate } = useTranslation();
  const onMouseHoverHandler = () => {
    if (callToActionType == PricingCallToActionType.Button && onClick != null) {
      onClick();
      setItemSelect(true);
    }
  };
  const onMouseLeaveHandler = () => {
    if (callToActionType == PricingCallToActionType.Button) {
      setItemSelect(false);
    }
  };
  return (
    <PlanItemStyled
      planColor={planColor}
      select={itemSelect ? true : false}
      callToAction={callToActionType}
      onMouseEnter={onMouseHoverHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <div className={Style.plan_name}>
        <div>
          {planImage != null && <img src={planImage} alt={planTitle} />}
          <h3>{planTitle}</h3>
        </div>
        <div>
          {callToActionType == PricingCallToActionType.CheckBox &&
            (isSelect ? (
              <CheckBoxIcon />
            ) : (
              <div onClick={onClick}>
                <CheckBoxOutlineBlankIcon
                  className={Style.plan_item_icon}
                  
                />
              </div>
            ))}
        </div>
      </div>
      <div className={Style.plan_price}>
        <p>{planPrice}</p>
        <span>{"/" + description}</span>
      </div>
      <div className={Style.ability}>
        <PlanAbilityItems
          Title={
            <p>
              {translate("PLANS.BUILDING_SUPPORT")}
              {buildingLimitNumber === 1 ? (
                " " + translate("BUILDING.ONE") + " "
              ) : (
                <>
                  <span> (</span>
                  <span className={Style.buildingNumber}>
                    {buildingLimitNumber}
                  </span>
                  <span>) </span>
                </>
              )}
              {translate("BUILDING.BUILDINGS").toLowerCase()}
            </p>
          }
          planColor={planColor}
        />
        <PlanAbilityItems
          Title={<p>{translate("PLANS.MANAGING_USERS")}</p>}
          planColor={planColor}
        />
        <PlanAbilityItems
          Title={<p>{translate("PLANS.GET_REPORT")}</p>}
          planColor={planColor}
        />
        {callToActionType == PricingCallToActionType.CheckBox && (
          <Link to={"/"} target="_blank">
            {translate("SHARED.SEE_MORE")}
          </Link>
        )}
      </div>
      {callToActionType == PricingCallToActionType.Button && (
        <div>
          <Button
            theme={ButtonTheme.primaryReverse}
            link="./"
            size={SizeType.small}
            text={translate("SHARED.UPGRADE")}
          />
        </div>
      )}
    </PlanItemStyled>
  );
};

export default PlanItem;
