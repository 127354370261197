import { AdminRolesRequest, BaseUrl, GetUserPermissionsRequest, PrimaryRolesRequest } from "../../text/Api";
import { ApiResponse } from "../../viewModels/api/Api";
import { IUserPermissions, IUserRole } from "../../viewModels/dataTypes/user/User";
import { ApiMethod } from "../../viewModels/enums/ApiMethod";
import { CallApi } from "../CallApi";
import { DefaultApiHeader } from "./ApiHeaders";



export const GetPrimaryRoles = async () => {
  const Url: string = BaseUrl + PrimaryRolesRequest;

  var Result: ApiResponse<IUserRole[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;
};

export const GetAdminRoles = async () => {
  const Url: string = BaseUrl + AdminRolesRequest;

  var Result: ApiResponse<IUserRole[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;
};

export const GetUserPermissions = async (userRoleIds : string) => {
  const Url: string = BaseUrl + GetUserPermissionsRequest + userRoleIds;

  var Result: ApiResponse<IUserPermissions[]> = await CallApi(
    Url,
    "{}",
    DefaultApiHeader,
    ApiMethod.Get
  );
  return Result;
};