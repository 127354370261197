import React from "react";
import { DashboardContainer } from "../../../../tools/container/customeContainer/DashboardContainer";
import DashboardBody from "../../dashboardBody/DashboardBody";
import ServicesCategoryList from "./serviceCategory/servicesCategoryList/ServicesCategoryList";

const BuildingServiceCategories: React.FC = () => {
  return (
    <DashboardContainer>
      <DashboardBody>
        <ServicesCategoryList />
      </DashboardBody>
    </DashboardContainer>
  );
};

export default BuildingServiceCategories;
