import React, {
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import Style from "./Button.module.scss";
import { ButtonState, ButtonTheme } from "../../viewModels/enums/ButtonEnum";
import { DefaultTFuncReturn } from "i18next";
import { SizeType } from "../../viewModels/dataTypes/tools/ListComponentType";
import useWindowSize from "../../hook/WindowSize";
import { SmallDeviceTrigger } from "../../Setting";

interface Props {
  text: DefaultTFuncReturn | ReactElement;
  link?: string | null;
  theme?: ButtonTheme;
  state?: ButtonState;
  clickMethod?: MouseEventHandler; // Make clickMethod function generic
  size?: SizeType;
  icon?: ReactNode;
  iconButton?: boolean;
  className?: string;
}
//Primary
const Button: React.FC<Props> = ({
  text,
  link = null,
  clickMethod,
  theme = ButtonTheme.default,
  state = ButtonState.default,
  size = SizeType.large,
  icon = null,
  iconButton = false,
  className = "",
}) => {
  const chooseButtonTheme = () => {
    if (state !== ButtonState.default) return;
    switch (theme) {
      case ButtonTheme.primary:
        return Style.primary_button;
      case ButtonTheme.accent:
        return Style.accent_button;
      case ButtonTheme.primaryReverse:
        return Style.reverse_button;
      case ButtonTheme.delete:
        return Style.delete_button;
      case ButtonTheme.deleteReverse:
        return Style.reverse_delete_button;
      default:
        return Style.button;
    }
  };
  const chooseButtonState = () => {
    switch (state) {
      case ButtonState.default:
        return "";
      case ButtonState.disable:
        return Style.disable_button;
      case ButtonState.success:
        return Style.success_button;
      default:
        return "";
    }
  };
  const chooseButtonSize = () => {
    switch (size) {
      case SizeType.large:
        return "";
      case SizeType.medium:
        return Style.medium_button;
      case SizeType.small:
        return Style.small_button;
      default:
        return "";
    }
  };

  const screenSize = useWindowSize();

  const [iconButtonState, setIconButtonState] = useState(false);

  useEffect(() => {
    setIconButtonState(iconButton && screenSize.width < SmallDeviceTrigger);
  }, [iconButton, screenSize]);

  return (
    <>
      {link != null ? (
        <Link
          data-testid="link-button"
          className={`${className} ${Style.button} ${
            iconButton ? Style.icon_button : ""
          } ${chooseButtonTheme()} ${chooseButtonState()} ${chooseButtonSize()}`}
          to={link}
        >
          {icon}
          {iconButton ? "" : text}
        </Link>
      ) : (
        <a
          data-testid="call-to-action-button"
          className={`${className} ${Style.button} ${
            iconButton ? Style.icon_button : ""
          } ${chooseButtonTheme()} ${chooseButtonState()} ${chooseButtonSize()}`}
          onClick={state === ButtonState.default ? clickMethod : () => {}}
        >
          {icon}
          {iconButtonState ? "" : text}
        </a>
      )}
    </>
  );
};

export default Button;
