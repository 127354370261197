import React, { ReactElement, useEffect, useState } from "react";
import DashboardMenu from "../menu/DashboardMenu";
import Style from "./DashboardBody.module.scss";
import DashboardHeader from "../header/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { AppThunkDispatch } from "../../../redux/Store";
import { GetUserDataAction } from "../../../redux/slices/UserSlice";
import { GetUserTokenInfo } from "../../../services/commonServices/CommonServices";
import FloorinsToast from "../../../tools/toast/FloorinsToast";
import { UserCredentialInfoViewModel } from "../../../viewModels/api/Models";

interface Props {
  children: ReactElement;
}

const DashboardBody: React.FC<Props> = ({ children }) => {
  //redux
  const userCredential = useSelector((state: IReducer) => state.user);

  const dispatch = useDispatch<AppThunkDispatch>();

  //states
  const [userCredentialInfo, setUserCredentialInfo] =
    useState<UserCredentialInfoViewModel | null>(null);
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    if (userCredential.userLogin) {
      setUserCredentialInfo(userCredential.user);
    } else {
      var userToken = GetUserTokenInfo();
      dispatch(GetUserDataAction(userToken?.userId ?? ""));
    }
  }, [userCredential]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={Style.dashboard}>
      <DashboardMenu userInfo={userCredentialInfo} />
      <div className={Style.dashboard_body}>
        <DashboardHeader userInfo={userCredentialInfo} scrolled={scrolled} />
        <div className={Style.dashboard_content}>
          {children}
          <FloorinsToast />
        </div>
      </div>
    </div>
  );
};

export default DashboardBody;
