import { Grid } from "@mui/material";
import React from "react";
import useWindowSize from "../../../../../../../hook/WindowSize";
import { MediumDeviceTrigger } from "../../../../../../../Setting";
import NewComment from "../../../../../../../tools/comment/newComment/NewComment";
import { BuildingCommunicationViewModel } from "../../../../../../../viewModels/api/Models";
import CommentCard from "../../../../../../../tools/comment/commentCard/CommentCard";

interface Props {
  comments: BuildingCommunicationViewModel[] | null;
  onChange: (value: string) => void;
  value: string;
}
const ComplaintComments: React.FC<Props> = ({ comments, onChange, value }) => {
  const windowSize = useWindowSize();

  return (
    <Grid
      container
      columnSpacing={windowSize.width > MediumDeviceTrigger ? 8 : 6}
    >
      <Grid item xs={12}>
        <NewComment onChange={onChange} value={value} />
      </Grid>
      {comments &&
        comments.map(
          (comment: BuildingCommunicationViewModel, index: number) => (
            <Grid item xs={12}>
              <CommentCard key={index} commentInfo={comment} />
            </Grid>
          )
        )}
    </Grid>
  );
};

export default ComplaintComments;
