import React, { useEffect, useState } from "react";
import {
  GetAdminPermissionsService,
  GetBuildingUserInfoService,
  UpdateAdministrationInfo,
  UpdateResidentialInfo,
} from "./BuildingUserInfoForm.Service";
import { useNavigate, useParams } from "react-router-dom";
import { BuildingUserInfoViewModel } from "../../../../../../viewModels/dataTypes/building/IBuildingUserInfoViewModels";
import { IServiceResult } from "../../../../../../viewModels/api/Api";
import { IUser } from "../../../../../../viewModels/dataTypes/user/User";
import { IAdministrator } from "../../../../../../viewModels/dataTypes/user/IAdministrator";
import {
  IResident,
  UpdateResidenViewModel,
} from "../../../../../../viewModels/dataTypes/user/IResident";
import UserInfo from "./userInfo/UserInfo";
import { CountryType } from "../../../../../../viewModels/dataTypes/country/Country";
import Style from "./BuildingUserInfoForm.module.scss";
import Loading from "../../../../../../tools/loading/Loading";
import { IDropDown } from "../../../../../../viewModels/dataTypes/tools/DropDownItems";
import AdministrationInfo from "./administrationInfo/AdministrationInfo";
import ResidentialInfo from "./residentialInfo/ResidentialInfo";
import Button from "../../../../../../tools/button/Button";
import {
  ButtonState,
  ButtonTheme,
} from "../../../../../../viewModels/enums/ButtonEnum";
import { useTranslation } from "react-i18next";
import { CancelAddAdmin } from "../../addUser/addAdminForm/AddAdminPopUps";
import {
  IAdminResponsibilitiesReducer,
  IReducer,
} from "../../../../../../viewModels/reduxInterfaces/ReduxInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../../redux/Store";
import { GetAdminRolesAction } from "../../../../../../redux/slices/AdminResponsibilitiesSlice";
import { AddAdministratorViewModel } from "../../../../../../viewModels/api/Models";
import {
  CompareObjectLists,
  ConvertIDropDownToString,
  IsNullList,
} from "../../../../../../services/commonServices/CommonServices";
import { CreateToast } from "../../../../../../tools/toast/ToastService";
import { ToastType } from "../../../../../../viewModels/enums/ToastType";
import { GetBuildingUsersAction } from "../../../../../../redux/slices/BuildingUsersSlice";
import { setHasUnsavedChanges } from "../../../../../../redux/slices/UnsavedChangesSlice";

const BuildingUserInfoForm = () => {
  const { buildingId, userId } = useParams();
  const [cancelUpdateResidentState, setCancelUpdateResidentState] =
    useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IUser>();
  const [userCountrey, setUserCountrey] = useState<CountryType | null>(null);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>("");
  const [mandatoriesChange, setMandatoriesChange] = useState<boolean>(false);
  const [prevAdminInfo, setPrevAdminInfo] = useState<IDropDown[] | null>(null);
  const [prevResidentialInfo, setPrevResidentialInfo] = useState<
    IResident[] | null
  >(null);

  const [administrationInfo, setAdministrationInfo] = useState<
    IAdministrator[] | null
  >(null);
  const [residentialInfo, setResidentialInfo] = useState<IResident[] | null>(
    null
  );
  const [preload, setpreload] = useState<boolean>(true);
  const [userPermissions, setUserPermissions] = useState<IDropDown[] | null>(
    []
  );
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.disable
  );

  const [adminRoles, setAdminRoles] = useState<IDropDown[] | null>(null);

  const navigate = useNavigate();

  const responsibilities: IAdminResponsibilitiesReducer = useSelector(
    (state: IReducer) => state.adminResponsibilities
  );
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    const getBuildingUserInfo = async () => {
      const getUserInfoResult: IServiceResult<BuildingUserInfoViewModel> =
        await GetBuildingUserInfoService(buildingId ?? "", userId ?? "");

      if (getUserInfoResult.data) {
        setUserInfo(getUserInfoResult.data?.userInfo);
        setUserCountrey({
          code: getUserInfoResult.data?.userInfo.countryCode,
          name: getUserInfoResult.data?.userInfo.countryName,
          symbol: getUserInfoResult.data?.userInfo.countryAbbreviation,
        });
        setUserPhoneNumber(
          getUserInfoResult.data?.userInfo.phoneNumber.replace(
            getUserInfoResult.data?.userInfo.countryCode,
            ""
          )
        );
        var adminPermissions = GetAdminPermissionsService(
          getUserInfoResult.data?.administrationPermissions ?? []
        );
        setAdministrationInfo(
          !IsNullList(getUserInfoResult.data?.administrationPermissions)
            ? getUserInfoResult.data?.administrationPermissions
            : null
        );
        setPrevAdminInfo(
          !IsNullList(adminPermissions) ? adminPermissions : null
        );
        setUserPermissions(
          !IsNullList(adminPermissions) ? adminPermissions : null
        );

        setResidentialInfo(
          !IsNullList(getUserInfoResult.data.residentialPermissions)
            ? getUserInfoResult.data.residentialPermissions
            : null
        );
        setPrevResidentialInfo(
          !IsNullList(getUserInfoResult.data.residentialPermissions)
            ? getUserInfoResult.data.residentialPermissions
            : null
        );
        setpreload(false);
      }
    };
    getBuildingUserInfo();
  }, []);

  useEffect(() => {
    let editionState: boolean =
      !CompareObjectLists(residentialInfo ?? [], prevResidentialInfo ?? []) ||
      !CompareObjectLists(userPermissions ?? [], prevAdminInfo ?? []);
    if (prevAdminInfo != null && userPermissions == null) {
      editionState = false;
    }
    setMandatoriesChange(editionState);
    dispatch(setHasUnsavedChanges(editionState));
    setButtonState(editionState ? ButtonState.default : ButtonState.disable);
  }, [residentialInfo, userPermissions]);

  useEffect(() => {
    if (responsibilities.fill) {
      setAdminRoles(responsibilities.responsibilities);
    } else {
      dispatch(GetAdminRolesAction());
    }
  }, [responsibilities]);

  const changeResidentInfoHandler = (userInfo: UpdateResidenViewModel) => {
    if (!residentialInfo) return;

    let updatedItems: IResident[] = residentialInfo.map((item: IResident) =>
      item.id === userInfo.residentId
        ? {
            ...item,
            contractEndDate: userInfo.endDate,
            contractStartDate: userInfo.startDate,
            isResident: userInfo.isResident,
          }
        : item
    );
    setResidentialInfo(updatedItems);
  };

  const deleteResident = (residentId: number | null, residentType: string) => {
    let residents = residentialInfo?.filter(
      (item: IResident) => item.id !== residentId
    );
    setPrevResidentialInfo(
      !IsNullList(residents ?? []) ? residents ?? null : null
    );

    setResidentialInfo(!IsNullList(residents ?? []) ? residents ?? null : null);
    dispatch(GetBuildingUsersAction(buildingId ?? ""));
    let hasRole = residents?.length === 0 && !administrationInfo;
    CreateToast(
      ToastType.Success,
      translate("ALERT.DELETE_RESIDENT_SUCCESSFULLY", {
        value: residentType,
      }),
      {
        autoClose: hasRole ? 1000 : 5000,
        onClose: () => {
          if (hasRole) {
            navigate(`/dashboard/building/users/${buildingId}`);
          }
        },
      }
    );
  };

  const deleteAdmin = () => {
    dispatch(GetBuildingUsersAction(buildingId ?? ""));
    setAdministrationInfo(null);
    setPrevAdminInfo(null);
    let hasRole = !residentialInfo;
    CreateToast(
      ToastType.Success,
      translate("ALERT.DELETE_ADMIN_SUCCESSFULLY"),
      {
        autoClose: hasRole ? 1000 : 5000,
        onClose: () => {
          if (hasRole) {
            navigate(`/dashboard/building/users/${buildingId}`);
          }
        },
      }
    );
  };

  const updateUserInfo = async () => {
    setpreload(true);
    let errorMessage: string | null = null;
    if (
      administrationInfo &&
      !CompareObjectLists(userPermissions ?? [], prevAdminInfo ?? [])
    ) {
      let adminInfo: AddAdministratorViewModel = {
        buildingId: Number(buildingId),
        countryId: userCountrey?.id,
        email: userInfo?.email,
        phoneNumber: userInfo?.phoneNumber,
        userId: userInfo?.id,
        userRoleIds: ConvertIDropDownToString(userPermissions),
      };
      const updateAdminResult = await UpdateAdministrationInfo(adminInfo);

      setAdministrationInfo(updateAdminResult.data ?? null);
      let adminPermissions = GetAdminPermissionsService(
        updateAdminResult.data ?? []
      );
      setPrevAdminInfo(!IsNullList(adminPermissions) ? adminPermissions : null);
      setUserPermissions(
        !IsNullList(adminPermissions) ? adminPermissions : null
      );
    }
    if (
      residentialInfo &&
      !CompareObjectLists(residentialInfo ?? [], prevResidentialInfo ?? [])
    ) {
      const updateResidentialInfoResult = await UpdateResidentialInfo(
        residentialInfo
      );
      if (!updateResidentialInfoResult.data) {
        errorMessage = updateResidentialInfoResult.message;
      } else {
        setPrevResidentialInfo(
          !IsNullList(updateResidentialInfoResult.data)
            ? updateResidentialInfoResult.data
            : null
        );
        setResidentialInfo(
          !IsNullList(updateResidentialInfoResult.data)
            ? updateResidentialInfoResult.data
            : null
        );
      }
    }
    setpreload(false);
    if (errorMessage) {
      CreateToast(ToastType.Error, translate(errorMessage));
    } else {
      dispatch(GetBuildingUsersAction(buildingId ?? ""));
      CreateToast(ToastType.Success, translate("ALERT.EDIT_SUCCESS"));
    }
  };

  const cancelUpdateResidentForm = () => {
    if (mandatoriesChange) {
      dispatch(setHasUnsavedChanges(true));

      setCancelUpdateResidentState(true);
    } else {
      navigate(-1);
    }
  };

  const closeCancelUpdateResidentPopUp = () => {
    setCancelUpdateResidentState(false);
  };

  const { t: translate } = useTranslation();

  return (
    <div className={Style.user_info}>
      <div className={Style.user_info_items}>
        <UserInfo
          firstName={userInfo?.firstName ?? ""}
          lastName={userInfo?.lastName ?? ""}
          phoneNumber={userPhoneNumber}
          country={userCountrey}
          email={userInfo?.email ?? ""}
          userFirstLogin={userInfo?.isConfirmed ?? false}
        />
        {administrationInfo && adminRoles && (
          <AdministrationInfo
            userResponsibilities={userPermissions}
            userResponsibilitiesHandler={setUserPermissions}
            adminRoles={adminRoles}
            deleteHandler={deleteAdmin}
          />
        )}
        {residentialInfo && (
          <ResidentialInfo
            changeHandler={changeResidentInfoHandler}
            residentialInfo={residentialInfo ?? []}
            deleteHandler={deleteResident}
          />
        )}
      </div>

      <div className={Style.updateresident_buttons}>
        <Button
          theme={ButtonTheme.primaryReverse}
          text={translate("BUTTON.BACK")}
          clickMethod={cancelUpdateResidentForm}
        />
        <Button
          text={translate("BUTTON.SAVE")}
          clickMethod={updateUserInfo}
          state={buttonState}
          theme={ButtonTheme.primary}
        />
      </div>
      {preload && <Loading />}
      {cancelUpdateResidentState && (
        <CancelAddAdmin cancelButton={closeCancelUpdateResidentPopUp} />
      )}
    </div>
  );
};

export default BuildingUserInfoForm;
