import styled from "styled-components";
import IconWrapper from "../../../../../../../tools/icons/CustomIcon";
import { ClassNameViewModel } from "../../../../../../../viewModels/dataTypes/CommonViewModels";

interface Props extends ClassNameViewModel {
  color: string;
  icon?: string;
}
const IconSliderItemStyle = styled.div<Props>`
  svg {
    color: ${(props: Props) => props.color};
  }
`;

const IconSliderItem: React.FC<Props> = ({ color, icon, className = "" }) => {
  return (
    <IconSliderItemStyle color={color}>
      <IconWrapper className={className} name={icon ?? ""} />
    </IconSliderItemStyle>
  );
};

export default IconSliderItem;
